import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-roadsafety',
  templateUrl: './roadsafety.component.html',
  styleUrls: ['./roadsafety.component.scss']
})
export class RoadsafetyComponent implements AfterViewInit {

  closeResult = '';
  options: NgbModalOptions = {
    modalDialogClass: 'customModalDialogClassCrime'
  };
  @ViewChild('content55') content5: ElementRef | undefined;

  constructor(
    private modalService: NgbModal
  ) { }

  ngAfterViewInit(): void {
    this.modalService.open(this.content5, this.options);
  }


  public open(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result: any) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason: any) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
