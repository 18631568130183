import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';
import {AppsettingsService} from '../../../../services/appsettings.service';

@Component({
  selector: 'app-trackeventsport',
  templateUrl: './trackeventsport.component.html',
  styleUrls: ['./trackeventsport.component.scss']
})
export class TrackeventsportComponent implements OnInit {
  configUrl: any;
    authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

    trackevents: any;
    public apiContent: any = '';
    public apiContentTrackEvents: any = '';
    public closeResult = '';
    public requestFailed = false;
    url = '';
    // videoCode= 'C3N_iGjhSTs'; previous year video code
    videoCode = 'hOIH5RkEn9Q';
    imageCounter = 1;
    imageCounter2 = 1;
    imageList = [{
        src: '/assets/rally-2023/rally/Trac Rally 2023 - 001.webp'
    }];
    imageList2 = [{
        src: '/assets/rally-2023/winners/Trac Rally Prize Giving001.webp'
    }];
    // public imageListVar = '/assets/Dullstroom-rally-2022/smaller/TRAC-DULLSTROOM-1.jpg';
    public imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 001.webp';
    public imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving001.webp';
    public imageListVarHighResolution = '/assets/rally-2023/rally/Trac Rally 2023 - 001.webp';

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        public appService: AppsettingsService
    ) {
        this.configUrl = this.appService.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/1';
        this.imgSrc = ''
    }

    ngOnInit(): void {

        this.appService.loadAppConfig().subscribe((response: any) => {

            this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/1';
            this.trackevents = response.apiBaseUrl + '/TracService/api/v1/trac/getEventsData';

            this.getData(this.configUrl).subscribe(data => {

                this.apiContent = data;
                console.log(this.apiContent.body);

            }, error => {
                this.requestFailed = true;
            });

            this.getData(this.trackevents).subscribe(data => {
                console.log(data.body);
                this.apiContentTrackEvents = data;
                // console.log(this.apiContentTrackEvents.body.Data[0]);
                this.videoCode = this.apiContentTrackEvents.body.Data[0].MediaLink;
                this.url = this.apiContentTrackEvents.body.Data[0].MediaLink;
                const url = this.apiContentTrackEvents.body.Data[0].MediaLink;
                const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                this.videoCode = url.match(VID_REGEX)[1];
            });
        });
    }


    getData(url: string): Observable<HttpResponse<any[]>> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authToken
        });

        return this.http.get<any[]>(url, {observe: 'response', headers: reqHeader});
    }


    public open(content: any): void {

        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    public nextImage(): void {

        if (this.imageCounter < 126) {
            this.imageCounter++;
            if(this.imageCounter <= 9){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
            }
            if(this.imageCounter > 9 && this.imageCounter <= 99){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 0' + this.imageCounter + '.webp';
            }
            if(this.imageCounter > 99 && this.imageCounter <= 999){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
            }
        } else {
            this.imageCounter = 1;
            this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
        }
    }

    public previousImage(): void {

        if (this.imageCounter >= 2) {
            this.imageCounter--;
            if(this.imageCounter <= 9){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
            }
            if(this.imageCounter > 9 && this.imageCounter <= 99){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 0' + this.imageCounter + '.webp';
            }
            if(this.imageCounter > 99 && this.imageCounter <= 999){
                this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
            }
        } else {
            this.imageCounter = 126;
            this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
        }
    }

    public nextImage2(): void {
        if (this.imageCounter2 < 121) {
            this.imageCounter2++;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }

    public previousImage2(): void {

        if (this.imageCounter2 >= 2) {
            this.imageCounter2--;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 121;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
        }
    }

    public highResolution(): void {
        if (this.imageCounter2 < 121) {
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }

    public nextImageHighRes(): void {
        // if (this.imageCounter <= 1121) {
        //     this.imageCounter++;
        //     this.imageListVarHighResolution = '/assets/rally-2023/winners/Trac Rally 2023 Winners - 00' + this.imageCounter + '.webp';
        // } else {
        //     this.imageCounter = 1;
        //     this.imageListVarHighResolution = '/assets/rally-2023/winners/Trac Rally 2023 Winners - 00' + this.imageCounter + '.webp';
        // }
        if (this.imageCounter2 < 121) {
            this.imageCounter2++;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving001' + this.imageCounter2 + '.webp';
        }
    }

    public previousImageRes(): void {
        if (this.imageCounter2 >= 2) {
            this.imageCounter2--;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 121;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }
    
    imgSrc:string;
    onClick(event:any){
        const imgElem = event.target;
        var target = event.target || event.srcElement || event.currentTarget;
        var srcAttr = target.attributes.src;
        this.imgSrc = srcAttr.nodeValue;
        console.log(this.imgSrc);
    }

}
// export class TrackeventsportComponent implements OnInit {
//   configUrl = '{{this.appSetting.apiBaseUrl}}/TracService/api/v1/trac/getPressReleaseData/1/2';
//   authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

//   trackevents = '{{this.appSetting.apiBaseUrl}}/TracService/api/v1/trac/getEventsData';


//   public apiContent: any = '';
//   public apiContentTrackEvents: any = '';
//   public closeResult = '';
//   public requestFailedorEmpty = false;
//   url = '';
//   // videoCode= 'C3N_iGjhSTs'; previous year video code
//   videoCode = 'hJ7Txc5aoZY';
//   public requestFailed = false;
//   constructor(
//     private http: HttpClient,
//     private modalService: NgbModal,
//     public appService: AppsettingsService
//   ) {

//   }

//   ngOnInit(): void {

//     this.appService.loadAppConfig().subscribe((response: any) => {

//       this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/2';
//       this.trackevents = response.apiBaseUrl + '/TracService/api/v1/trac/getEventsData';


//       this.getData(this.configUrl).subscribe(data => {

//         this.apiContent = data;
//         if (this.apiContent.body.Data.length === 0) {
//           this.requestFailedorEmpty = true;
//         }
//       }, error => {
//         this.requestFailedorEmpty = true;
//       });

//       this.getData(this.trackevents).subscribe(data => {
//         console.log(data.body);
//         this.apiContentTrackEvents = data;
//         console.log(this.apiContentTrackEvents.body.Data[0]);
//         this.videoCode = this.apiContentTrackEvents.body.Data[0].MediaLink;
//         this.url = this.apiContentTrackEvents.body.Data[0].MediaLink;
//         const url = this.apiContentTrackEvents.body.Data[0].MediaLink;
//         const VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
//         this.videoCode = url.match(VID_REGEX)[1];
//       });


//     });
//   }


//   getData(url: string): Observable<HttpResponse<any[]>> {
//     const reqHeader = new HttpHeaders({
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + this.authToken
//     });

//     return this.http.get<any[]>(url, {observe: 'response', headers: reqHeader});


//   }


//   public open(content: any): void {

//     this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
//       this.closeResult = `Closed with: ${result}`;
//     }, (reason) => {
//       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
//     });
//   }

//   private getDismissReason(reason: any): string {
//     if (reason === ModalDismissReasons.ESC) {
//       return 'by pressing ESC';
//     } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
//       return 'by clicking on a backdrop';
//     } else {
//       return `with: ${reason}`;
//     }
//   }


// }
