import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {AppsettingsService} from '../../../services/appsettings.service';

@Component({
  selector: 'app-readmoretrackeventport',
  templateUrl: './readmoretrackeventport.component.html',
  styleUrls: ['./readmoretrackeventport.component.scss']
})
export class ReadmoretrackeventportComponent implements OnInit {

  configUrl = '{{this.appSetting.apiBaseUrl}}/TracService/api/v1/trac/getPressReleaseDetail/';
  articleFrom = '{{this.appSetting.apiBaseUrl}}/TracService/api/v1/trac/getPressReleaseData/1/2';
  authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

  public apiContent: any;
  public apiContentArticle: any;
  public closeResult = '';
  PageData: any;
  PageDataFromArticle: any = '';
  currentId: any;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public appService: AppsettingsService
  ) {
    this.configUrl = this.appService.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseDetail/';
    this.articleFrom = this.appService.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/2';
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id: string =  params.id;
      const idFrom: string =  params.idFrom;
      console.log(id);
      this.configUrl = this.configUrl + id;
      console.log(this.configUrl);

      this.getPressReleasesData().subscribe(data => {

        this.apiContent = data;
        console.log('pressssssssssssssss release data');
        console.log(this.apiContent.body.Data.length);
        this.PageData = this.apiContent.body.Data[0].PageData;

      });

      // Getting details from the article that was clicked.


      this.getArticleData().subscribe(data => {
        this.apiContentArticle = data;
        console.log(data);
        const calcArticleNr = idFrom;
        console.log(calcArticleNr);
        this.PageDataFromArticle = this.apiContentArticle.body.Data[calcArticleNr];
      });

    });


  }




  getPressReleasesData(): Observable<HttpResponse<any[]>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });
    return this.http.get<any[]>(this.configUrl, { observe: 'response', headers: reqHeader });
  }

  getArticleData(): Observable<HttpResponse<any[]>> {

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });
    return this.http.get<any[]>(this.articleFrom, { observe: 'response', headers: reqHeader });
  }

}
