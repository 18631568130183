<!--   Carousel  **************** ************** **************** max-height: 520px; -->
<ngb-carousel *ngIf="images" class="grey-gradient-background "
              style="margin-top: 83px;   "
              [showNavigationArrows]="showNavigationArrows"
              [showNavigationIndicators]="showNavigationIndicators">
    <ng-template ngbSlide>
        <div class="container">
            <div class="row  rounded">
                <div class="col-sm-12 col-lg-6 white-text ">
                    <div class="p-3 mt-3 mb-3     ">
                        <div class="embed-responsive embed-responsive-16by9 text-center ">
                            <iframe class="embed-responsive-item p-2 " style="max-height: 520px;  "
                                    src="https://www.youtube.com/embed/kZVI8tcaPh4?si=xNVeXxAm0ZpWz7Qz"
                                    title="TRAC N4"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>

                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 white-text ">
                    <div class="p-3 mt-3 mb-3     ">
                        <div class="removeMarginOnSmallScreens">
                            <h3 class="text-uppercase">TRAC - Fazendo a diferença um quilômetro de cada vez</h3>
                            <br/>
                            Inspirados pelo design contemporâneo e pelas ligações
                            comunitárias, é nossa missão construír, desenvolver e envolver
                            progressivamente, a transformação da imagem da nossa empresa
                            posiciona-nos para levar esta visão coletiva, bem para o futuro.
                            <br/><br/>
                            <h5 class="text-uppercase">
                                ASSISTA AO VIDEO PARA SABER MAIS SOBRE A TRAC
                            </h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </ng-template>
</ngb-carousel>
<!--
<ngb-carousel *ngIf="images" style="margin-top: 77px; background-color: #242424">
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[0]" alt=" first slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">O SEU GUARDIÃO NA N4</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[1]" alt=" second slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">MUITO MAIS DO QUE APENAS UMA ESTRADA</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[2]" alt="third slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">LIGANDO CULTURAS, PESSOAS E DESTINOS</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[3]" alt="fourth slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">TRAC Responsabilidade Social</div>
    </div>
  </ng-template>
</ngb-carousel>
-->

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-12 p-3  text-center bg-yellow ">
            <div class=" black-text bold">
                Mapa da nossa rota
            </div>
        </div>

        <div class="col-12 shadow bg-yellow">
            <div class="row text-center justify-content-center align-middle" style="height: 364px;">

                <iframe #theiframe id="theiframe" [ngStyle]="{'width':setWidth}" [src]="url | safe"
                        class="text-center">
                </iframe>

            </div>
        </div>


        <div class="col-lg-12 p-3  text-center bg-yellow ">
            <div class=" black-text bold pointer">
                <a href="" routerLink="/liverouteport" class="black-text fontsize13em pr-3"
                   title="Mais Informação...">Mais aqui</a>
                <img title="Mais Informação..." src="./assets/images/home/UiIcons_Location.png"
                     class="img-fluid hoverstate"
                     routerLink="/liverouteport"
                     style="height:28px;">
            </div>
        </div>
        
            <div class="col-lg-12 p-3  text-center grey-gradient-background">
              <div class="text-uppercase white-text h3 my-1">
                Predicted Traffic Flow - December 2022
              </div>
            </div>
        

    </div>

</div>  -->
<!-- end of contrainer-fluid -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 p-3  text-center grey-gradient-background">
            <div class="text-uppercase white-text fontsize075">
                TRANS AFRICAN CONCESSIONS (TRAC) TEM COMO RESPONSABILIDADE GERIR A N4 ROTA COM PORTAGEM A PARTIR DA
                <br/>
                RAMPA DE SAÍDA SOLOMON MALHANGU NA CIDADE DE TSHWANE (PRETORIA), NA PROVINCIA DE GAUTENG ATÉ AO PORTO
                <br/>
                DE MAPUTO EM MOÇAMBIQUE.
            </div>
        </div>
    </div>
</div>

<!-- Traffic advisory here --- -------------------------->
<!-- <div class="container-fluid" style="">
    <div class="row">
        <div class="col-lg-12 h1 text-center pt-4 ">
            Easter 2024
        </div>
        <div class="hide-on-large-screen text-center">
            <button class="btn black-text shadow bg-yellow fontsize13m text-center" (click)="open(content6)">Tap to View
                Predicted Traffic Flow
            </button>
            <img src="assets/images/Predicted-Traffic-Flow-2024-Easter.webp?v=20240327" class="img-fluid" (click)="open(content6)"/>
        </div>
        <div class="col-lg-12 pt-3 text-center hide-traffic-advisory-on-small-screen"
             style="overflow: auto">
            <div class="text-uppercase white-text ">
                <style type="text/css">
                    .tg {
                        border-collapse: collapse;
                        border-color: #ccc;
                        border-spacing: 0;
                        margin: 0 auto;
                        width: 100%;
                    }

                    .tg td {
                        background-color: #fff;
                        border-color: #ccc;
                        border-style: solid;
                        border-width: 1px;
                        color: #333;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        overflow: hidden;
                        padding: 11px 20px;
                        word-break: normal;
                    }

                    .tg th {
                        background-color: #f0f0f0;
                        border-color: #ccc;
                        border-style: solid;
                        border-width: 1px;
                        color: #333;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        overflow: hidden;
                        padding: 11px 20px;
                        word-break: normal;
                    }

                    .tg .tg-lto5 {
                        background-color: #ffcb2f;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-zyik {
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        top: -1px;
                        vertical-align: top;
                        will-change: transform
                    }

                    .tg .tg-d52n {
                        background-color: #32cb00;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-0pky {
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top;
                        font-weight: bold;

                    }

                    .tg .tg-tw5s {
                        background-color: #fe0000;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-7btt {
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-pxkr {
                        background-color: #32cb00;
                        border-color: inherit;
                        color: #000000;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .groen {
                        background-color: #32cb00;
                        border-color: inherit;
                        color: #000000;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-qz2x {
                        background-color: #ffcb2f;
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .oranje{
                        background-color: #ffcb2f;
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-siyb {
                        background-color: #fe0000;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .rooi {
                        background-color: #fe0000;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .pers {
                        background-color: #73039f;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                </style>
                <table class="tg">
                    <thead>
                    <tr>
                        <th class="tg-zyik h4" colspan="11">Predicted Traffic Flow for the TRAC N4 Route for Easter 2024
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="tg-0pky">Time</td>
                        <td class="tg-0pky">Wednesday</td>
                        <td class="tg-0pky">Thursday</td>
                        <td class="tg-0pky">Thursday</td>
                        <td class="tg-0pky">Friday</td>
                        <td class="tg-0pky">Saturday</td>
                        <td class="tg-0pky">Sunday</td>
                        <td class="tg-0pky">Monday</td>
                        <td class="tg-0pky">Tuesday</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky"></td>
                        <td class="tg-0pky">20 March 2024</td>
                        <td class="tg-0pky">21 March 2024</td>
                        <td class="tg-0pky">28 March 2024</td>
                        <td class="tg-0pky">29 March 2024</td>
                        <td class="tg-0pky">30 March 2024</td>
                        <td class="tg-0pky">31 March 2024</td>
                        <td class="tg-0pky">01 April 2024</td>
                        <td class="tg-0pky">02 April 2024</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">00:00 - 03:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">03:00 - 06:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">06:00- 09:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">09:00- 12:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">12:00 - 15:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-bad"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">15:00 - 18:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">18:00 - 21:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>

                    </tr>
                    <tr>
                        <td class="tg-0pky">21:00 - 00:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-7btt" colspan="11">INDEX</td>
                    </tr>
                    <tr>
                        <td class="tf-light" colspan="11">Light Traffic Conditions</td>
                    </tr>
                    <tr>
                        <td class="tf-busy" colspan="11">Busy Traffic Conditions</td>
                    </tr>
                    <tr>
                        <td class="tf-heavy" colspan="11">Heavy Traffic Conditions - Expect Delays</td>
                    </tr>
                    <tr>
                        <td class="tf-bad" colspan="11">Exceptionally Heavy Traffic Conditions - Expect Delays</td>
                    </tr>
                    </tbody>
                </table>
                <br/><br/>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="actionlink" href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Port - March 2024.pdf" target="_blank">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/traffic-advisory-portuguese.webp?v20240327"
                        class="img-fluid shadow" alt="TRAC Cares">
                    </div>
                    <div class="cta-content hide bg-black" style="width: 97%;">
                        <h3 class="text-center">TRACN4 AVISO DE TRÂNSITO</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <p class="text-center">CLIQUE AQUI PARA VER</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div> -->
 <!-- End of Traffic advisory here --- -------------------------- -->

 <div class="container-fluid" style="">
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5">
                <a class="actionlink" href="" routerLink="/traceventsport">
                    <img src="./assets/images/home-tiles/trac-rally-2024-banner.webp?v20240716" class="img-fluid shadow" alt="TRAC Rally">
                </a>
            </div>
        </div>
    </div>
</div>

<div class=" text-center">
    <div class="container">
        <div class="row py-4">
            <!-- Image 1 with black mouse over                                        TRAC CARES-->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/tracn4-cares-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="TRAC Cares">
                    </div>
                    <div class="cta-content hide bg-black" (click)="open(content1)">
                        <h3 class="text-center">Mais Informação</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** ---  TAGS ONLY -->

            <!-- Image 3 with black mouse over -->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/" (click)="open(content3)">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/get-a-tag-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="Tags Only">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Mais Informação</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** ---  -->
            <!-- Image 2 with black mouse over -->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/" (click)="open(content2)">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/why-stop-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="Why stop">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Mais Informação</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** --- TAG ONLY -->

            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/plazafeesport">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/toll-tariffs-thumbnail.webp?v=20240410"
                             class="img-fluid shadow" alt="sanral 2024">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Mais Informação</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>

            <!-- Image 4 with black mouse over -->
            <!--
            <div class="col-sm-12 col-md-3 text-center mb-3">
              <a class="actionlink" href="" routerLink="/" (click)="open(content4)">
                <div class="cta-img">
                  <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Traffic%20Advisory_Why%20Stop%20Thubnail.jpg" class="img-fluid shadow" alt="TRAC Cares">
                </div>
                <div class="cta-content hide bg-black">
                  <h3 class="text-center">Learn More</h3>
                  <div class="dot-divider mb-3">
                    <span class="dot-divider-white"></span>
                  </div>
                </div>
              </a>
            </div>
            -->
            <!-- ------------- *************************** ---  -->

            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <div class="actionlink">
                    <div class="cta-img">
                        <img src="./assets/images/Department Icons_Trac Assist.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h6 class="mt-2 mb-0 font-bold text-uppercase black-text">CLIQUE NO MOVEL PARA CHAMARE</h6>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text"></div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase">LIGUE AO NOSSO CENTRO DE ASSINTÊNCIA DISPONIVEL 24/7</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>

                        <h3 class="text-center text-uppercase"><a class="h2 white-text" href="tel:0800 87 22 64">0800 87
                            22 64</a></h3>
                        <h3 class="text-center text-uppercase"><a class="h2 white-text" href="tel:8009022">800 9022</a>
                        </h3>
                    </div>
                </div>
            </div>

            <!-- ------------ -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <a class="actionlink" href="" routerLink="/about-company-profileport">
                    <div class="cta-img">
                        <img src="./assets/images/Department-Icons_About-Trac.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">SOBRE</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">PREFILE DA EMPRESA</div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase">SOBRE A TRAC</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <h3 class="text-center text-uppercase"><a href="tel:0800872264"></a></h3>
                    </div>
                </a>
            </div>
            <!-- ------------ -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <a class="actionlink" href="" routerLink="/plazafeesport">
                    <div class="cta-img">
                        <img src="./assets/images/csiEducation/CSI%20Icons_Education.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">TARIFAS DE
                            PORTAGEM</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">CONSULTE AS MAIS RECENTES
                            TARIFAS DE PORTAGEM
                        </div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase ">TARIFAS DE PORTAGEM</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <h3 class="text-center text-uppercase ">Eficaz 1 March 2021</h3>
                    </div>
                </a>
            </div>

            <!-- ------------  CSI Guide -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <div class="actionlink" href="#"  >
                    <div class="cta-img">
                        <img src="./assets/images/csiEducation/CSI%20Icons_Education.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">CSI Guide</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">
                            <a class="" style="font-size: inherit; color:inherit"
                               href="assets/csi/csi.pdf">Download the guide</a>
                        </div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase ">Download PDF Now</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <a class="apdfs text-center "
                           style=" border: 1px solid #fff; font-size: 1.2em; width:100%; border-radius: 3px;"
                           href="assets/csi/csi.pdf"
                           title="Click and View PDF Document"
                           target="_blank">Download Document</a>
                    </div>
                </div>
            </div>


        </div>
        <div class="row py-4">
            <div class="col">
                <div id="block--2" class="block block--wysiwyg_block">
                    <div class="block__content ">
                        <p>Tratando-se a N4 Rota com Portagem de uma Empresa que Constrói, Opera e Transfere a estrada
                            com portagem
                            (Build, Operate and Transfer (BOT), percorrendo
                            aproximadamente a distância de 570 Kilometros. A TRAC é detentora de concessão pelo prazo de
                            30 anos com
                            as agências de estradas nacionais, Sul africana e
                            Moçambicana – SANRAL e ANE respectivamente – esta concessão foi assinada no ano de 1997
                            tendo como
                            compromisso o seu desenvolvimento, gerência e
                            manutenção, estimular e facilitar o negócio em três regiões chave na ecónomia – Gauteng,
                            Mpumalanga e
                            Moçambique.</p>
                        <p>O desenvolvimento e manutenção da N4 estrada com portagem só é possivel com os fundos gerados
                            pelas seis
                            portagens ao longo do trajecto e que são geridas
                            pela TRAC</p>
                        <p>Aquando em viagem, pela N4 Rota com Portagem, os viajantes deparam com vistas e paisagens
                            deslumbrantes,
                            efeitos e promenores geológicos que traduzem a
                            viagem por esta majestosa estrada uma experiência para a vida, para além destes magnifícos
                            cenários
                            deparam ainda com um grande número de opções nas areas
                            do lazer,desporto e actividades naturais, como também pontos de comes e bebes que servem
                            comidas e bebidas
                            deliciosas, muitos destes pontos fornecem comidas
                            únicas, sem paralelo.&nbsp;<a
                                    href="#" routerLink="/about-company-profileport">Mais Informação</a></p></div>
                </div>
            </div>
        </div>
    </div>
</div>

<img src="assets/images/road-footer.jpg" alt="Road footer" style="width: 100%;">


<ng-template #content1 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Trac Cares</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Trac%20Cares_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title2">Why Stop ?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Why%20Stop_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content3 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title3">Tag-Only</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Why%20Queue_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title4">Traffic Advisory</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Traffic%20Advisory_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>

<ng-template #content5 let-modal>
    <div class="modal-header bg-yellow ">
        <h4 class="modal-title" id="modal-basic-title5">Announcement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body   grey-gradient-background2 p-1">

        <img src="assets/TrafficManagement-Dec-2022/TrafficManagementFlyer-Port-Dec2022.jpg"

             class="img-fluid imgHeight mx-auto d-block shadow"/>

    </div>
    <div class="modal-footer bg-yellow">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content6 let-modal>
    <div class="modal-header bg-yellow ">
        <h4 class="modal-title" id="modal-basic-title6">Traffic Advisory December 2022</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body   grey-gradient-background2 p-4" style="overflow: scroll; ">
        <!-- Traffic advisory here --- -------------------------- -->
        <div class="container-fluid" style="">
            <div class="row">
                <div class="col-lg-12 h1 text-center pt-4 ">
                    2023 EASTER
                </div>
                <div class="col-lg-12 pt-3 text-center "
                     style="overflow: auto">
                    <div class="text-uppercase white-text ">
                        <style type="text/css">
                            .tg {
                                border-collapse: collapse;
                                border-color: #ccc;
                                border-spacing: 0;
                                margin: 0 auto;
                                width: 100%;
                            }

                            .tg td {
                                background-color: #fff;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }

                            .tg th {
                                background-color: #f0f0f0;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                font-weight: normal;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }

                            .tg .tg-lto5 {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-zyik {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                top: -1px;
                                vertical-align: top;
                                will-change: transform
                            }

                            .tg .tg-d52n {
                                background-color: #32cb00;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-0pky {
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top;
                                font-weight: bold;

                            }

                            .tg .tg-tw5s {
                                background-color: #fe0000;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-7btt {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-pxkr {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .groen {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-qz2x {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .oranje{
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-siyb {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .rooi {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .pers {
                                background-color: #73039f;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                        </style>
                        <table class="tg">
                            <thead>
                            <tr>
                                <th class="tg-zyik h4" colspan="11">Predicted Traffic Flow for the TRAC N4 Route for Easter 2024
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="tg-0pky">Time</td>
                                <td class="tg-0pky">Wednesday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Saturday</td>
                                <td class="tg-0pky">Sunday</td>
                                <td class="tg-0pky">Monday</td>
                                <td class="tg-0pky">Tuesday</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky"></td>
                                <td class="tg-0pky">20 March 2024</td>
                                <td class="tg-0pky">21 March 2024</td>
                                <td class="tg-0pky">28 March 2024</td>
                                <td class="tg-0pky">29 March 2024</td>
                                <td class="tg-0pky">30 March 2024</td>
                                <td class="tg-0pky">31 March 2024</td>
                                <td class="tg-0pky">01 April 2024</td>
                                <td class="tg-0pky">02 April 2024</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">00:00 - 03:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">03:00 - 06:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">06:00- 09:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">09:00- 12:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">12:00 - 15:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-bad"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">15:00 - 18:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">18:00 - 21:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-0pky">21:00 - 00:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-7btt" colspan="11">INDEX</td>
                            </tr>
                            <tr>
                                <td class="tf-light" colspan="11">Light Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tf-busy" colspan="11">Busy Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tf-heavy" colspan="11">Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            <tr>
                                <td class="tf-bad" colspan="11">Exceptionally Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of Traffic advisory here --- -------------------------- -->
    </div>
    <div class="modal-footer bg-yellow">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>
