<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2">
          <h6 class="m-0 text-right ">ABOUT US</h6>
          <h1 class="text-right ">COMPANY PROFILE</h1></div>
        <div class="col">
          <img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-black py-4  grey-gradient-background">
      <div class="col">
        <div class="container">
          <div class="text-white h6 text-center text-uppercase lineHeight166">TRANS AFRICAN CONCESSIONS
          (TRAC) MANAGES THE N4 TOLL ROUTE STARTING FROM SOLOMON MAHLANGU OFF RAMP IN TSHWANE, GAUTENG TO THE MAPUTO
          PORT IN MOZAMBIQUE.</div></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col">
        <div class="row" style="justify-content: center; align-items: center;">
          <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 0 40px 0 15px;">
            <div id="block--3" class="block block--wysiwyg_block">
              <div class="block__content">
                <p style="font-size: 18px; text-align: left;">
                  Trans African Concessions (TRAC) is an international, privately-owned company
                  that manages the N4 Toll Route from the Solomon Mahlangu Drive Interchange in Tshwane (formerly Pretoria),
                  Gauteng, to the Maputo Port in Mozambique. TRAC’s vision is to connect people, places and countries quickly,
                  conveniently and safely and this is the motto that the company lives by.
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6" style="padding: 0 60px;">
            <img src="./assets/images/about/trac_bbbee_banner.webp?v20240918" class="img-fluid shadow" alt="BBBEE">
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="text-uppercase font-bold"><strong style="font-size: 16px;">Board of Directors</strong></h3>
          </div>
        </div>
        <div class="row py-4 team">
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Chairman</strong></p>
                <p>Vuyelwa Masangwana</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Muhammad Patel</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Hayley Stern</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Boingotlo Cleopatra Maroane</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Executive Director</strong></p>
                <p>Alexander Michael Antonie van Niekerk</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>James Francis Howard</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Dean Horton</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Alternate Director</strong></p>
                <p>Gregory Boris Babaya</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Sean Gerard Macdonald</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Manual Patricio da Cruz Viola</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>William John Crosse</p></div>
            </div>
          </div>
        </div>
        <div id="block--3" class="block block--wysiwyg_block">
          <div class="block__content">
            <p>The company employs over 500 people and is headed by CEO <b>Alex van Niekerk</b>, who is supported by
              seven Executive Managers. Each Executive Manager leads an important division within the company, namely:
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="text-uppercase font-bold"><strong style="font-size: 16px;">TRAC Executive Committee</strong></h3>
          </div>
        </div>
        <div class="row py-4 team">
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Engineering</strong></p>
                <p>Wimpie Janse van Rensburg</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Finance</strong></p>
                <p>Ilze Stander</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Plazas Mozambique</strong></p>
                <p>Firmino Inguane</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Human Resources and CSI</strong></p>
                <p>Adri Fourie</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Plazas South Africa</strong></p>
                <p>Obed Lebudi</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Maintenance</strong></p>
                <p>Derek Howe-Dreyer</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Stakeholder Manager and LCC Mozambique</strong></p>
                <p>Sesi Mkhize</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-yellow py-4">
      <div class="col">
        <div class="container"><h2 class="text-center">TRAC N4</h2>
          <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
          <div id="block--4" class="block block--wysiwyg_block">
            <div class="block__content"><p>TRAC, which was officially formed in 1996, became an essential element of the
              Maputo Corridor Spatial Development Initiative after signing a 30-year concession contract in 1997. The
              contract ensures that TRAC&nbsp; manages certain sections of the N4 Toll Route, in conjunction with the
              South African National Roads Agency Limited (SANRAL) and its Mozambican counterpart, Administração
              Nacional de Estradas (ANE).</p>
              <p>TRAC was initially contracted to design, construct, finance, operate and maintain the N4 between
                eMalahleni (formerly Witbank) in South Africa and Maputo in Mozambique. The &nbsp;concession was then
                extended by 60 km, from the Solomon Mahlangu Drive Interchange to the Gauteng/Mpumalanga border in 2005,
                bringing the total road length to 570 km. The management and upgrade of the road is made possible
                through funds generated from toll fees from the six mainline toll plazas and four off ramp plazas that
                TRAC operates along the route.</p>
              <p>The Build, Operate and Transfer (BOT) contract expires on 5 February 2028 and represents a benchmark
                public-private partnership with the governments of South Africa and Mozambique, private sector and
                communities along the route, with each party sharing in the project’s associated risks and rewards.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class=" image-sep "
             style="background-image: url('./assets/images/road.jpg');
             height: 80px; background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12">
        <h5 class="text-center font-bold" >MISSION STATEMENT</h5>
          <div class="dot-divider mb-3">
          <span class="dot-divider-black"></span>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12"><h3 class="my-4 text-center text-uppercase font-bold">TRAC, ITS EMPLOYEES AND ALL SERVICE
        PROVIDERS AFFILIATED TO IT, ENDEAVOUR TO:</h3></div>
    </div>
    <div class="row py-4 mission">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Be Accountable &amp; Responsible</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Keep Abreast Of Technology</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Acknowledge &amp; Appreciate</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Lead By Example</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Add Value To The Road User</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Have Open &amp; Frank Communication</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Build Trust &amp; Respect</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Focus On Training &amp; Development</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Celebrate Diversity</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Uplift Communities</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Be Consistent</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Work Together</p></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-black p-0">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12"><h3 class="my-4 text-center text-uppercase text-white font-bold">TO ENSURE THE ABOVE TOP
          LINE PRINCIPLES ARE ADHERED TO, TRAC, ITS EMPLOYEES AND ALL SERVICE PROVIDERS AFFILIATED TO IT, REBUT:</h3>
        </div>
      </div>
      <div class="row py-4 mission">
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Indecisiveness &amp; Lack Of Follow Through</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Backstabbing, Politics &amp; Gossip</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Arrogance</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Accepting Mediocrity</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Fear Of Expression</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Absenteeism</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Bureaucracy</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Lack Of Care &amp; Humanity</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Poor Maintenance</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Lack Of Integrity</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Fraud &amp; Corruption</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Incompetency</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Ill-discipline</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Lack Of Leadership Visibility</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
