<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">DESPORTO E
            CULTURA</h1></div>
        <div class="col"><img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>É geralmente aceite de que uma variadissima gama de beneficios sociais
        sejam derivados do engajamento e participação em
        actividades desportivas e culturais. Após inúmeros estudos realizados sobre os benificios destes dois itens
        sociais ficou concluído que a
        maioria destes indicaram que a participação promove o comportamento pro-social, melhora o desempenho académico e
        contribui
        positivamente no bem-estar emocional, fisíco e mental do participante</p></div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">
      <div class="container">
        <div class="row py-4">
          <div class="col-sm-12 col-md-12"><h5 class="text-center text-black">A TRAC É TESTEMUNHA DESTES BENIFICIOS EM
            PRIMEIRA MÃO POR VIA DOS VARIADISSÍMOS EVENTOS E
            PROJECTOS DESPORTIVOS E CULTURAIS QUE TEM APOIADO DESDE O INICIO DA NOSSA CONCESSÃO EM
            1997, EM CONSEQUÊNCIA DESTAS EXPERIÊNCIAS A TRAC MANTÉM-SE COMPROMETIDA AOS PROJECTOS
            QUE SE SEGUEM CUJOS FAZEM PARTE DO PORTFOLIO CSI DESPORTO E CULTURA.</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>




  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_SELATI%20CUP.png" alt=""
             class="img-fluid imgsize"/>
        <h6>SELATI CUP</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CROC%20CANOE%20MARATHON.png"
             alt="" class="img-fluid imgsize"/>
        <h6>SELATI MARATHON</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_GREATEST%20TRAIN%20RACE--.png" alt=""
             class="img-fluid imgsize"/>
        <h6>GREATEST TRAIN
          RACE</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CROC%20CANOE%20MARATHON.png"
             alt="" class="img-fluid imgsize"/>
        <h6>TRAC CROC CANOE
          MARATHON</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_SCHOOL%20SPORTS--.png"
             alt="" class="img-fluid imgsize"/>
        <h6 class="text-uppercase">Desporto nas escolas</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CURRO%20CHESS--.png" alt=""
             class="img-fluid imgsize"/>
        <h6>TRAC CURRO CHESS</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_INNIBOS.png" alt=""
             class="img-fluid imgsize"/>
        <h6>INNIBOS</h6>
      </div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiSports/Croc-Canoe-70.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
      </div>
    </div>
  </div>

  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environmentport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MEIO AMBIENTE</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/educationport">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/healthport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SAÚDE E BENIFICÊNCIA</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputoport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">TRAC EM MOÇAMBIQUE</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafetyport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SEGURANÇA RODOVIÁRIA</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smmeport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESENVOLVIMENTO SOCIAL
                    E ECONÔMICO</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
