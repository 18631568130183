<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0" style="background:#ffee00;">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right ">Portagens</h6>
          <h1 class="text-right ">BENEFICIOS DE UMA<br/>
            ESTRADA COM<br/>
            PORTAGENS</h1></div>
        <div class="col"><img src="./assets/images/plaza/Why%20Toll%20Icon-01.png" alt="Why Toll"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4 d-flex align-items-center">
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12"><h5 class="text-center text-black">PORQUÊ E COMO É QUE A TRAC TRABALHA
            PARA SI!</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
            <p>Portagem continuam a ser aplicados no desenvolvimento das
              infraestruturas, gerir a tempo permanente uma auto estrada de
              excelência que ao utente assegura uma viagem tranquila, mais rápida e
              de maior conveniência, a partir de Tshwane (Pretoria), na Africa do Sul,
              com chegada a Maputo, em Moçambique.</p>
            <p>O reembolso em termos mais latos é notório, tanto para a Africa do Sul
              como para Moçambique que tem acontecido por via de investimentos
              substânciais, no desenvolvimento sócio económico e das comunidades
              ao longo da rota, não podendo esquecer a vasta expansão industrial,
              negócios comerciais e criação de emprego na região, tendo todos estes
              factores cimentado o sucesso do Corredor de Maputo</p>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12">
            <img src="./assets/images/home-tiles/tags-only.jpg" class="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class=" image-sep "
             style="background-image: url('./assets/images/road.jpg');
             height: 80px; background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6"> <div id="block--10" class="block block--wysiwyg_block"> <div class="block__content"> <h2 class="text-center">COMO É DESPENDIDO O SEU PAGAMENTO DE PORTAGEM</h2><div class="dot-divider mb-3"> <span class="dot-divider-black"></span> </div><p>A maioria dos fundos gerados pelo pagamento de portagem são aplicados na N4 estrada com portagem, em si própria. Actualmente custa à TRAC mais do que ZAR 100 000 por Kilómetro, por ano em manutenção do dia-a-dia, este valor é apenas para a rota em si. Isto é conhecido por manutenção de rotina, na qual estão incluídos o corte de ervas, selagem de fissuras, reparação ou ereção de cercas, melhoramentos de sinalética, marcação e pinturas na rodovia e manter as guardas de protecção em bom estado.</p><p>Dos fundos gerados também se paga, reabilitação da estrada, melhoramentos de maior envergadura e projectos de expansão, como ainda o pagamento de equidade e da dívida contraída necessária à realização deste corredor internacional, de qualidade mundial.</p> </div></div> </div>
      <div class="col-sm-6"> <div id="block--11" class="block block--wysiwyg_block"> <div class="block__content"> <h2 class="text-center">QUEM DETERMINA OS VALORES DAS PORTAGENS?</h2><div class="dot-divider mb-3"> <span class="dot-divider-black"></span> </div><p>Os valores no pagamento de portagens foram inicialmente acordados pela TRAC agências rodóviarias SANRAL da Africa do Sul e ANE de Moçambique, quando teve inicio o contracto de concessão.</p><p>Os valores foram então calculados tendo em conta os benificios usufruidos pelos utentes no uso da N4, comparativamente ao uso de estrada alternativa, tendo sido fixado a uma percentagem da poupança. Presentemente os valores são ajustados anualmente, em conformidade com o preço index do consumidor (CPI), disponibolisado pelos respectivos departamentos Nacionais de Transportes.</p> </div></div> </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background" >
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase white-text">LINKS RELACIONADOS</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="relatedlinkstolls" class="row py-4">
      <div class="col-sm-12 col-md-6 text-center"  routerLink="/plazafeesport">
        <div class="actionlink" href="#">
          <div class="cta-img"><img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac.png" style="height: 150px;"
                                    alt="Toll"><h5 class="mt-3 mb-0" >Tarifs De Portagem</h5>
            <h6 class="m-0" >Consulte as Mais Recentes Tarifas de Portagem</h6></div>
          <div class="cta-content hide bg-black"><h3 class="text-center">Tarifa de pedágio</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center"></h3></div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 text-center" routerLink="/plazaconcessionrequirementsport">
        <div class="actionlink" >
          <div class="cta-img"><img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac.png" style="height: 150px;"
                                    alt="Toll Green"><h5 class="mt-3 mb-0" >Requerimentos</h5>
            <h6 class="m-0" >De Concessao</h6></div>
          <div class="cta-content hide bg-black"><h3 class="text-center">Mais Recentes Requerimentos</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center">DOWNLOADABLE</h3></div>
        </div>
      </div>

    </div>
  </div>

</main>
