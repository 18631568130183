<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold">CSI</h6>
          <h1 class="text-right ">HEALTH & WELFARE</h1></div>
        <div class="col"><img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Banner_1.png" alt="Health"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>The health and welfare of the communities along the 570m-long N4 Toll Route is paramount to TRAC. From healthcare projects, to those
        that focus specifically on assistance to the elderly, to initiatives that are aimed at raising funds and awareness about a prominent disease,
        TRAC throws its weightand more, behind them all.</p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiHealth/Bikers-29.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">THE FOLLOWING PROJECTS AND EVENTS FALL UNDER THE TRAC CSI HEALTH AND WELFARE PORTFOLIO:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_KUPHILA%20CLINIC.png" alt=""  class="img-fluid imgsize"/>
        <h6>KUPHILA CLINIC</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_CANSA%20RELAY%20FOR%20LIFE.png" alt="" class="img-fluid imgsize"/>
        <h6>CANSA RELAY FOR
          LIFE</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_ASSISTANCE%20TO%20THE%20ELDERLY.png" alt=""  class="img-fluid imgsize"/>
        <h6>ASSISTANCE TO THE
          ELDERLY</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_SPCA.png" alt="" class="img-fluid imgsize"/>
        <h6>SPCA</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_MANDELA%20DAY.png" alt="" class="img-fluid imgsize"/>
        <h6>MANDELA DAY</h6>
      </div>

    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ENVIRONMENT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/education">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCATION & SKILLS DEVELOPMENT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputo">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MAPUTO CORRIDOR</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafety">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ROAD SAFETY</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smme">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SMME DEVELOPMENT & SUPPORT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandculture">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SPORTS & CULTURE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
