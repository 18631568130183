<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold">CSI</h6>
          <h1 class="text-right ">ENVIRONMENT</h1></div>
        <div class="col"><img src="./assets/images/csiEducation/EDUCATION%20&%20SKILLS%20DEVELOPMEN%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>Our environment goes hand-in-hand with the way in which we exist. TRAC believes in not only creating and establishing an environment
        in which fauna, flora and homosapiens can flourish, but also in nurturing what already exists to secure a prosperous future.
        <br/><br/>
        TRAC uses every opportunity to do good for Mother Nature and supports numerous initiatives and programmes which have the same
        goal. From establishing a Greening the N4 project which has the protection of flora and fauna during major road works as a main
        objective to sponsoring the rehabilitation and care of three orphaned rhino housed at the world’s biggest orphan rhino sanctuary, no stone
        is left unturned in our quest to be environmentally responsible.
      </p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEnvironment/Tree-Dark-2-1.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">THE ENVIRONMENTALLY-RELATED PROJECTS AND/OR ORGANISATIONS CREATED,<br/>
        SUPPORTED AND/OR ENDORSED BY TRAC ARE:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_GREENING%20THE%20N4.png" alt=""  class="img-fluid imgsize"/>
        <h6>GREENING THE N4</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_TREE%20OF%20REMEMBRANCE.png" alt="" class="img-fluid imgsize"/>
        <h6>TREE OF
          REMEMBRANCE</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_CARE%20FOR%20WILD%20REHABILITATION%20CENTRE.png" alt=""  class="img-fluid imgsize"/>
        <h6>CARE FOR WILD
          REHABILITATION
          CENTRE</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_MASHESHA%20STOVES.png" alt="" class="img-fluid imgsize"/>
        <h6>MASHESHA STOVES</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_HEAL.png" alt="" class="img-fluid imgsize"/>
        <h6>HEAL</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_LEFPA.png" alt=""  class="img-fluid imgsize"/>
        <h6>LEFPA</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_EWT.png" alt=""  class="img-fluid imgsize"/>
        <h6>EWT</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_OTHER.png" alt=""  class="img-fluid imgsize"/>
        <h6>OTHER</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/education">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCATION & SKILLS DEVELOPMENT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">HEALTH & WELFARE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MAPUTO CORRIDOR</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ROAD SAFETY</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SMME DEVELOPMENT & SUPPORT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SPORTS & CULTURE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
