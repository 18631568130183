<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0" style="background:#ffee00;">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right ">Plaza &amp; Tolls</h6>
          <h1 class="text-right ">WHY TOLL?</h1></div>
        <div class="col"><img src="./assets/images/plaza/Why%20Toll%20Icon-01.png" alt="Why Toll"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4 d-flex align-items-center">
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12"><h5 class="text-center text-black">WHY AND HOW TOLL ROADS WORK FOR YOU!</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
            <p>Toll roads world-wide operate on the user-pays principle, in other words, road users pay a direct user
              fee for the portion of the road they utilise.</p>
            <p>The toll road users pay on the N4 Toll Route continues to be used to develop the road’s infrastructure
              and a well-managed road network which in turn ensures a safer, faster, more convenient route as well as
              shortened travel time from Tshwane in Gauteng, South Africa to Maputo in Mozambique.</p>
            <p>In addition, broader payback for South Africa and Mozambique has come from substantial investments in
              socio-economic development of the communities along the route, and the wider expansion of industry,
              commercial business and jobs in the region which in turn has cemented the success of the Maputo
              Corridor.</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12">
            <img src="./assets/images/home-tiles/tags-only.jpg" class="img-fluid"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class=" image-sep "
             style="background-image: url('./assets/images/road.jpg');
             height: 80px; background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6">
        <div id="block--10" class="block block--wysiwyg_block">
          <div class="block__content"><h2 class="text-center">HOW YOUR TOLL FEE IS SPENT</h2>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <p>The majority of the funds generated from toll fees are spent on the N4 toll road itself. It currently
              costs TRAC more than R120 000 per kilometre, per annum, for day-to-day maintenance of the route only. This
              is known as routine maintenance under which grass cuts, sealing of cracks, repairing of fences and
              potholes, upgrading signage and road markings and keeping guard rails in good condition fall under.</p>
            <p>Toll fees also pay for road rehabilitation, major upgrades and expansion projects as well as the to pay
              the equity and debt that have been required to create this world-class international corridor.</p></div>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="block--11" class="block block--wysiwyg_block">
          <div class="block__content"><h2 class="text-center">WHO SETS TOLL FEES?</h2>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <p>The toll fees on the N4 Toll Route were initially agreed by TRAC and the South African and Mozambican
              roads agencies, SANRAL and ANE respectively, at the start of the concession contract.</p>
            <p>The fees were calculated against the benefit that the N4 Toll Route would offer road users, compared to
              an alternative road, and were set at a percentage of the saving.</p>
            <p>Currently they are adjusted once a year in line with the consumer price index (CPI) by the respective
              National Departments of Transport.</p></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background" >
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase white-text">Related Links</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="relatedlinkstolls" class="row py-4">
      <div class="col-sm-12 col-md-6 text-center"  routerLink="/plazafees">
        <div class="actionlink" href="#">
        <div class="cta-img"><img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac.png" style="height: 150px;"
                                  alt="Toll"><h5 class="mt-3 mb-0" >TOLL PLAZAS &amp; TOLL
          FEES</h5><h6 class="m-0" >View toll plaza &amp; fee information</h6></div>
        <div class="cta-content hide bg-black"><h3 class="text-center">TOLL TARIFF</h3>
          <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
          <h3 class="text-center">EFFECTIVE 1 MARCH 2023</h3></div>
      </div>
      </div>

      <div class="col-sm-12 col-md-6 text-center" routerLink="/plazaconcessionrequirements">
        <div class="actionlink" >
        <div class="cta-img"><img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac.png" style="height: 150px;"
                                  alt="Toll Green"><h5 class="mt-3 mb-0" >CONCESSION
          REQUIREMENTS</h5><h6 class="m-0" >Find out more</h6></div>
        <div class="cta-content hide bg-black"><h3 class="text-center">LATEST CONCESSION REQUIREMENTS</h3>
          <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
          <h3 class="text-center">DOWNLOADABLE</h3></div>
      </div>
      </div>

    </div>
  </div>

</main>
