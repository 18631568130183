import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-environmentport',
  templateUrl: './environmentport.component.html',
  styleUrls: ['./environmentport.component.scss']
})
export class EnvironmentportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
