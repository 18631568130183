<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">MEDIA</h1></div>
        <div class="col"><img src="./assets/images/media/PressReleasesIcon-01.png" style="max-height: 200px; width: auto"
                              alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4" *ngIf="this.apiContent !== ''">
      <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
        <div class="col-sm-12 col-md-12 py-4" *ngIf="i >= greaterthan && i <= smallerThan">
          <div class="post-title"><span class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
            <h2 class="posttitle mb-0">{{o.Name}}</h2></div>
          <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
            <a class="h6" routerLink="/readmoreport/{{o.ID}}/{{i}}">Read More</a></div>
        </div>
      </ng-container>
      <mat-paginator [length]="totalNumberOfItems" class=""
                     [pageSize]="paginatorPagesize"
                     [pageSizeOptions]="[5, 10, 25]"
                     aria-label="Select page"
                     (page)="getNext($event)">
      </mat-paginator>
      <!--
      <div class="post-links">
        <ul class="pagination pagination-sm">
          <li class="page-item disabled"><span class="page-link">«</span></li>
          <li class="page-item active"><span class="page-link">1</span></li>
          <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=2">2</a></li>
          <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=3">3</a></li>
          <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=4">4</a></li>
          <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=5">5</a></li>
          <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=2" rel="next">»</a>
          </li>
        </ul>
      </div>
      -->

    </div>
  </div>

</main>
