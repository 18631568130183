import {Component, OnInit, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {NgbCarouselConfig, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {AppsettingsService} from '../../services/appsettings.service';

@Component({
  selector: 'app-get-a-tag',
  templateUrl: './get-a-tag.component.html',
  styleUrls: ['./get-a-tag.component.scss']
})

export class GetATagComponent implements OnInit {
  closeResult = '';
  
  constructor(
    config: NgbCarouselConfig,
    public appSetting: AppsettingsService,
    private modalService: NgbModal,
    private myElement: ElementRef
  ) {
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
  }

  public open(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
