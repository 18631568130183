import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whytoll',
  templateUrl: './whytoll.component.html',
  styleUrls: ['./whytoll.component.scss']
})
export class WhytollComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
