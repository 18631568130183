import {Component, Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {throwError} from 'rxjs';
import {AppsettingsService} from './appsettings.service';
import {any} from 'codelyzer/util/function';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private api: any;
  configUrl: any;
  public returnPost: Observable<any> | undefined;

  constructor(
    private http: HttpClient,
    public appService: AppsettingsService
  ) {}

  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  postdata(val: any, configResponse: any): Observable<any> {

    // tslint:disable-next-line:max-line-length
    return this.http.post<any>(configResponse.apiBaseUrl + '/TracService/api/v1/contact/sendContactInfo', JSON.stringify(val), this.httpHeader)
        .pipe(
          retry(1),
          catchError(this.processError)
        );
  }



  public processError(err: any): any {
    let message = '';
    if (err.error instanceof ErrorEvent) {
      message = err.error.message;
    } else {
      message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    // console.log(message);
    return throwError(message);
  }

}
