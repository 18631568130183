<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">SEGURANÇA RODOVIÁRIA</h1></div>
        <div class="col"><img src="./assets/images/csiRoadSafety/ROAD%20SAFETY%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>Como concessionários de uma das mais movimentadas rotas nacionais da Africa do Sul e de Moçambique a segurança na estrada é a
        preocupação primária da TRAC.
        <br/><br/>
        Apesar da segurança na estrada ser da inteira responsabilidade dos utentes, o ónus em assegurar que a N4 Rota com Portagem
        cumpra com as normas de segurança de nível mundial como também a disponibilização de todos os requesitos necessários para essa
        mesma segurança são da nossa conta. Como também é nossa obrigação emitir todas as mensagens sobre segurança a todos quantos
        usam a nossa estrada.</p>
      </div>

    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiRoadSafety/School-Project-517.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">
        OS PROJECTOS SEGUINTES FAZEM PARTE DO PORTFOLIO DE SEGURANÇA RODOVIÁRIA DA TRAC</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-4 text-center">
        <img src="./assets/images/csiRoadSafety/ROAD%20SAFETY%20Icons_SCHOOL%20PROJECT.png" alt=""  class="img-fluid imgsize"/>
        <h6>PROJECTO ESCOLAR</h6>
      </div>
      <div class="col-sm-12 col-md-4 text-center">
        <img src="./assets/images/csiRoadSafety/ROAD%20SAFETY%20Icons_PEAK%20TRAFFIC%20ROAD%20SAFETY%20CAMPAIGNS.png" alt="" class="img-fluid imgsize"/>
        <h6>CAMPANHA DE SEGURANÇA
          RODOVIÁRIA NO PICO DO
          TRÁFEGO</h6>
      </div>
      <div class="col-sm-12 col-md-4 text-center">
        <img src="./assets/images/csiRoadSafety/ROAD%20SAFETY%20Icons_ASSISTANCE%20TO%20EMERGENCY%20SERVICE%20&%20LAW%20ENFORCEMENT%20AGENCIES.png" alt=""  class="img-fluid imgsize"/>
        <h6>ASSISTÊNCIA AOS
          SERVIÇOS DE EMERGÊNCIA
          & ÀS AGÊNCIAS DE
          APLICAÇÃO DA LEI</h6>
      </div>

    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environmentport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MEIO AMBIENTE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/educationport">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/healthport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SAÚDE E BENIFICÊNCIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputoport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">TRAC EM MOÇAMBIQUE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smmeport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESENVOLVIMENTO SOCIAL
                    E ECONÔMICO</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandcultureport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESPORTO E CULTURA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
