<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold">CSI</h6>
          <h1 class="text-right ">MAPUTO CORRIDOR</h1></div>
        <div class="col"><img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>ETRAC is the only cross-border concessionaire in Southern Africa. Apart from being a key role player in the economic development of the
        Maputo Corridor, the infrastructure development across the N4 Toll Route has helped us cement privileged and special relationships with
        the communities along our national road, including in Mozambique.</p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiMaputo/Moz-Sports-04.jpg'); background-size: cover; background-position: 26% 30%;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h4 class="text-center text-black">WE HAVE AN EXTENSIVE CSI MANDATE ACROSS THE 99KM OF THE
        MOZAMBICAN SECTION OF OUR ROAD<br/>
        WHICH INCLUDES THE FOLLOWING INITIATIVES:</h4>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTOCORRIDR%20Icons_ESCOLARMARIA%20DELINGAMO.png" alt="" class="img-fluid imgsize"/>
        <h6>PRIMARY SCHOOL LINGAMO</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTOCORRIDORIcons_ESCOLAPRMARIDETREVO.png" alt=""  class="img-fluid imgsize"/>
        <h6>PRIMARY SCHOOL</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_ADPP.png" alt=""  class="img-fluid imgsize"/>
        <h6>ADPP</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_CAPAZ.png" alt="" class="img-fluid imgsize"/>
        <h6>CAPAZ</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_MATOLA%20MARATHON.png" alt="" class="img-fluid imgsize"/>
        <h6>MATOLA MARATHON</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_AGUIAS%20ESPECIAIS.png" alt=""  class="img-fluid imgsize"/>
        <h6>AGUIAS SPECIALISTS</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_BURSARIES.png" alt=""  class="img-fluid imgsize"/>
        <h6>BURSARIES</h6>
      </div>

    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ENVIRONMENT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/health">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">HEALTH & WELFARE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafety">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ROAD SAFETY</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smme">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SMME DEVELOPMENT & SUPPORT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandculture">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SPORTS & CULTURE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/education">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCATION & SKILLS DEVELOPMENT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
