import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {AppsettingsService} from '../../../services/appsettings.service';
import { popup } from 'leaflet';
import { Console } from 'console';

@Component({
    selector: 'app-gallery-rally2024',
    templateUrl: './rally2024.component.html',
    styleUrls: ['./rally2024.component.scss']
})
export class GalleryRally2024 implements OnInit {
    @ViewChild('popup') myModal : any;
    configUrl: any;
    authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

    public closeResult = '';
    public requestFailed = false;
    rallyimages: any = [];
    rallyimagescount: any = 0;
    rallyimageselected: any = 0;
    url = '';
    imageCounter = 0;
    imageCounter2 = 0;
    imageList = [{
        src: ''
    }];
    imageList2 = [{
        src: ''
    }];
    // public imageListVar = '/assets/Dullstroom-rally-2022/smaller/TRAC-DULLSTROOM-1.jpg';
    public imageListVar = '';
    public imageListVar2 = '';
    public imageListVarHighResolution = '';

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        public appService: AppsettingsService
    ) {
        this.configUrl = this.appService.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/1';
        this.imgSrc = ''
    }

    ngOnInit(): void {

        this.appService.loadAppConfig().subscribe((response: any) => {

            // this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/1/1';

            this.getData('/assets/data/rally2024/rally2024.json').subscribe(data => {
                this.rallyimages = data.body;
                const arrFromObj = Object.keys(this.rallyimages);
                this.rallyimagescount=arrFromObj.length;

                this.imageListVar = this.rallyimages[0].url;
                this.imageListVar2 = this.rallyimages[0].url;
                this.imageListVarHighResolution = this.rallyimages[0].url;
            });
        });
    }


    getData(url: string): Observable<HttpResponse<any[]>> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.authToken
        });

        return this.http.get<any[]>(url, {observe: 'response', headers: reqHeader});
    }


    public open(content: any): void {

        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    public nextImage(): void {

        if (this.imageCounter < this.rallyimagescount - 1) {
            this.imageCounter++;
            this.imageListVar = this.rallyimages[this.imageCounter].url;
        } else {
            this.imageCounter = 0;
            this.imageListVar = this.rallyimages[0].url;
        }

        // if (this.imageCounter < this.rallyimagescount) {
        //     this.imageCounter++;
        //     if(this.imageCounter <= 9){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
        //     }
        //     if(this.imageCounter > 9 && this.imageCounter <= 99){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 0' + this.imageCounter + '.webp';
        //     }
        //     if(this.imageCounter > 99 && this.imageCounter <= 999){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
        //     }
        // } else {
        //     this.imageCounter = 1;
        //     this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
        // }
    }

    public previousImage(): void {
        console.log(this.imageCounter)
        if (this.imageCounter >= 1) {
            this.imageCounter--;
            this.imageListVar = this.rallyimages[this.imageCounter].url;
        } else {
            this.imageCounter = this.rallyimagescount -1;
            this.imageListVar = this.rallyimages[this.imageCounter].url;
        }
        // if (this.imageCounter >= 2) {
        //     this.imageCounter--;
        //     if(this.imageCounter <= 9){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 00' + this.imageCounter + '.webp';
        //     }
        //     if(this.imageCounter > 9 && this.imageCounter <= 99){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 0' + this.imageCounter + '.webp';
        //     }
        //     if(this.imageCounter > 99 && this.imageCounter <= 999){
        //         this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
        //     }
        // } else {
        //     this.imageCounter = 126;
        //     this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
        // }
    }

    public nextImage2(): void {
        if (this.imageCounter2 < 121) {
            this.imageCounter2++;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }

    public previousImage2(): void {

        if (this.imageCounter2 >= 2) {
            this.imageCounter2--;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 121;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
        }
    }

    public highResolution(): void {
        if (this.imageCounter2 < 121) {
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }

    public nextImageHighRes(): void {
        // if (this.imageCounter <= 1121) {
        //     this.imageCounter++;
        //     this.imageListVarHighResolution = '/assets/rally-2023/winners/Trac Rally 2023 Winners - 00' + this.imageCounter + '.webp';
        // } else {
        //     this.imageCounter = 1;
        //     this.imageListVarHighResolution = '/assets/rally-2023/winners/Trac Rally 2023 Winners - 00' + this.imageCounter + '.webp';
        // }
        if (this.imageCounter2 < 121) {
            this.imageCounter2++;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 1;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving001' + this.imageCounter2 + '.webp';
        }
    }

    public previousImageRes(): void {
        if (this.imageCounter2 >= 2) {
            this.imageCounter2--;
            if(this.imageCounter2 <= 9){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
            }
            if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
                this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
            }
        } else {
            this.imageCounter2 = 121;
            this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving00' + this.imageCounter2 + '.webp';
        }
    }
    
    imgSrc:string;
    onClick(event:any){
        const imgElem = event.target;
        var target = event.target || event.srcElement || event.currentTarget;
        var srcAttr = target.attributes.src;
        this.imgSrc = srcAttr.nodeValue;
        // console.log(this.imgSrc);
    }

    imageSelected(event: Event): void {
        let elementId: string = (event.target as Element).id;
        // console.log(elementId)
        this.rallyimageselected = elementId;
        this.imageCounter = +elementId;
        this.imageCounter2 = +elementId;
        this.imageListVar = this.rallyimages[elementId] != '' ?this.rallyimages[elementId].url:this.rallyimages[0].url;
        this.modalService.open(this.myModal, {centered:true});
    }
}
