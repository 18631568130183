import {Component, OnInit, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {NgbCarouselConfig, NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {AppsettingsService} from '../../../services/appsettings.service';

@Component({
  selector: 'app-homeport',
  templateUrl: './homeport.component.html',
  styleUrls: ['./homeport.component.scss']
})
export class HomeportComponent implements OnInit, AfterViewInit {

  images = [1, 2, 3, 4].map((n) => `./assets/images/Home-Banner-${n}.jpg`);
  closeResult = '';
  setWidth: any;
  url = '';
  showNavigationArrows = false;
  showNavigationIndicators = false;

  @ViewChild('content5') content5: ElementRef | undefined;
  options: NgbModalOptions = {
    modalDialogClass: 'customModalDialogClass'
  };

  constructor(
    config: NgbCarouselConfig,
    private modalService: NgbModal,
    public appSetting: AppsettingsService) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    this.setWidth = '1700px';

    this.appSetting.loadAppConfig().subscribe((response: any) => {
      this.url = response.apiBaseUrl + '/tracmap/';
    });

  }

  ngAfterViewInit(): void {
    // this.modalService.open(this.content5, this.options);
  }

  public open(content: any): void {

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
