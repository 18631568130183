<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow" style="padding: 50px 0;">
      <div class="container">
        <div class="row py-2 justify-content-center align-items-center">
            <div class="col">
                <h6 class="m-0 text-center text-uppercase">pay your toll without stopping</h6>
                <h1 class="text-center text-uppercase">Get a Tag</h1>
            </div>
        </div>
      </div>
    </div>
</main>
<div class=" text-center">
    <div class="container">
        <div class="row py-4 justify-content-center">
            <!-- Image 3 with black mouse over -->
            <div class="col-sm-12 col-md-6 text-center mb-3">
                <a class="actionlink" href="" routerLink="/get-a-tag" (click)="open(tagcontent1)">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/get-a-tag-flyer.webp?v20240412" class="img-fluid shadow" alt="Tags Only">
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<img src="assets/images/road-footer.jpg" alt="Road footer" style="width: 100%;">

<ng-template #tagcontent1 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title3">Tag-Only</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/get-a-tag-flyer.webp?v20240412"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>

<style>

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 12.5rem 1rem 0 1rem !important;
        pointer-events: none;
    }

</style>
