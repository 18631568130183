<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right ">SOBRE</h6>
          <h1 class="text-right ">PERFIL DA EMPRESA</h1></div>
        <div class="col"><img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-black py-4  grey-gradient-background">
      <div class="col">
        <div class="container">
          <div class="text-white h6 text-center text-uppercase lineHeight166">
            TRANS AFRICAN CONCESSIONS (TRAC) TEM COMO RESPONSABILIDADE GERIR A N4 ROTA COM PORTAGEM A PARTIR DA RAMPA<br/>
            DE SAÍDA SOLOMON MALHANGU NA CIDADE DE TSHWANE (PRETORIA), NA PROVINCIA DE GAUTENG ATÉ AO PORTO DE MAPUTO EM MOÇAMBIQUE.</div></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col">
        <div id="block--3" class="block block--wysiwyg_block">
          <div class="block__content"><p>Trans Africa Concessions (TRAC) é uma empresa internacional de
            propriedade privada, e que tem como responsabilidade  gerir a N4 Rota com Portagem a partir do Nó da Solomon Mahlangu
            Drive em Tshwane (outrora Pretoria),Gauteng, até ao porto de Maputo em Moçambique.</p>
            <p>A visão da TRAC é ligar pessoas, lugares e países de forma rápida, conveniente e segura, sendo este o motto para o qual a empresa vive.
            </p>
<p>A empresa emprega para cima de 500 funcionários, é chefiada por <b>Alex van Niekerk</b> CEO da mesma, que por sua vez tem o apoio de sete Gerentes Executivos.</p>
          </div>
        </div>
        <!-- <div class="row py-4 team">
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Engenharia</strong></p>
                <p>Wimpie Janse van Rensburg</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Finanças</strong></p>
                <p>Ilze Stander</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Portagens de Moçambique</strong></p>
                <p>Firmino Inguane</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>
                Recursos Humanos e <br/>Investimentos Sociais</strong></p>
                <p>Adri Fourie</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Portagens da Africa do Sul</strong></p>
                <p>Obed Lebudi</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Manutenção</strong></p>
                <p>Derek Howe-Dreyer</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Controlo de Carga</strong></p>
                <p>Sesi Mkhize</p></div>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-12">
            <h3 class="text-uppercase font-bold"><strong style="font-size: 16px;">Board of Directors</strong></h3>
          </div>
        </div>
        <div class="row py-4 team">
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Muhammad Patel</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Hayley Stern</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Boingotlo Cleopatra Maroane</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Director</strong></p>
                <p>Alexander Michael Antonie van Niekerk</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>James Francis Howard</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Vuyelwa Masangwana</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Dean Horton</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Alternate Director</strong></p>
                <p>Gregory Boris Babaya</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Sean Gerard Macdonald</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>Manual Patricio da Cruz Viola</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Non Executive Director</strong></p>
                <p>William John Crosse</p></div>
            </div>
          </div>
        </div>
        <div id="block--3" class="block block--wysiwyg_block">
          <div class="block__content">
            <p>
              A empresa emprega mais de 500 pessoas e é dirigida pelo CEO Alex van Niekerk, que é apoiado por sete Gerentes Executivos. Cada Gerente Executivo lidera uma divisão importante dentro da empresa, a saber:
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="text-uppercase font-bold"><strong style="font-size: 16px;">TRAC Executive Committee</strong></h3>
          </div>
        </div>
        <div class="row py-4 team">
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Engineering</strong></p>
                <p>Wimpie Janse van Rensburg</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Finance</strong></p>
                <p>Ilze Stander</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Plazas Mozambique</strong></p>
                <p>Firmino Inguane</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Human Resources and CSI</strong></p>
                <p>Adri Fourie</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Plazas South Africa</strong></p>
                <p>Obed Lebudi</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Maintenance</strong></p>
                <p>Derek Howe-Dreyer</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4 mb-2">
            <div class="row">
              <div class="col-sm-2">
                <img src="./assets/images/about/Personal%20Icons-01.png" class="img-fluid"/>
              </div>
              <div class="col-sm-10"><p class="mb-1"><strong>Stakeholder Manager and LCC Mozambique</strong></p>
                <p>Sesi Mkhize</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-yellow py-4">
      <div class="col">
        <div class="container"><h2 class="text-center">TRAC N4</h2>
          <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
          <div id="block--4" class="block block--wysiwyg_block">
            <div class="block__content"><p>A TRAC foi constituída e formalizada no decorrer do ano de 1996, passando de imediato a ser um elemento essencial da Iniciativa de Desenvolvimento Espacial do Corredor do Maputo, após assinado o contracto de concessão pelo periodo de trinta (30) anos, decorria por altura o ano de 1997. O contracto assegura  que a TRAC  gere deteminadas secções da N4 Rota com Portagem, conjuntamente com a (SANRAL), South African National Roads Agency Limited e sua contraparte Moçambicana, (ANE) Administração Nacional de Estradas.</p>
              <p>A TRAC foi inicialmente contractada para desenhar, construir, financiar, operar e manter a N4 entre eMalahleni (outrora Witbank)na Africa do Sul e Maputo em Moçambique. Aontece que a  concessão é então acrescida de sessenta (60) km, a partir do nó Solomon Mahlangu Drive até à divisória fronteiriça virtual Gauteng/Mpumalanga, decorria por essa altura o ano 2005, totalizando  570 km de rota. A gerência e os melhoramentos da estrada só são possíveis através dos fundos gerados nas seis (6) portagens ao longo da linha principal, e das quatro plazas nas rampas de saída que a TRAC opera ao longo da rota.</p>
              <p>O  contracto de Construção, Operação e Transferência (BOT), expira no dia 5 de Fevereiro de 2028 e representa uma sociedade marco-geodésico com os governos da Africa do Sul e Moçambique, sector privado e comunidades ao longo da rota, com cada um destes partidos comungarem nos riscos e recompensas associados ao projecto:</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class=" image-sep "
             style="background-image: url('./assets/images/road.jpg');
             height: 80px; background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12">
        <h5 class="text-center font-bold" >AFIRMAÇÃO NA MISSÃO</h5>
        <div class="dot-divider mb-3">
          <span class="dot-divider-black"></span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12"><h3 class="my-4 text-center text-uppercase font-bold">TRAC, SEUS EMPREGADOS E APROVISIONADORES DE SERVIÇOS AFILIADOS, EMPENHAM-SE EM:</h3></div>
    </div>
    <div class="row py-4 mission">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Serem responsáveis</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Dar valor ao utente da estrada</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Construir confiança e respeitosity</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Ser consistente</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Trabalhar em união</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Ter comunicação franca e aberta</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Reconhecer e estimar</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Construir confiança e respeito</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Celebrar a diversidade</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Focar em boa administração</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Manter-se na vanguarda da tecnologia</p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col-sm-1">
            <img src="./assets/images/about/Mission%20Statement%20Icons_Tick.png" class="img-fluid"/>
          </div>
          <div class="col-sm-11"><p class="mb-1">Focar na formação e desenvolvimento</p></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-black p-0">
    <div class="container">
      <div class="row ">
        <div class="col-sm-12"><h3 class="my-4 text-center text-uppercase text-white font-bold">PARA GARANTIR QUE OS PRINCÍPIOS ACIMA SÃO ADERIDOS, A TRAC, OS SEUS FUNCIONÁRIOS E TODOS
          OS FORNECEDORES DE SERVIÇOS AFILIADOS A EMPRESA, REJEITAM:
        </h3>
        </div>
      </div>
      <div class="row py-4 mission">
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Aceitar medíocridade</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">
              Faltar contínuamente</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Burocracia</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Fraude e corrupção</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Falta de integridade</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Manutenção miserável</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Falta de cuidados e humanidade</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Receio em se exprimir</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Arrogância</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Apunhalar pelas costas, politiquices e bisbilhotice</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Má disciplina</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Incompetência</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Falta de visão condutora</p></div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 mb-3">
          <div class="row text-white">
            <div class="col-sm-1">
              <img src="./assets/images/about/MissionStatement%20Icons_Tick-white.png" class="img-fluid"/>
            </div>
            <div class="col-sm-11"><p class="mb-1">Indecisões e falta de acção</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
