<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------Portuguese Footer below ------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->

<div *ngIf="!langService.isEnglish" class="d-flex flex-column flex-md-row align-items-center p-2 grey-gradient-background "
     style="position: absolute; top:0; left:0; z-index: 1010; width: 100%;  ">
  <div class="my-0 mr-md-auto font-weight-normal">
    <img routerLink="/home" [src]="logo"  class="img-fluid p-2 pointer" style="height: 70px;"/>
  </div>

  <div class="d-md-none" style="position: absolute; right: 20px; top:20px;">
    <button class="navbar-toggler white-text" type="button"  *ngIf="isMenuCollapsed"(click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <button class="navbar-toggler white-text" type="button" *ngIf="!isMenuCollapsed" (click)="isMenuCollapsed">
      <div class="white-text" style="text-align:center; border:2px solid #fff;
      border-radius: 50%; height: 25px; width:25px; font-size: 14px; padding: 3px; position: absolute; right:24px; top:4px; " (click)="isMenuCollapsed = true">X</div>
    </button>
  </div>

  <div class="white-text" (click)="!isMenuCollapsed" *ngIf="!isMenuCollapsed" style="text-align:center;
      height: 45px; width:45px; font-size: 14px; padding: 3px; position: absolute;  left:26px; top:20px; " >
    <img src="assets/images/uk-flag.jpg" class="img-fluid " (click)="setLanguage('english')" />
  </div>
  <!-- Step 2: Add the ngbCollapse directive to the element below. -->
  <div class="col" >
    <div [ngbCollapse]="isMenuCollapsed"  class="collapse navbar-collapse shadow ">
      <ul class="navbar-nav mobilemenuItems">
        <li class="nav-item active">
          <!-- Step 4: Close the menu when a link is clicked. -->
          <a class="nav-link" routerLink="homeport" (click)="isMenuCollapsed = true">Casa</a>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Sobre</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/about-company-profileport"  (click)="isMenuCollapsed = true">Prefile Da Empresa</a></li>
            <li><a  routerLink="/about-historyport"  (click)="isMenuCollapsed = true">HISTÓRIA</a></li>
            <li><a  routerLink="/about-anti-corruptionport"  (click)="isMenuCollapsed = true">PLANCO DE ACÇÃO ANTI – CORRUPÇÃO</a></li>
          </ul>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Concursos</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/constructionport"  (click)="isMenuCollapsed = true">Projetos de construção</a></li>
            <li><div class="nav-link white-text" > Manutenção de Rotina </div></li>
            <li><a  routerLink="/smme-tenders-port" (click)="isMenuCollapsed = true">SMME TENDERS</a></li>
            <li><a  routerLink="/tenderresultsport"  (click)="isMenuCollapsed = true">RESULTADOS DE CONCURSOS</a></li>
            <li><a  routerLink="/routineport"  (click)="isMenuCollapsed = true"> Especificações de Manutenção de Rotina</a></li>
          </ul>
          <a class="nav-link white-text" routerLink="/jointheteamport"  (click)="isMenuCollapsed = true">Junte-se à equipe</a>

          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Plaza & Portagens</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/plazawhytollport"  (click)="isMenuCollapsed = true">Porquê e Portagem?</a></li>
            <li><a  routerLink="/plazafeesport"  (click)="isMenuCollapsed = true">Plazas com Portagem & Tarifas de Portagem</a></li>
            <li><a  routerLink="/plazaconcessionrequirementsport"  (click)="isMenuCollapsed = true">REQUERIMENTOS DE CONCESSÃO</a></li>
            <!-- <li><a  href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Port - March 2024.pdf?v=20240327" target="_blank">Aviso de Trânsito</a></li> -->
          </ul>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Controlo de Carga</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/loadcontrolport"  (click)="isMenuCollapsed = true">Controlo de Carga</a></li>
            <li><a  routerLink="/loadcontrolcenterport"  (click)="isMenuCollapsed = true">LNUMEROS DE CONTACTO DOS CENTROS DE CONTROLE DE CARGA</a></li>

          </ul>
          <a class="nav-link white-text" routerLink="/roadsafetyport" (click)="isMenuCollapsed = true">Road Safety</a>

          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Seguranca Na Estrada</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/educationport"  (click)="isMenuCollapsed = true">Educação e Desenvolvimento de Conhecimentos</a></li>
            <li><a  routerLink="/environmentport"  (click)="isMenuCollapsed = true">Meio Ambiente</a></li>
            <li><a  routerLink="/healthport"  (click)="isMenuCollapsed = true">Saude E Benificencia</a></li>
            <li><a  routerLink="/maputoport"  (click)="isMenuCollapsed = true">Trac em Moçambique</a></li>
            <li><a  routerLink="/csiroadsafetyport"  (click)="isMenuCollapsed = true">Seguranca na estrada</a></li>
            <li><a  routerLink="/smmeport"  (click)="isMenuCollapsed = true">DESENVOLVIMENTO E SUPORTE</a></li>
            <li><a  routerLink="/sprtsandcultureport"  (click)="isMenuCollapsed = true">Desporto E Cultura</a></li>
          </ul>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Media</a>
          <ul class="mobilemenuItems list-style-none">
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem class="bold  fontsize125em routinemaintenanceNoHover white-text py-2" style="padding:0;">Trac Events</button>
            <li><a  routerLink="/traceventsport"  (click)="isMenuCollapsed = true">TRAC N4 Nacional Rally 2024</a></li>
            <li><a  routerLink="/traceventsElandsMarathon2024"  (click)="isMenuCollapsed = true">Trac N4 Elands Marathon 2024</a></li>
            <!-- <li><a  routerLink="/traceventsElandsMarathonport"  (click)="isMenuCollapsed = true">Trac N4 Elands Marathon 2023</a></li> -->
            <div class="dropdown-divider"></div>
            <li><a  routerLink="/pressreleasesport"  (click)="isMenuCollapsed = true">Press Releases</a></li>
          </ul>
          <a class="nav-link white-text"  routerLink="/contactport" (click)="isMenuCollapsed = true">Contacte</a>
        </li>
      </ul>
    </div>
  </div>


  <!-- ------------------------------------------------------------------------------ WEB DESKTOP PORTUGUESE MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP PORTUGUESE MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP PORTUGUESE MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP PORTUGUESE MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP PORTUGUESE MENU -->

  <nav class="my-2 my-md-0 mr-md-3 white-text d-none d-md-block" [ngbCollapse]="!isMenuCollapsed">
    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="homeport">Casa</a>
    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity fontsize085 pointer" href="return false;" id="dropdownAboutPort"
         ngbDropdownToggle>SOBRE</a>
      <div ngbDropdownMenu aria-labelledby="dropdownAboutPort" class="dropdownbackground">
        <button ngbDropdownItem routerLink="about-company-profileport">Prefile Da Empresa</button>
        <button ngbDropdownItem routerLink="about-historyport">HISTÓRIA</button>
        <button ngbDropdownItem routerLink="about-anti-corruptionport">PLANCO DE ACÇÃO ANTI – CORRUPÇÃO</button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownTender2" ngbDropdownToggle>Concursos</a>
      <div ngbDropdownMenu aria-labelledby="dropdownTender2" class="dropdownbackground">
        <button ngbDropdownItem routerLink="constructionport"> Projetos de construção </button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="bold  fontsize12em routinemaintenanceNoHover" style=""> Manutenção de Rotina </button>
        <!-- <div class="dropdown-divider"></div> -->
        <button ngbDropdownItem routerLink="smme-tenders-port">SMME Tenders</button>
        <button ngbDropdownItem routerLink="tenderresultsport"> Resultados de Concursos</button>
        <button ngbDropdownItem routerLink="routineport"> Especificações de Manutenção de Rotina</button>
        <div class="dropdown-divider"></div>
      </div>
    </div>


    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="jointheteamport"> Junte-se à equipe </a>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownPlaza2" ngbDropdownToggle> Plaza & Portagens</a>
      <div ngbDropdownMenu aria-labelledby="dropdownPlaza2" class="dropdownbackground">
        <button ngbDropdownItem routerLink="plazawhytollport"> Porquê e Portagem? </button>
        <button ngbDropdownItem routerLink="plazafeesport"> Plazas com Portagem & Tarifas de Portagem </button>
        <button ngbDropdownItem routerLink="plazaconcessionrequirementsport">Requerimentos de ConCessao</button>
        <!-- <a class="dropdown-item" href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Eng - March 2024.pdf" target="_blank" style="font-size: inherit!important;padding: 0.25rem 1.5rem;">Aviso de Trânsito</a> -->
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownLoadControl2" ngbDropdownToggle>
        Controlo de Carga</a>
      <div ngbDropdownMenu aria-labelledby="dropdownLoadControl2" class="dropdownbackground">
        <button ngbDropdownItem routerLink="loadcontrolport"> Controlo de Carga </button>
        <button ngbDropdownItem routerLink="loadcontrolcenterport"> NUMEROS DE CONTACTO DOS CENTROS DE CONTROLE DE CARGA </button>
      </div>
    </div>

    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="roadsafetyport"> Seguranca Na Estrada</a>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownCSI" ngbDropdownToggle> Investimentos Sociais </a>
      <div ngbDropdownMenu aria-labelledby="dropdownCSI" class="dropdownbackground">
        <button ngbDropdownItem routerLink="educationport"> Educação e Desenvolvimento de Conhecimentos </button>
        <button ngbDropdownItem routerLink="environmentport">  Meio Ambiente </button>
        <button ngbDropdownItem routerLink="healthport">  Saude E Benificencia </button>
        <button ngbDropdownItem routerLink="maputoport">Trac em Moçambique</button>
        <button ngbDropdownItem routerLink="csiroadsafetyport"> Seguranca na estrada </button>
        <button ngbDropdownItem routerLink="smmeport"> DESENVOLVIMENTO E SUPORTE </button>
        <button ngbDropdownItem routerLink="sprtsandcultureport">Desporto E Cultura </button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownMedia2" ngbDropdownToggle>Media</a>
      <div ngbDropdownMenu aria-labelledby="dropdownMedia2" class="dropdownbackground">
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="bold  fontsize125em routinemaintenanceNoHover white-text" style="">Trac Eventos</button>
        <button ngbDropdownItem routerLink="traceventsport">TRAC N4 Nacional Rally 2024</button>
        <!-- <button ngbDropdownItem routerLink="traceventsElandsMarathonport">Trac N4 Elands Marathon 2023</button> -->
        <button ngbDropdownItem routerLink="traceventsElandsMarathon2024">Trac N4 Elands Marathon 2024</button>
        <div class="dropdown-divider"></div>

        <button ngbDropdownItem routerLink="pressreleasesport">Press Releases</button>
      </div>
    </div>
    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="contactport">Contacte</a>
    <div ngbDropdown class="d-inline-block">
      <div class="p-2 text-uppercase white-text-50opacity pointer" >
        <img src="assets/images/uk-flag.jpg" class="img-fluid " (click)="setLanguage('english')" style="height: 20px;"/>
      </div>
    </div>
  </nav>
</div>

<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- -------------------------------------------English Footer below ------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- EEEEEEEEEEEEnglish  --------------------------------- ----------------------------------------------------- -->
<div *ngIf="langService.isEnglish"  class="d-flex flex-column flex-md-row align-items-center p-2 grey-gradient-background "
     style="position: absolute; top:0; left:0; z-index: 1010; width: 100%;  ">
  <div class="my-0 mr-md-auto font-weight-normal">
    <img routerLink="/home" [src]="logo" class="img-fluid p-2 pointer" style="height: 70px;"/>
  </div>

  <div class="d-md-none" style="position: absolute; right: 20px; top:20px;">
    <button class="navbar-toggler white-text" type="button"  *ngIf="isMenuCollapsed"(click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <button class="navbar-toggler white-text" type="button" *ngIf="!isMenuCollapsed" (click)="isMenuCollapsed">
      <div class="white-text" style="text-align:center; border:2px solid #fff;
      border-radius: 50%; height: 25px; width:25px; font-size: 14px; padding: 3px; position: absolute; right:24px; top:4px; " (click)="isMenuCollapsed = true">X</div>
    </button>
  </div>

  <div class="white-text"  *ngIf="!isMenuCollapsed" style="text-align:center;
      height: 45px; width:45px; font-size: 14px; padding: 3px; position: absolute; left:26px; top:20px; " >
    <img src="assets/images/portugal-flag.jpg" class="img-fluid " (click)="setLanguage('porto')" />
  </div>

  <!-- Step 2: Add the ngbCollapse directive to the element below. -->

  <!-- MOBILE MENU -->
  <div class="col " >
    <div [ngbCollapse]="isMenuCollapsed"  class="collapse navbar-collapse shadow">
      <ul class="navbar-nav mobilemenuItems">
        <li class="nav-item active">
          <!-- Step 4: Close the menu when a link is clicked. -->
          <a class="nav-link" routerLink="home" (click)="isMenuCollapsed = true">Home</a>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">About</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/about-company-profile"  (click)="isMenuCollapsed = true">Company Profile</a></li>
            <li><a  routerLink="/about-history"  (click)="isMenuCollapsed = true">History</a></li>
            <li><a  routerLink="/about-anti-corruption"  (click)="isMenuCollapsed = true">Anti-Corruption policy</a></li>
          </ul>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Tenders</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/smme-tenders"  (click)="isMenuCollapsed = true">SMME Tenders</a></li>
            <li><a  routerLink="/construction"  (click)="isMenuCollapsed = true">Construction Projects</a></li>
            <li><a  routerLink="/tenderresults"  (click)="isMenuCollapsed = true">Tender Results</a></li>
            <li><a  routerLink="/routine"  (click)="isMenuCollapsed = true">Routine Maintenance Spec</a></li>
          </ul>
          <a class="nav-link white-text" routerLink="/jointheteam"  (click)="isMenuCollapsed = true">Join the Team</a>

          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Plaza &amp; Fees</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/plazawhytoll"  (click)="isMenuCollapsed = true">Why Toll ?</a></li>
            <li><a  routerLink="/plazafees"  (click)="isMenuCollapsed = true">Toll Plazas & Fees</a></li>
            <li><a  routerLink="/plazaconcessionrequirements"  (click)="isMenuCollapsed = true">Concession Requirements</a></li>
            <!-- <li><a  href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Eng - March 2024.pdf?v=20240327" target="_blank" (click)="isMenuCollapsed = true">Traffic Advisory</a></li> -->
          </ul>
          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Load Control</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/loadcontrol"  (click)="isMenuCollapsed = true">Load Control</a></li>
            <li><a  routerLink="/loadcontrolcenter"  (click)="isMenuCollapsed = true">Load Control Centres Contact Details</a></li>

          </ul>
          <a class="nav-link white-text" routerLink="/roadsafety" (click)="isMenuCollapsed = true">Road Safety</a>

          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">CSI</a>
          <ul class="mobilemenuItems list-style-none">
            <li><a  routerLink="/education"  (click)="isMenuCollapsed = true">Education & Skills Development</a></li>
            <li><a  routerLink="/environment"  (click)="isMenuCollapsed = true">Environment</a></li>
            <li><a  routerLink="/health"  (click)="isMenuCollapsed = true">Health & Welfare</a></li>
            <li><a  routerLink="/maputo"  (click)="isMenuCollapsed = true">Maputo Corridor</a></li>
            <li><a  routerLink="/csiroadsafety"  (click)="isMenuCollapsed = true">Road Safety</a></li>
            <li><a  routerLink="/smme"  (click)="isMenuCollapsed = true">SMME Development & Support</a></li>
            <li><a  routerLink="/sprtsandculture"  (click)="isMenuCollapsed = true">Sports & Culture</a></li>
            <li><a  routerLink="/assets/csi/trac_csi_book.pdf" target="_blank" (click)="isMenuCollapsed = true">Making a Difference a Kilometre At A Time</a></li>
          </ul>

          <a class="nav-link white-text"  (click)="isMenuCollapsed = true">Media</a>
          <ul class="mobilemenuItems list-style-none">
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem class="bold  fontsize125em routinemaintenanceNoHover white-text py-2" style="padding:0;">Trac Eventos</button>
            <li><a  routerLink="/tracevents"  (click)="isMenuCollapsed = true">TRAC N4 National RALLY 2024</a></li>
            <li><a  routerLink="/traceventsElandsMarathon2024"
                    (click)="isMenuCollapsed = true">Trac N4 Elands Marathon 2024</a></li>
            <!-- <li><a  routerLink="/traceventsElandsMarathon2023"
                    (click)="isMenuCollapsed = true">Trac N4 Elands Marathon 2023</a></li> -->
            <div class="dropdown-divider"></div>
            <li><a  routerLink="/pressreleases"  (click)="isMenuCollapsed = true">Press Releases</a></li>
          </ul>
          <a class="nav-link white-text"  routerLink="/contact" (click)="isMenuCollapsed = true">Contact</a>
        </li>
      </ul>
    </div>
  </div>


  <!-- ------------------------------------------------------------------------------ WEB DESKTOP ENGLISH MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP ENGLISH MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP ENGLISH MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP ENGLISH MENU -->
  <!-- ------------------------------------------------------------------------------ WEB DESKTOP ENGLISH MENU -->

  <nav class="my-2 my-md-0 mr-md-3 white-text d-none d-md-block" [ngbCollapse]="!isMenuCollapsed">
    <a class="p-2 text-uppercase white-text-50opacity" href="#">Home</a>
    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity fontsize085 pointer" href="return false;" id="dropdownAbout"
         ngbDropdownToggle>About</a>
      <div ngbDropdownMenu aria-labelledby="dropdownAbout" class="dropdownbackground">
        <button ngbDropdownItem routerLink="about-company-profile">Company Profile</button>
        <button ngbDropdownItem routerLink="about-history">History</button>
        <button ngbDropdownItem routerLink="about-anti-corruption">Anti-Corruption policy</button>
      </div>
    </div>
    <!-- <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity fontsize085 pointer" href="return false;" id="dropdownAbout"
         ngbDropdownToggle>Gallery</a>
      <div ngbDropdownMenu aria-labelledby="dropdownAbout" class="dropdownbackground">
        <button ngbDropdownItem routerLink="gallery/rally-2024">Rally 2024</button>
      </div>
    </div> -->

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownTender" ngbDropdownToggle>Tenders</a>
      <div ngbDropdownMenu aria-labelledby="dropdownTender" class="dropdownbackground">
        <button ngbDropdownItem routerLink="construction">Construction Projects</button>
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="bold  fontsize125em routinemaintenanceNoHover" style="">Routine Maintenance</button>
        <!-- <div class="dropdown-divider"></div> -->
        <button ngbDropdownItem routerLink="smme-tenders">SMME Tenders</button>
        <button ngbDropdownItem routerLink="tenderresults">Tender Results</button>
        <button ngbDropdownItem routerLink="routine">Routine Maintenance Spec</button>
        <div class="dropdown-divider"></div>
      </div>
    </div>


    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="jointheteam">Join the team</a>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownPlaza" ngbDropdownToggle>Plaza & Toll</a>
      <div ngbDropdownMenu aria-labelledby="dropdownPlaza" class="dropdownbackground">
        <button ngbDropdownItem routerLink="plazawhytoll">Why Toll?</button>
        <button ngbDropdownItem routerLink="plazafees">Toll Plazas & Fees</button>
        <button ngbDropdownItem routerLink="plazaconcessionrequirements">Concession Requirements</button>
        <!-- <a class="dropdown-item" href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Eng - March 2024.pdf?v=20240327" target="_blank" style="font-size: inherit!important;padding: 0.25rem 1.5rem;">Traffic Advisory</a> -->
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownLoadControl" ngbDropdownToggle>
        Load Control</a>
      <div ngbDropdownMenu aria-labelledby="dropdownLoadControl" class="dropdownbackground">
        <button ngbDropdownItem routerLink="loadcontrol">Load Control</button>
        <button ngbDropdownItem routerLink="loadcontrolcenter">Load Control Center Contact Details</button>
      </div>
    </div>

    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="roadsafety">Road Safety</a>

    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownCSIcsi" ngbDropdownToggle>CSI</a>
      <div ngbDropdownMenu aria-labelledby="dropdownCSI" class="dropdownbackground dropdown-menu-right">
        <a class="dropdown-item" href="assets/csi/CSI Book - TRAC Uplifting Communities.pdf" target="_blank" style="font-size: inherit!important;padding: 0.25rem 1.5rem;">CSI Book - TRAC Uplifting Communities</a>
        <button ngbDropdownItem routerLink="education">Education & Skills Development</button>
        <button ngbDropdownItem routerLink="environment"> Environment</button>
        <button ngbDropdownItem routerLink="health"> Health & Welfare</button>
        <button ngbDropdownItem routerLink="maputo"> Maputo Corridor</button>
        <button ngbDropdownItem routerLink="csiroadsafety"> Road Safety</button>
        <button ngbDropdownItem routerLink="smme"> SMME Development & Support</button>
        <button ngbDropdownItem routerLink="sprtsandculture"> Sports & Culture</button>
      </div>
    </div>



    <div ngbDropdown class="d-inline-block">
      <a class="p-2 text-uppercase white-text-50opacity" href="#" id="dropdownMedia" ngbDropdownToggle>Media</a>
      <div ngbDropdownMenu aria-labelledby="dropdownMedia" class="dropdownbackground">
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem class="bold  fontsize125em routinemaintenanceNoHover white-text" style="">Trac Events</button>
        <button ngbDropdownItem routerLink="tracevents">TRAC N4 National RALLY 2024</button>
        <button ngbDropdownItem routerLink="traceventsElandsMarathon2024">Trac N4 Elands Marathon 2024</button>
        <!-- <button ngbDropdownItem routerLink="traceventsElandsMarathon2023">Trac N4 Elands Marathon 2023</button> -->
        <div class="dropdown-divider"></div>
        <button ngbDropdownItem routerLink="pressreleases">Press Releases</button>
      </div>
    </div>
    <a class="p-2 text-uppercase white-text-50opacity" href="#" routerLink="contact">Contact</a>
    <div ngbDropdown class="d-inline-block">
      <div class="p-2 text-uppercase white-text-50opacity pointer" >
        <img src="assets/images/portugal-flag.jpg" class="img-fluid " (click)="setLanguage('porto')" style="height: 20px;"/>
      </div>
    </div>
  </nav>
</div>
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- -------------------------------------------End of English Footer above------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- EEEEEEEEEEEEnglish  --------------------------------- ----------------------------------------------------- -->

<router-outlet></router-outlet>
<app-footer></app-footer>
