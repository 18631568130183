import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-anti-corruption-policyport',
  templateUrl: './about-anti-corruption-policyport.component.html',
  styleUrls: ['./about-anti-corruption-policyport.component.scss']
})
export class AboutAntiCorruptionPolicyportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
