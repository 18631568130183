<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow"  >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right ">SOBRE</h6>
          <h1 class="text-right ">PLANCO DE ACCÃO ANTI- CORRUPÇÃO</h1></div>
        <div class="col"><img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row py-4 ">
      <div class="col">
        <div class="container">
          <div id="block--7" class="block block--wysiwyg_block bold ">
            <div class="block__content"> <p>Todos os controlos assim como os procedimentos internos da TRAC têm como intenção detectar, prevenir ou detectar actividades impróprias e corruptas, tendo a TRAC no entanto conhecimento de que nem o melhor sistema de controlo pode providenciar segurança absoluta contra irregularidades, nós TRAC confiamos na boa-vontade e nos valores morais de cada individuo para que nos possam assistir em manter a empresa livre de corrupção.</p><p>Funcionários, intervenientes, sub-empreiteiros e membros do público em geral são desta forma denunciar e apoiar alegações respeitantes condução imprópria e corrupta,&nbsp; que possa ter efeito directo na posição financeira e imagem da empresa, através dos meios correctos e disponíveis. Todas as alegações serão investigadas, ora interinamente ou pelas autoridades relevantes da lei.<br></p><p>Alegações sobre corrupção ou acções impróprias devem ser relatadas pela linha TRAC Fraud Hotline +27 (0) 82 900 2447. As chamadas não serão atendidas da forma convencional, o quexoso deve deixar uma mensagem gravada que será ouvida por um oficial da TRAC que será particularmente designado. A TRAC agradece que os mais e melhores detalhes da queixa sejam detalhadamente mencionados, actos factuais e toda a informação específica possível. Todo e qualquer queixoso pode manter-se anónimo, é no entanto importante lembrar de que ao providenciar a informação de contacto pessoal poderá acelerar a investigação.<br></p><p>A gerência da TRAC mantém o direito em deteminar se as circunstâncias justificam uma investigação em conformidade com as politicas anti-corrupção, recursos humanos, leis aplicáveis e regulamentos.<br></p> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-black py-4 text-white">
      <div class="col">
        <div class="container"><h2 class="text-center text-white text-uppercase">Recompensa</h2><div class="dot-divider mb-3"><span class="dot-divider-white"></span></div><p>A gerência da TRAC poderá decidir em atribuir a recompensa de ZAR 10 000 â pessoa que em primeiro lugar relatou a actividade imprópria e, ou de corrupção. Este montante será determinado à discrição da gerência da TRAC, sendo certo que não será permitido comunicação ou qualquer tipo de disputa nesta matéria. De forma a qualificar-se para este incentivo o queixoso deverá ser identificável e providenciar informação detalhada.</p><p>&nbsp;A TRAC proibe estrimamente a retaliação, actos de importunar ou molestar, descriminação contra qualquer pessoa que relatou queixa no bom sentido de bem fazer, e no de acreditar em si próprio.</p></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col"><img src="./assets/images/anticorruption/Whistle-Blower-Creative-01.jpg" class="img-fluid"
                            alt="Whistle Blower 1 1 Resized">
        <h1 class="text-center"><span style="color: #000000;"><a class="clickphone" style="color: #000000;"
                                                                 href="tel:0829002447">+27 82 900 2447</a></span></h1>
      </div>
    </div>
  </div>

</main>
