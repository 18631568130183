<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow shadow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center ">
        <div class="col offset-2"><h6 class="m-0 text-right ">TENDERS</h6>
          <h1 class="text-right ">TENDER RESULTS</h1></div>
        <div class="col"><img src="./assets/images/tenders/Tender%20ResultsIcon-01.png" alt="Road"></div>
      </div>
    </div>
  </div>


  <!-- <div class="container">
    <div class="row pt-4">
      <div class="col-xs-12 col-md-12">
        <h5 class="text-center font-bold">TENDER RESULTS 2021</h5>
        <div class="dot-divider mb-3">
          <span class="dot-divider-black"></span>
        </div>
      </div>
    </div>
  </div> -->

  <div class="container">
    <!-- <div class="row py-5 mb-5">
      <div class="col-xs-12 col-md-12 text-center">
        <a class="apdfs text-center p-4" style=" border: 1px solid #fff; width:50%; border-radius: 6px; margin: 0 auto;"
            href="assets/xlsx/Valid_SMME_Tenders_To_Be_Evaluated.xlsx?v=20240723" target="_blank">
            <div class="h-25 " style="color:#fff; font-weight:600; min-height: 20px; ">Click Here to Download Current Tenders To Be Evaluated</div>
        </a>
      </div>
    </div> -->
    <div class="row py-5 my-5">
      <div class="col-sm-12 col-md-12">
        <h5 class="font-bold m-0">NO TENDERS RESULTS AT THE MOMENT</h5>
      </div>
    </div>
  </div>

  <!-- <div class="container" *ngIf="apiContent != ''">
    <div class="row py-4" *ngIf="this.apiContent.body.Data.length !== 0">
      <div class="col-sm-12 col-md-12">
        <h6 class="text-center font-bold">Please download relevant document for more information.</h6>
      </div>
    </div>


    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="row py-4" *ngIf="apiContent != ''">
          <ng-container *ngFor="let o of this.apiContent.body.Data; index as i">
            <div class="col-sm-12 col-md-6 text-center " style="border:0px solid #eee; min-height:vh20;  ">
              <div class="row ">
                <div class="col-12 h-100 ">
                  
           Category: "Construction Projects"
          Date: "2021-07-08T00:00:00"
          Description: "This is the Construction Project Tender 1"
          FileName: "ConstructionProjectTender1.pdf"
          ID: 1
          Name: "Construction Project Tender 1"

                  
                  <div class="text-center p-3 m-2 bg-black white-text h3 rounded ">{{o.Category}}</div>
                  <div class="text-left p-1 m-2 h5 rounded border-bottom ">Date: {{o.Date | date:'dd MMMM Y'}}</div>
                  <h5 class="text-left p-1 m-2 h5 border-bottom">{{o.Description}}</h5>
                  <h5 class="text-left p-1 m-2 h5 border-bottom">PDF document : {{o.FileName}}</h5>
                  <br/><br/>
                  <a class="apdfs text-center " style=" border: 1px solid #fff; width:100%; border-radius: 3px;"
                     href="{{this.appService.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/{{o.ID}}"
                     title="Click and View PDF Document"
                     target="_blank">Download Document</a>

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div> -->


  <div class="container" *ngIf="apiContent != ''">
    <!-- <div class="row py-6" *ngIf="this.apiContent.body.Data.length == 0">
      <div class="col-xs-12 col-md-12">
        <div class="row py-4 empty-content">
          <div class="col-xs-12 col-md-12 d-flex align-items-center justify-content-center">
            <h2 class="text-center">
              <div id="block--9" class="block block--wysiwyg_block m-5">
                <div class="block__content h6 m-5">THERE&nbsp;ARE&nbsp;NO&nbsp;CURRENT TENDERS FOR&nbsp;CONSTRUCTION&nbsp;PROJECTS&nbsp;AVAILABLE.
                </div>
              </div>
            </h2>
          </div>
        </div>
      </div>
    </div>  -->
    <!-- end of row -->


  </div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:9.81%;">
        <div style="position: absolute; width:100%; overflow: hidden; height: 248px; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>


</main>
