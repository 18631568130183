<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow shadow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">ROAD SAFETY</h1></div>
        <div class="col"><img src="./assets/images/roadsafety/Road%20Safety%20Icon-01.png" alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="home-action-section text-center">
    <div class="container">
      <div class="row py-4">


        <!-- Image 1 with black mouse over                                        Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%201.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content1)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of image selection 1 -->

        <!-- Image 2 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%202.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content2)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 3 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%203.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content3)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->


        <!-- Image 4 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%204.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content4)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 5 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%205.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content5)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 6 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%206.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content6)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 7 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%207.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content7)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 8 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%208.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content8)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 9 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%209.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content9)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 10 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2010.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content10)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->



        <!-- Image 11 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2011.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content11)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 12 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2012.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content12)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 13 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2013.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content13)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 14 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2014.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content14)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->


        <!-- Image 15 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2015.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content15)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 16 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2016.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content16)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 17 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2017.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content17)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->


        <!-- Image 18 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2018.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content18)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->

        <!-- Image 19 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-3 text-center mb-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/RS%2019.jpg" class="img-fluid shadow" alt="Road Safety">
            </div>
            <div class="cta-content hide bg-black" (click)="open(content19)">
              <h3 class="text-center">Learn More</h3>
              <div class="dot-divider mb-3">
                <span class="dot-divider-white"></span>
              </div>
            </div>
          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->


        <!-- Image 19 with black mouse over                                       Slide -->
        <div class="col-sm-12 col-md-12 text-center m-3">
          <div class="actionlink" href="" >
            <div class="cta-img">
              <img src="./assets/images/roadsafety/arriveAlive.png" class="img-fluid " alt="Road Safety">
            </div>

          </div>
        </div><!-- end of col-sm-12 md-3 image selection 1 -->




      </div> <!-- end of row -->
    </div>
  </div> <!-- end of image selection 1 -->
</main>


<ng-template #content1 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%201.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>



<ng-template #content2 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="">Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%202.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>


<ng-template #content3 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="">Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%203.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>


<ng-template #content4 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%204.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>


<ng-template #content5 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%205.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>


<!--        ----------------------------------------------------- -->

<ng-template #content6 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%206.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content7 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%207.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content8 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%208.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content9 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%209.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content10 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2010.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>












<!--        ----------------------------------------------------- -->

<ng-template #content11 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2011.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content12 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2012.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content13 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2013.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content14 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2014.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content15 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2015.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>







<!--        ----------------------------------------------------- -->

<ng-template #content16 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2016.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content17 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2017.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content18 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2018.jpg" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>

<ng-template #content19 let-modal>
  <div class="modal-header">
    <h4 class="modal-title" >Road Safety</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="./assets/images/roadsafety/RS%2019.jpg" class="img-fluid"  alt=""/>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>



<ng-template #content55 let-modal>
  <div class="modal-header bg-yellow ">
    <h4 class="modal-title" id="modal-basic-title5">Announcement</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body   grey-gradient-background2 p-4">
    <div class="container-fluid">
      <div class="row">
      <div class="col-sm-12 col-lg-6 shadow-lg">
        <img src="./assets/crime-awareness/TRAC-Anti-Crime-Poster-Back-Final-July-2022-min.jpg" class="img-fluid"  alt="Announcement"/>

      </div>
      <div class="col-sm-12 col-lg-6 shadow-lg">
        <img src="./assets/crime-awareness/TRAC-Anti-Crime-Poster-Front-Final-July%202022-min.jpg" class="img-fluid"  alt="Announcement"/>

      </div>
      </div>
    </div>


  </div>
  <div class="modal-footer bg-yellow">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
  </div>
</ng-template>
<style>

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 12.5rem 1rem 0 1rem !important;
    pointer-events: none;
  }

</style>
