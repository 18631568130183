<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow shadow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col offset-2"><h6 class="m-0 text-right ">Mapa</h6>
                    <h1 class="text-right text-uppercase ">Mapa da nossa rota</h1></div>
                <div class="col"><img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us"></div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 p-3  text-center   ">
                <div class=" black-text bold h4">
                    Estrada com Portagem
                </div>
            </div>
            <div class="col-12 p-3  text-center ">
                <img title="More information on map" src="./assets/images/home/UiIcons_Location.png"
                     class="img-fluid pr-2"
                     style="height:28px;">Clique no <span class="p-2 rounded"
                                                          style="background-color: #4CAF50; color:#fff">verde</span>
                e <span class="p-2 rounded" style="background-color: #f44336; color:#fff">vermelha</span> marcador.
                <hr/>
            </div>

            <div class="col-12 mb-2  ">
                <div class="row text-center justify-content-center align-middle" style="height: 364px;">
                    <iframe #theiframe id="theiframe" [ngStyle]="{'width':setWidth}" [src]="url | safe"
                            class="text-center">
                    </iframe>

                </div>
            </div>
        </div>
    </div>

    <div class="home-after-map-section">
        <div class="container">
            <div class="row py-4">



                <div class="col-sm-12 col-md-12">
                    <hr/>

                    <header>
                        <div class="postmaintitle-holder" >
                            <h1 class="mt-5 black-text">{{this.PageName}}</h1>
                            <span class="greenseparator small left"></span></div>
                        <div class="post-title mt-5">
                            <span class="posttitledate mr-2">{{this.PageDate | date:'dd MMMM'}}</span>
                            <h2 class="posttitle mb-0"> - {{this.PageName}}</h2>
                            <div class="meta w-100 float-left"><span class="author">Administrator</span>&nbsp;
                                <time class="date mr-2" datetime="">
                                    {{this.PageDate | date:'dd MMMM YYYY'}}
                                </time>

                            </div>
                        </div>
                    </header>
                    <hr/>
                    <div class="" *ngIf="this.apiContent !== ''">

                        <div class="content" [innerHTML]="this.PageData | safeHtml">

                        </div>

                    </div>
                    <hr/>
                </div>

            </div>
        </div>
    </div>


</main>

<ng-template #content1 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title4">Traffic Advisory</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/traffic-advisory/port-April2022.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>
