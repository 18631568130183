<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">TRAC EM
            MOÇAMBIQUE</h1></div>
        <div class="col"><img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>A TRAC é a única concessionária trans-fronteiriça em Africa Austral. Para além se ser um interviniente chave no desenvolvimento
        económico do Corredor de Maputo,a TRAC é também responsável pelo desenvolvimento da infraestrutura, da N4 Rota com Portagem,
        tendo proporcionado o cimentar de relações previligiadas e especiais com as comunidades ao longo da nossa estrada nacional, onde se
        inclui muito orgulhosamente Moçambique.</p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiMaputo/Moz-Sports-04.jpg'); background-size: cover; background-position: 26% 30%;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">
        TEMOS UM EXTENSO MANDATADO DE INVESTIMENTOS SOCIAS AO LONGO DO PERCURSO DA NOSSA<br/>
        ESTRADA EM MOÇAMBIQUE, NO QUAL SE INCLUEM AS SEGUINTES INICIATIVAS:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTOCORRIDR%20Icons_ESCOLARMARIA%20DELINGAMO.png" alt="" class="img-fluid imgsize"/>
        <h6>ESCOLA PRÍMARIA DE
          LINGAMO</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTOCORRIDORIcons_ESCOLAPRMARIDETREVO.png" alt=""  class="img-fluid imgsize"/>
        <h6>ESCOLA PRÍMARIA DE
          TREVO</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_ADPP.png" alt=""  class="img-fluid imgsize"/>
        <h6>ADPP</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_CAPAZ.png" alt="" class="img-fluid imgsize"/>
        <h6>CAPAZ</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_MATOLA%20MARATHON.png" alt="" class="img-fluid imgsize"/>
        <h6>MATOLA MARATHON</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_AGUIAS%20ESPECIAIS.png" alt=""  class="img-fluid imgsize"/>
        <h6>AGUIAS ESPECIAIS</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiMaputo/MAPUTO%20CORRIDOR%20%20Icons_BURSARIES.png" alt=""  class="img-fluid imgsize"/>
        <h6>BOLSAS</h6>
      </div>

    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="container-fluid">
        <div class="row py-4">
          <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/environmentport">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">MEIO AMBIENTE</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->
          <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/educationport">
              <div class="cta-img"><img
                src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->

          <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/healthport">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">SAÚDE E BENIFICÊNCIA</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->
           <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/csiroadsafetyport">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">SEGURANÇA RODOVIÁRIA</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->
          <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/smmeport">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">DESENVOLVIMENTO SOCIAL
                  E ECONÔMICO</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->
          <div class="col-sm-12 col-md-2 text-center">
            <a href="" routerLink="/sprtsandcultureport">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">DESPORTO E CULTURA</h3>
              </div>
            </a>
          </div> <!-- **************** **************** ************************************************************* -->
        </div>
      </div>
    </div>
  </div>

</main>
