import {Component, OnInit, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import {NgbCarouselConfig, NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {AppsettingsService} from '../../services/appsettings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements AfterViewInit {

  images = [1, 2, 3, 4].map((n) => `./assets/images/Home-Banner-${n}.jpg`);
  closeResult = '';
  setWidth: any;
  url = '';
  showNavigationArrows = false;
  showNavigationIndicators = false;
  appService: any;

  options: NgbModalOptions = {
    modalDialogClass: 'customModalDialogClass'
  };

  @ViewChild('content5') content5: ElementRef | undefined;

  constructor(
    config: NgbCarouselConfig,
    public appSetting: AppsettingsService,
    private modalService: NgbModal,
    private myElement: ElementRef
  ) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
    // console.log(appSetting.apiBaseUrl);
    // src="{{this.appSetting.apiBaseUrl}}/TracMap/"
    // https://41.193.27.5/tracmap/
  }

  ngAfterViewInit(): void {
    this.setWidth = '1700px';
    this.appSetting.loadAppConfig().subscribe((response: any) => {
      this.url = response.apiBaseUrl + '/tracmap/';

    });
    // this.modalService.open(this.content5, this.options);
    // this.open(this.content5, this.options);
  }
  
  public open(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
