import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-company-profile',
  templateUrl: './about-company-profile.component.html',
  styleUrls: ['./about-company-profile.component.scss']
})
export class AboutCompanyProfileComponent implements OnInit {


  images = [1, 2, 3, 4].map((n) => `./assets/images/about/Company%20Profile%20Carousel_${n}.jpg`);


  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;

  }

  ngOnInit(): void {
  }

}
