<main id="main" class="about-template">
    <!-- <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col">
                    <h1 class="row justify-content-center align-items-center bold">TRAC National Rally 2024
                        <span class="ml-4"><img src="./assets/images/media/Trac%20Events%20Page%20Icon-01.png" style="height: 100px; width: auto" alt="Events"></span>
                    </h1>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="jumbotron jumbotron-fluid m-0 image-cover" style="background-image: url('./assets/images/media/rally2024/TRAC-Banner-Rally2024.webp?v=20241112');"> -->
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-sm-12 px-0">
                    <a target="_blank" href="./assets/rally-2024/2024-TRAC-N4-Rally-Guide.pdf?v=20241113" class="p-0 d-block">
                        <img src="./assets/images/media/rally2024/TRAC-Banner-Rally2024.webp?v=20241112" style="height: auto; width: auto" alt="Trac Rally 2024">
                    </a>
                </div>
                <!-- <img src="./assets/images/media/rally2024/trac-rally-badge-2024.webp?v20240710" style="height: 200px; width: auto" alt="Trac Rally 2024"> -->
                <!-- <img src="./assets/images/home-tiles/trac-rally-2024-banner.webp?v20240710" style="width: auto" alt="Trac Rally 2024"> -->
            </div>
        </div>
    <!-- </div> -->
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center my-5">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase mb-5">TRACN4 2023 NATIONAL RALLY VIDEO HIGHLIGHTS</h3>
                    <iframe width="100%" height="500" src="https://www.youtube.com/embed/XeHPMY-p96s?si=xtojUg60IOws2PV-"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/F8yZGJLaNLs"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/hJ7Txc5aoZY?si=-U2KDSikdrtjGBjg"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/-g1nLofP19g?si=o5wag4Pit3LEcYo8"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/TYtHIMsbV_k?si=IfVnKbmoz20YO3iI"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div class="container-fluid" style="background-color: #2020200a;">
        <div class="row">
            <div class="col-sm-12 text-center mt-5">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">ACTION GALLERY</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mt-3 mb-3 text-center" style="display: flex; flex-direction: column;">
                <!-- <span>{{imageCounter}} / 126</span> -->
                <img [src]="imageListVar" style="height:100%;" class="img-fluid d-block mx-auto rounded" alt="Trac Rally 2023"/>
                <button
                        class="slider-btn slider-btn-left"
                        (click)="previousImage()">&larr;
                </button>
                <button class="slider-btn slider-btn-right" (click)="nextImage()">&rarr;
                </button>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-4 col-md-3 px-1 py-1" *ngFor="let img of this.rallyimages;  index as i">
                    <div [attr.id]="i" class="" style="background-image: url('{{img.url}}'); width: 100%; height: 150px; background-size: cover; background-position: center;" (click)="imageSelected($event)">
                        <!-- <img src="{{img.url}}" height="150" /> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col text-center py-3 mt-2">
                <a class="actionlink abutton" href="" routerLink="/gallery/rally-2024">View Full Gallery</a>
            </div>
        </div>
        <br/>
    </div>
    <div class="container-fluid">
        <div class="row py-3">
            <div class="col-sm-12">
                <div class="container  ">
                    <div class="col-sm-12 col-lg-12 white-text mt-5 my-3 p-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="mt-3 mb-3 black-text">
                                    <div class="removeMarginOnSmallScreensSpectatorGuide text-left">
                                        <!-- <h3 class="text-uppercase">THE 2023 TRACN4 NATIONAL RALLY IN DULLSTROOM, MPUMALANGA, WAS A RESOUNDING
                                            TRIUMPH, THANKS TO IDEAL WEATHER CONDITIONS, DEMANDING STAGES, AND
                                            OUTSTANDING DRIVING. SEE OUR GALLERY OF THE ACTION BELOW</h3> -->
                                        <p>The TRACN4 Rally, the crown jewel of the South African National Rally Championship (SANRC), roars back to life on November 15-16, 2024, in Dullstroom, Mpumalanga. This year's iteration marks the thrilling finale of the SANRC, the oldest and most enduring championship in South African motorsport history.</p>
                                        <p>Brace yourselves for a weekend pulsating with high-octane action! Spectators can expect to witness displays of exceptional driving skill as competitors navigate the demanding terrain of Dullstroom's surrounding forests. The Emakhazeni leg promises a grueling yet exhilarating two-day battle across meticulously designed stages.</p>
                                        <p>For more information, stay tuned to this page and follow our social media channels for updates.</p>
                                    </div>
                                </div>
                                <div class="mt-5 mb-3 black-text">
                                    <div class="removeMarginOnSmallScreensSpectatorGuide text-left">
                                        <!-- <h3 class="text-uppercase">THE 2023 TRACN4 NATIONAL RALLY IN DULLSTROOM, MPUMALANGA, WAS A RESOUNDING
                                            TRIUMPH, THANKS TO IDEAL WEATHER CONDITIONS, DEMANDING STAGES, AND
                                            OUTSTANDING DRIVING. SEE OUR GALLERY OF THE ACTION BELOW</h3> -->
                                        <p><strong>2024 TRACN4 NATIONAL RALLY DETAILS</strong></p>
                                        <p><strong>Venue: </strong>Dullstroom</p>
                                        <p><strong>Stages: </strong>TBA</p>
                                        <p><strong>Price: </strong>FREE for Spectators</p>
                                        <p><strong>Time: </strong>TBA</p>
                                        <p><strong>Duration: </strong>Two Days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6" style="text-align: center;">
                                <!-- <img src="./assets/images/media/rally2024/trac-rally-artwork-2024.webp?v20240710" alt="2024 TRACN4 National Rally Programme" class="img-fluid"/> -->
                                <img src="./assets/images/media/rally2024/trac-rally-badge-2024.webp?v20240710" style="width: 70%; margin: auto;" alt="2024 TRACN4 National Rally Programme" class="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="container-fluid" style="background-color: #2020200a;">
        <div class="row">
            <div class="col">
                <div class="container pt-2 pb-5">
                    <div class="row py-4 text-center">
                        <div class="col">
                            <h3 class="text-uppercase">PARTNERS</h3>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center" style="text-align : center; background-color: #fff;">
                        <div class="col-sm-3 mb-3 ">
                            <img src="./assets/images/media/TracN4.jpg?v20240716" alt="" class="img-fluid"
                                 class="img-fluid text-center   mx-auto d-block" alt=""/>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/images/media/MSA.jpg?v20240716" alt="" class="img-fluid text-center  mx-auto d-block" alt=""/>
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/nrc-logo2022.png?v20240716" alt=""
                                 class="img-fluid text-center  mx-auto d-block">
                        </div>
                        <div class="col-sm-3 mb-3 ">
                            <img src="./assets/images/media/SSTlogo.png?v20240716" alt="SST Logo"
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/images/media/new/RacedayTV.png?v20240716" alt="Race day"
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/rally2022/S&NTmbers.jpg?v20240716" alt=""
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/images/media/rally2023/crystal-by-ctrack.webp?v20240716" alt=""
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/images/media/rally2024/tgr-logo.webp?v20240716" alt="TGR"
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3 ">
                            <img src="./assets/images/media/rally2024/safcol-logo-2.webp?v20240716" alt="SAFCOL"
                                 class="img-fluid text-center mx-auto d-block p-3">
                        </div>
                        <div class="col-sm-3 mb-3">
                            <img src="./assets/images/media/rally2024/mibern-logo.webp?v20240716" alt="Mibern"
                                 class="img-fluid text-center  mx-auto d-block">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center align-items-center" style="text-align : center;">
            <div class="col-sm-3 mb-3 ">
                <img src="./assets/images/media/rally2024/safcol-logo.webp" alt="SAFCOL"
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-3 mb-3">
                <img src="./assets/images/media/rally2024/tgr-logo.webp" alt="TGR"
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-3">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">2023 WINNERS AND TOP PERFORMERS</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 mt-2 mb-5" style="text-align: center; background: #f5f5f5;">
                <div>{{imageCounter2}} / 121</div>
                <button class="" (click)="open(popup); highResolution()" style="background: transparent; border: none; padding: 0;">
    
                <img 
                    [src]="imageListVar2" 
                    style="height:100%;" 
                    class="img-fluid d-block mx-auto rounded" 
                    alt="Trac Rally 2023"
                    (click)="onClick($event)"
                    data-toggle="modal" data-target="#myModal"
                />
                </button>
                <button
                        class="slider-btn slider-btn-left"
                        (click)="previousImage2()">&larr;
                </button>
                <button class="slider-btn slider-btn-right" (click)="nextImage2()">&rarr;
                </button>
            </div>
        </div>
    </div> -->
    <!-- <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center mt-2 mb-5">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">TRACN4 NATIONAL RALLY VIDEO HIGHLIGHTS</h3>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/F8yZGJLaNLs"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/hJ7Txc5aoZY?si=-U2KDSikdrtjGBjg"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/-g1nLofP19g?si=o5wag4Pit3LEcYo8"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div class="col-sm-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/TYtHIMsbV_k?si=IfVnKbmoz20YO3iI"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
    </div> -->
    <!-- <div class="container-fluid">
        <div class="row py-4">
            <div class="col">
                <h3 class="text-center">SPONSORS</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-3 col-lg-2 mb-3 mt-2 offset-1">
                <img src="./assets/images/media/rally2023/rise-fm.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
        </div>
    </div> -->
    <div class="header  bg-yellow py-4">
        <h3 class="text-center text-black bold">PRESS RELEASES 2024</h3>
    </div>
    <div class="container">
        <div class="row" *ngIf="requestFailed">
            <div class="col-sm-12 col-md-12 py-4">
                <div class="post-title">
                    <h5 class="mb-0 bold text-center">
                        No Current Press Releases
                    </h5>
                </div>
            </div>
        </div>
        <div class="row py-4" *ngIf="this.apiContent !== ''">
            <ng-container *ngFor="let o of this.apiContent.body?.Data;  index as i ">
                <div *ngIf="o.Date>='2024-01-01'" class="col-sm-6 col-md-6 py-4">
                    <div class="post-title">
                        <h2 class="posttitle mb-0 bold"><span
                                class="posttitledate mr-2">{{o.Date | date:'dd MMMM'}}</span>{{o.Name}}</h2></div>
                    <div class="post-summary"><p class="float-left w-100 ">{{o.Introduction}}</p>
                        <a class="h6" routerLink="/readmoretracevents/{{o.ID}}/{{i}}">Read More</a></div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="header  bg-yellow py-4">
        <h3 class="text-center text-black bold">PRESS RELEASES 2019 - 2023</h3>
    </div>
    <div class="container">
        <div class="row" *ngIf="requestFailed">
            <div class="col-sm-12 col-md-12 py-4">
                <div class="post-title">
                    <h5 class="mb-0 bold text-center">
                        No Current Press Releases
                    </h5>
                </div>
            </div>
        </div>
        <div class="row py-4" *ngIf="this.apiContent !== ''">
            <ng-container *ngFor="let o of this.apiContent.body?.Data;  index as i ">
                <div *ngIf="o.Date<='2023-12-31'" class="col-sm-6 col-md-6 py-4">
                    <div class="post-title">
                        <h2 class="posttitle mb-0 bold"><span
                                class="posttitledate mr-2">{{o.Date | date:'dd MMMM'}}</span>{{o.Name}}</h2></div>
                    <div class="post-summary"><p class="float-left w-100 ">{{o.Introduction}}</p>
                        <a class="h6" routerLink="/readmoretracevents/{{o.ID}}/{{i}}">Read More</a></div>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- <div class="container">
        <div id="relatedlinkstolls" class="row pt-5">
            <div class="col-sm-12 col-md-12">
                <h5 class="text-center text-uppercase ">Related Links</h5>
            </div>
        </div>
        <div id="" class="row pb-5 related-blocks mx-sm-1 justify-content-center">
            <div class="col-sm-12 col-md-4 text-center block-bg-yellow p-0 mb-2 mx-0 mx-md-5">
                <a class="actionlink p-0" href="" routerLink="/pressreleases">
                    <div class="p-3 related-block-content block-content-reverse">
                        <div class="cta-img d-flex justify-content-center align-items-start" style="height: 280px;">
                            <img class="" src="./assets/images/icons/press-release-icon.png?v20241112" style="height: 150px; position: absolute;" alt="Press Releases">
                            <div class="related-block-content-headings">
                                <h5 class="mt-3 mb-0 black-text">Press Releases</h5>
                                <h6 class="mt-2 black-text">Find out more</h6>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div> -->
</main>

<ng-template #popup let-modal class="slider-popup">
    <div class="slider-popup">
    <!-- <div class="modal-header" style="background: transparent!important;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="modal-title" id="modal-basic-title4">2023 WINNERS AND TOP PERFORMERS - {{imageCounter}} of 167</h4>

                </div>
                <div class="col-sm-12">
                    <div>
                        <button
                                class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="previousImageRes()" style="width:50%;border-right: 2px solid #fff;">Previous
                        </button>
                        <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="nextImageHighRes()" style="width:50%;">Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-header" style="position: absolute; z-index: 9; right: 0; border: none;">
        <button type="button" class="btn btn-outline-light" (click)="modal.close('close click')">Close</button>
    </div>
    <div class="modal-body">
        <img [src]="imageListVar2"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
        <div class="col-sm-12 position-absolute" style="top: 50%;">
            <div>
                <button
                        class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                        (click)="previousImageRes()" style="width:60px;">&larr;
                </button>
                <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                        (click)="nextImageHighRes()" style="width:60px; position: absolute; right: 15px;">&rarr;
                </button>
            </div>
        </div>
    </div>
    </div>
</ng-template>