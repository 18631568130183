<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 image-cover" style="background-image: url('./assets/images/media/rally2023/banner-1.webp?v=20241112'); height: 25vh;">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <!-- <img src="./assets/images/media/rally2024/trac-rally-badge-2024.webp?v20240710" style="height: 200px; width: auto" alt="Trac Rally 2024"> -->
                <!-- <img src="./assets/images/home-tiles/trac-rally-2024-banner.webp?v20240710" style="width: auto" alt="Trac Rally 2024"> -->
            </div>
        </div>
    </div>
    <div class="container-fluid" style="background-color: #2020200a;">
        <div class="row">
            <div class="col-sm-12 text-center mt-5">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase mb-4">RALLY 2024 GALLERY</h3>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-sm-12 mt-3 mb-3 text-center" style="display: flex; flex-direction: column;">
                <span>{{imageCounter}} / {{this.rallyimagescount}}</span>
                <img [src]="imageListVar" style="height:100%;" class="img-fluid d-block mx-auto rounded" alt="Trac Rally 2023"/>
                <button
                        class="slider-btn slider-btn-left"
                        (click)="previousImage()">&larr;
                </button>
                <button class="slider-btn slider-btn-right" (click)="nextImage()">&rarr;
                </button>
            </div>
        </div> -->
        <div class="container">
            <div class="row">
                <div class="col-4 col-md-3 px-1 py-1" *ngFor="let img of this.rallyimages;  index as i">
                    <div [attr.id]="i" class="" style="background-image: url('{{img.url}}'); width: 100%; height: 150px; background-size: cover; background-position: center;" (click)="imageSelected($event)">
                        <!-- <img [attr.id]="i" src="{{img.url}}" height="150" /> -->
                    </div>
                </div>
            </div>
        </div>
        <br/>
    </div>
</main>

<ng-template #popup let-modal class="slider-popup">
    <div class="slider-popup">
    <!-- <div class="modal-header" style="background: transparent!important;">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="modal-title" id="modal-basic-title4">2023 WINNERS AND TOP PERFORMERS - {{imageCounter}} of 167</h4>
                </div>
                <div class="col-sm-12">
                    <div>
                        <button
                                class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="previousImageRes()" style="width:50%;border-right: 2px solid #fff;">Previous
                        </button>
                        <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="nextImageHighRes()" style="width:50%;">Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div> -->
    <div class="modal-header" style="position: absolute; z-index: 9; right: 0; border: none;">
        <button type="button" class="btn btn-outline-light" (click)="modal.close('close click')">Close</button>
    </div>
    <div class="modal-body">
        <img [src]="imageListVar"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
        <div class="col-sm-12 position-absolute" style="top: 50%;">
            <div>
                <button
                        class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                        (click)="previousImage()" style="width:60px;">&larr;
                </button>
                <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                        (click)="nextImage()" style="width:60px; position: absolute; right: 15px;">&rarr;
                </button>
            </div>
        </div>
    </div>
    </div>
</ng-template>