import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AboutCompanyProfileComponent} from './components/about-company-profile/about-company-profile.component';
import {HomeComponent} from './components/home/home.component';
import {AboutAntiCorruptionPolicyComponent} from './components/about-anti-corruption-policy/about-anti-corruption-policy.component';
import {AboutHistoryComponent} from './components/about-history/about-history.component';
import {TenderresultsComponent} from './components/tenders/tenderresults/tenderresults.component';
import {RoutineComponent} from './components/tenders/routine/routine.component';
import {SMMETendersComponent} from './components/tenders/smmetenders/smmetenders.component';
import {SMMETendersPortComponent} from './components/tenders/smmetenders/smmetendersport/smmetendersport.component';
import {ConstructionComponent} from './components/tenders/construction/construction.component';
import {JointheteamComponent} from './components/jointheteam/jointheteam.component';
import {WhytollComponent} from './components/plaza/whytoll/whytoll.component';
import {TollplazafeesComponent} from './components/plaza/tollplazafees/plazafees';
// import {TollplazaAndfeesComponent} from './components/plaza/toll-plaza-and-fees/toll-plaza-and-fees';
import {ConcessionrequirementsComponent} from './components/plaza/concessionrequirements/concessionrequirements.component';
import {ContactComponent} from './components/contact/contact.component';
import {LoadcontrolComponent} from './components/loadcontrol/loadcontrol/loadcontrol.component';
import {LoadcontrolcenterComponent} from './components/loadcontrol/loadcontrolcenter/loadcontrolcenter.component';
import {EducationComponent} from './components/csi/education/education.component';
import {EnvironmentComponent} from './components/csi/environment/environment.component';
import {HealthComponent} from './components/csi/health/health.component';
import {MaputoComponent} from './components/csi/maputo/maputo.component';
import {CsiroadsafetyComponent} from './components/csi/csiroadsafety/csiroadsafety.component';
import {SmmeComponent} from './components/csi/smme/smme.component';
import {SportsandcultureComponent} from './components/csi/sportsandculture/sportsandculture.component';
import {TrackeventsComponent} from './components/media/trackevents/trackevents.component';
import {PressreleasesComponent} from './components/media/pressreleases/pressreleases.component';
import {RoadsafetyComponent} from './components/roadsafety/roadsafety.component';
import {LiverouteComponent} from './components/liveroute/liveroute.component';
import {ReadmoreComponent} from './components/media/readmore/readmore.component';
import {OpendocumentComponent} from './components/opendocument/opendocument.component';
import {HomeportComponent} from './components/home/homeport/homeport.component';
import {LiverouteportComponent} from './components/liveroute/liverouteport/liverouteport.component';
import {AboutCompanyProfileportComponent} from './components/about-company-profile/about-company-profileport/about-company-profileport.component';
import {AboutHistoryportComponent} from './components/about-history/about-historyport/about-historyport.component';
import {JointheteamportComponent} from './components/jointheteam/jointheteamport/jointheteamport.component';
import {RoutineportComponent} from './components/tenders/routine/routineport/routineport.component';
import {TenderresultsportComponent} from './components/tenders/tenderresults/tenderresultsport/tenderresultsport.component';
import {AboutAntiCorruptionPolicyportComponent} from './components/about-anti-corruption-policy/about-anti-corruption-policyport/about-anti-corruption-policyport.component';
import {ConstructionportComponent} from './components/tenders/construction/constructionport/constructionport.component';
import {LoadcontrolportComponent} from './components/loadcontrol/loadcontrol/loadcontrolport/loadcontrolport.component';
import {ConcessionrequirementsportComponent} from './components/plaza/concessionrequirements/concessionrequirementsport/concessionrequirementsport.component';
import {LoadcontrolcenterportComponent} from './components/loadcontrol/loadcontrolcenter/loadcontrolcenterport/loadcontrolcenterport.component';
import {WhytollportComponent} from './components/plaza/whytoll/whytollport/whytollport.component';
import {TollplazafeesportComponent} from './components/plaza/tollplazafees/tollplazafeesport/tollplazafeesport.component';
import {ReadmoreportComponent} from './components/media/readmore/readmoreport/readmoreport.component';
import {HealthportComponent} from './components/csi/health/healthport/healthport.component';
import {EnvironmentportComponent} from './components/csi/environment/environmentport/environmentport.component';
import {EducationportComponent} from './components/csi/education/educationport/educationport.component';
import {SportsandcultureportComponent} from './components/csi/sportsandculture/sportsandcultureport/sportsandcultureport.component';
import {PressreleasesportComponent} from './components/media/pressreleases/pressreleasesport/pressreleasesport.component';
import {TrackeventsportComponent} from './components/media/trackevents/trackeventsport/trackeventsport.component';
import {MaputoportComponent} from './components/csi/maputo/maputoport/maputoport.component';
import {CsiroadsafetyportComponent} from './components/csi/csiroadsafety/csiroadsafetyport/csiroadsafetyport.component';
import {RoadsafetyportComponent} from './components/roadsafety/roadsafetyport/roadsafetyport.component';
import {SmmeportComponent} from './components/csi/smme/smmeport/smmeport.component';
import {ReadmoretrackeventComponent} from './components/media/readmoretrackevent/readmoretrackevent.component';
import {ReadmoretrackeventportComponent} from './components/media/readmoretrackeventport/readmoretrackeventport.component';
import {ContactportComponent} from './components/contact/contactport/contactport.component';
import {TrackeventsElandsMarathonComponent} from './components/media/trackevents-elands-marathon/trackevents-elands-marathon.component';
import {
  TrackeventsElandsMarathonportComponent
} from './components/media/trackevents-elands-marathonport/trackevents-elands-marathonport.component';
import {ElandsMarathan2023Component} from './components/media/elands-marathan2023/elands-marathan2023.component';
import {ElandsMarathon2023portComponent} from './components/media/elands-marathon2023port/elands-marathon2023port.component';
import {ElandsMarathon2024Component} from './components/media/elands-marathon2024/elands-marathon2024.component';
import {ReadmoreMarathonComponent} from './components/media/readmore-marathon/readmore-marathon.component';
import {ReadmoreMarathonportComponent} from './components/media/readmore-marathonport/readmore-marathonport.component';
import { GetATagComponent } from './components/get-a-tag/get-a-tag.component';
import { GalleryRally2024 } from './components/gallery/rally-2024/rally2024.component';

// port
const routes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent }, { path: 'homeport', component: HomeportComponent },
  { path: 'liveroute', component: LiverouteComponent }, { path: 'liverouteport', component: LiverouteportComponent },

  { path : 'about-company-profile', component: AboutCompanyProfileComponent },{ path : 'about-company-profileport', component: AboutCompanyProfileportComponent },
  { path : 'about-history', component: AboutHistoryComponent }, { path : 'about-historyport', component: AboutHistoryportComponent },
  { path : 'about-anti-corruption', component: AboutAntiCorruptionPolicyComponent },{ path : 'about-anti-corruptionport', component: AboutAntiCorruptionPolicyportComponent },

  //  Tenders
  { path: 'construction', component: ConstructionComponent }, { path: 'constructionport', component: ConstructionportComponent },
  { path: 'tenderresults', component: TenderresultsComponent},  { path: 'tenderresultsport', component: TenderresultsportComponent},
  { path: 'routine', component: RoutineComponent},  { path: 'routineport', component: RoutineportComponent},
  { path: 'smme-tenders', component: SMMETendersComponent},  { path: 'smme-tenders-port', component: SMMETendersPortComponent},

  // JOIN THE TEAM
  { path: 'jointheteam', component: JointheteamComponent},
  { path: 'jointheteamport', component: JointheteamportComponent},
  // plaza
  { path: 'plazawhytoll', component: WhytollComponent},
  { path: 'plazawhytollport', component: WhytollportComponent},
  { path: 'plazafees', component: TollplazafeesComponent},
  // { path: 'toll-plaza-and-fees', component: TollplazaAndfeesComponent},
  { path: 'plazafeesport', component: TollplazafeesportComponent},
  { path: 'plazaconcessionrequirements', component: ConcessionrequirementsComponent},  { path: 'plazaconcessionrequirementsport', component: ConcessionrequirementsportComponent},

  // load control
  { path: 'loadcontrol', component: LoadcontrolComponent},  { path: 'loadcontrolport', component: LoadcontrolportComponent},
  { path: 'loadcontrolcenter', component: LoadcontrolcenterComponent},  { path: 'loadcontrolcenterport', component: LoadcontrolcenterportComponent},

  // road safety
  { path: 'roadsafety', component: RoadsafetyComponent},  { path: 'roadsafetyport', component: RoadsafetyportComponent},
  // csi
  { path: 'education', component: EducationComponent},  { path: 'educationport', component: EducationportComponent},
  { path: 'environment', component: EnvironmentComponent},{ path: 'environmentport', component: EnvironmentportComponent},
  { path: 'health', component: HealthComponent},  { path: 'healthport', component: HealthportComponent},
  { path: 'maputo', component: MaputoComponent}, { path: 'maputoport', component: MaputoportComponent},
  { path: 'csiroadsafety', component: CsiroadsafetyComponent}, { path: 'csiroadsafetyport', component: CsiroadsafetyportComponent},
  { path: 'smme', component: SmmeComponent},  { path: 'smmeport', component: SmmeportComponent},
  { path: 'sprtsandculture', component: SportsandcultureComponent}, { path: 'sprtsandcultureport', component: SportsandcultureportComponent},
  // media
  { path: 'tracevents', component: TrackeventsComponent},
  { path: 'tracevents2024', component: TrackeventsComponent},
  { path: 'traceventsport', component: TrackeventsportComponent},
  { path: 'traceventsElandsMarathon', component: TrackeventsElandsMarathonComponent},  
  { path: 'traceventsElandsMarathonport', component: TrackeventsElandsMarathonportComponent},
  { path: 'pressreleases', component: PressreleasesComponent},  
  { path: 'pressreleasesport', component: PressreleasesportComponent},
  { path: 'readmore/:id/:idFrom', component: ReadmoreComponent}, 
  { path: 'readmoreport/:id/:idFrom', component: ReadmoreportComponent},
  { path: 'readmoretracevents/:id/:idFrom', component: ReadmoretrackeventComponent}, 
  { path: 'readmoretraceventsport/:id/:idFrom', component: ReadmoretrackeventportComponent},

  { path: 'traceventsElandsMarathon2023', component: ElandsMarathan2023Component},
  { path: 'traceventsElandsMarathon2023port', component: ElandsMarathon2023portComponent},

  { path: 'traceventsElandsMarathon2024', component: ElandsMarathon2024Component},

  { path: 'readmoreMarathon/:id/:idFrom', component: ReadmoreMarathonComponent},
  { path: 'readmoreMarathonport/:id/:idFrom', component: ReadmoreMarathonportComponent},

  //Gallery
  { path: 'gallery/rally-2024', component: GalleryRally2024},

  // contact
  { path: 'contact', component: ContactComponent},  
  { path: 'contactport', component: ContactportComponent},

  // get a tag
  { path: 'get-a-tag', component: GetATagComponent},

  // open document
  { path: 'opendocument', component: OpendocumentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    useHash: true,
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
