import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {AppsettingsService} from '../../../services/appsettings.service';

@Component({
  selector: 'app-construction',
  templateUrl: './construction.component.html',
  styleUrls: ['./construction.component.scss']
})
export class ConstructionComponent implements OnInit {

  configUrl: any;
  authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

  public apiContent: any = '';
  public requestFailed = false;

  constructor(
    private http: HttpClient,
    public appService: AppsettingsService
  ) {
    // this.configUrl = this.appService.apiBaseUrl + '/TracService/api/v1/document/getDocumentData/1';
  }


  ngOnInit(): void {

    this.appService.loadAppConfig().subscribe((response: any) => {
      //
      this.configUrl = response.apiBaseUrl + '/TracService/api/v1/document/getDocumentData/1';

      this.getRouteDocuments().subscribe(data => {
        // console.log(data.body);
        this.apiContent = data;
      }, (error => {
        // console.error('error caught in component');
        this.requestFailed = true;
      }));
    });
  }


  getRouteDocuments(): Observable<HttpResponse<any[]>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });

    return this.http.get<any[]>(this.configUrl, { observe: 'response', headers: reqHeader });
  }

  opendoc(val: any) {
    console.log(val);

    this.getDocs().subscribe(data => {


    });
  }

  getDocs(): Observable<HttpResponse<any[]>> {
    const urlval = '{{this.appSetting.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/5';
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/pdf',
      Authorization: 'Bearer ' + this.authToken
    });

    return this.http.get<any>(urlval, { observe: 'response', headers: reqHeader });
  }

}
