<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
        <div class="row py-2 justify-content-center align-items-center">
            <div class="col">
                <h1 class="row justify-content-center align-items-center bold">SMME Tender Pre-Qualifications
                    <span class="ml-4"><img src="./assets/images/tenders/Routine%20Maintance%20Specs%20Icon-01.png" style="height: 100px; width: auto" alt="Events"></span>
                </h1>
            </div>
        </div>
    </div>
</div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 text-center p-4">
        <img src="./assets/images/tenders/Trac-Logo-dark-1.webp?v=202403-20" class="img-fluid " width="40%" alt="tracn4 Logo">
      </div>
    </div>
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <h5 class="font-bold">EXPRESSION OF INTEREST</h5>
        <p class="font-bold">Routine Road Maintenance (RRM) expression of interest for various sections along the N4 Toll Route between Tshwane
          and Lebombo Border Post are available on the TRAC website from
          Monday 08th April 2024</p>
        <p>TRAC makes use of contractors to undertake the small routine works on the N4 Toll Route between Tshwane and Lebombo Border Post.</p>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="background-color: #f8f8f8;">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h6 class="font-bold" style="font-size: 16px;">Expression of Interest details</h6>
              <p>Application documents can be downloaded from the link below</p>
              <a class="apdfs text-center" style=" border: 1px solid #fff; width:100%; border-radius: 6px;"
                href="assets/pdfs/TRAC RRM Prequal Document Rev4 FM.pdf?v=20240405" target="_blank">
                <div class="h-25 " style="color:#fff; font-weight:600; min-height: 20px; ">Download TRAC RRM Prequal Document</div>
              </a>
              <p class="font-bold mt-2">All applications must be completed in hard copy, addressed and couriered or hand-delivered to:</p>
              <p class="font-bold">TRAC Nelspruit Regional Office<br>2C Mataffin Road<br>Mbombela<br>1201</p>
            </div>
            <div class="col-sm-12 col-md-6">
              <h6 class="font-bold" style="font-size: 16px;">Closing date</h6>
              <p>All submissions must reach the TRAC Nelspruit Regional Office no later than 12: 00 (noon) on or before Monday 22 April 2024.</p>
              <p class="font-bold">Late submissions will not be considered.</p>
              <h6 class="font-bold" style="font-size: 16px;">Enquires:</h6>
              <p class="">Executive Maintenance Manager<br>Derek Howe-Dreyer,</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid N4grey-gradient-background">
    <div class="row">
      <div class="col-sm-12 p-4">
        <h5 class="text-white text-center m-0">NO TELEPHONE CALLS PLEASE!</h5>
      </div>
    </div>
  </div> <!-- end of container-fluid -->
</main>