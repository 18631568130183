<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col offset-2"><h6 class="m-0 text-right "></h6>
                    <h1 class="text-right ">PLAZAS COM PORTAGEM<br/> & TARIFAS DE<br/> PORTAGEM</h1></div>
                <div class="col"><img src="./assets/images/plaza/Tolls_Plaza_banner.png"
                                      alt="About Us"></div>
            </div>
        </div>
    </div>
    <div class="container-fluid ">
        <div class="row ">
            <div class="col-sm-12 col-md-3">
                <div class="my-4">
                    <h5 class="text-center ">Express Lanes now open along the N4 Route</h5>
                    <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/jXbKETHsXmU"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                </div>
                <br/>
                <br/>
                <div class="actionlink" style="width:98%; ">
                    <div class="">
                        <img src="./assets/images/sanral/sanral-a3-poster.jpg"
                             class="img-fluid shadow" alt="Sanral">
                    </div>
                    <!--
                    <div class="cta-content hide bg-black" (click)="open(content5)">
                        <h3 class="text-center">Learn More</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                    -->
                </div>
            </div>
            <div class="col-sm-12  col-md-8">

    <div class="container ">
        <div class="row py-4">
            <div class="col-sm-12 col-md-6"><h5 class="text-center">PORTAGENS NA N4</h5>
                <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
                <p>Existem seis (6) plazas de portagem no trajecto principal e quatro (4) plazas nas rampas de saida da
                    N4 Rota
                    com
                    Portagem, a partir da rampa de saida Solomon Malhangu sita à zona Leste de Tshwane (Pretoria), em
                    direcção a
                    Maputo, Moçambique.</p>


                <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="static-1">
                    <ngb-panel id="static-{{o.id}}" *ngFor="let o of this.apiContent.body?.Data">
                        <ng-template class="grey-gradient-background " ngbPanelTitle class="bb">
                            <img src="./assets/images/plaza/Ui%20Icons_Location.png" class="img-fluid "
                                 style="height: 22px;"/>
                            <span class="pl-2 bold">{{o.name}}</span>
                        </ng-template>

                        <ng-template ngbPanelContent>
                            <div class="card-bodyPlaza">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Vehicle Class</th>
                                            <th scope="col">Cost</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th scope="row">Class 1</th>
                                            <td>{{o.currency}} {{o.cat1}}.00</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Class 2</th>
                                            <td>{{o.currency}} {{o.cat2}}.00</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Class 3</th>
                                            <td>{{o.currency}} {{o.cat3}}.00</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Class 4</th>
                                            <td>{{o.currency}} {{o.cat4}}.00</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <p class="small text-center
">Errors and omissions excepted (E &amp; OE) </p>
            </div>

            <div class="col-sm-12 col-md-6 text-center mb-3 ">
                <div class="row">
                    <!--
                    <div class="col-md-6">
                        <div class="actionlink" style="width:95%; ">
                            <div class="cta-img">
                                <img
                                        src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Trac%20Cares_Why%20Stop%20Thubnail.jpg"
                                        class="img-fluid shadow" alt="TRAC Cares">
                            </div>
                            <div class="cta-content hide bg-black" (click)="open(content1)">
                                <h3 class="text-center">Learn More</h3>
                                <div class="dot-divider mb-3">
                                    <span class="dot-divider-white"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="actionlink" style="width:98%; ">
                            <div class="cta-img">
                                <img src="./assets/images/sanral/sanral-a3-poster.jpg" class="img-fluid shadow"
                                     class="img-fluid shadow" alt="Sanral">
                            </div>
                            <div class="cta-content hide bg-black" (click)="open(content5)">
                                <h3 class="text-center">Learn More</h3>
                                <div class="dot-divider mb-3">
                                    <span class="dot-divider-white"></span>
                                </div>
                            </div>
                        </div>
                    </div>
-->
                    <div class="col-md-6 mt-1">
                        <div class="actionlink" style="width:95%; ">
                            <div class="cta-img">
                                <img src="./assets/images/express-lane-flyers/ExpressLaneFlyer2-min-get-a-tag.jpg"
                                     class="img-fluid shadow" alt="TRAC Cares">
                            </div>
                            <div class="cta-content hide bg-black" (click)="open(content2)">
                                <h3 class="text-center">Learn More</h3>
                                <div class="dot-divider mb-3">
                                    <span class="dot-divider-white"></span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 mt-1">
                        <div class="actionlink" style="width:95%; ">
                            <div class="cta-img">
                                <img src="./assets/images/express-lane-flyers/ExpressLaneFlyer1-min-why-stop.jpg"
                                     class="img-fluid shadow" alt="TRAC Cares">
                            </div>
                            <div class="cta-content hide bg-black" (click)="open(content3)">
                                <h3 class="text-center">Learn More</h3>
                                <div class="dot-divider mb-3">
                                    <span class="dot-divider-white"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-5">
                        <div class="actionlink">
                            <div class="cta-img">
                                <img src="./assets/images/sanral/Toll%20Tariffs-%20Final%20-%20Lowvelder_240x240.jpg"
                                     class="img-fluid shadow" alt="TRAC Cares">
                            </div>
                            <div class="cta-content hide bg-black" (click)="open(content4)">
                                <h3 class="text-center">Learn More</h3>
                                <div class="dot-divider mb-3">
                                    <span class="dot-divider-white"></span>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
            </div> <!-- col-md-6 -->


            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <!-- ------------------------------------------------------------------------------- -->
            <div class="col-sm-12  col-md-1">

            </div>
        </div>
    </div>



    <div class="container-fluid bg-yellow">
        <div class="row py-4">
            <div class="col-sm-12 col-md-12">
                <h5 class="text-center ">TARIFAS</h5>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-black">
        <div class="row py-3">
            <div class="col-sm-12 col-md-12"><h5 class="text-white text-center m-0">PORTAGENS NA AFRICA DO SUL</h5>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row py-3" *ngIf="this.apiContent !== ''">
            <ng-container *ngFor="let o of this.apiContent.body?.Data">
                <div class="col-sm-12 col-md-3" *ngIf="o.currency === 'R'">
                    <div class="table-responsive">
                        <table class="table tollplazatabl2es">
                            <thead>
                            <tr>
                                <th scope="col" colspan="2"
                                    class="text-center grey-gradient-background white-text">{{o.name}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td scope="row">Class 1</td>
                                <td class="text-right">{{o.currency}} {{o.cat1}}.00</td>
                            </tr>
                            <tr>
                                <td scope="row">Class 2</td>
                                <td class="text-right">{{o.currency}} {{o.cat2}}.00</td>
                            </tr>
                            <tr>
                                <td scope="row">Class 3</td>
                                <td class="text-right">{{o.currency}} {{o.cat3}}.00</td>
                            </tr>
                            <tr>
                                <td scope="row">Class 4</td>
                                <td class="text-right">{{o.currency}} {{o.cat4}}.00</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="container-fluid bg-black">
        <div class="row py-3">
            <div class="col-sm-12 col-md-12"><h5 class="text-white text-center m-0">PORTAGENS EM MOÇAMBIQUE</h5></div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row py-3" *ngIf="this.apiContent !== ''">
            <div class="col-sm-12 col-md-3" *ngFor="let o of this.apiContent.body?.Data">
                <div class="table-responsive" *ngIf="o.currency === 'MT'">
                    <table class="table tollplazatables">
                        <thead>
                        <tr>
                            <th scope="col" colspan="2"
                                class="text-center grey-gradient-background white-text">{{o.name}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td scope="row">Class 1</td>
                            <td class="text-right">{{o.currency}} {{o.cat1}}.00</td>
                        </tr>
                        <tr>
                            <td scope="row">Class 2</td>
                            <td class="text-right">{{o.currency}} {{o.cat2}}.00</td>
                        </tr>
                        <tr>
                            <td scope="row">Class 3</td>
                            <td class="text-right">{{o.currency}} {{o.cat3}}.00</td>
                        </tr>
                        <tr>
                            <td scope="row">Class 4</td>
                            <td class="text-right">{{o.currency}} {{o.cat4}}.00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-yellow">
        <div class="row py-4">
            <div class="col-sm-12 col-md-12"><h5 class="text-center ">MÉTODOS DE PAGAMENTO</h5>
                <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row py-4">
            <div class="col-sm-12 col-md-6"><h2 class="text-center">PORTAGENS NA AFRICA DO SUL</h2>
                <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
                <h4>Os métodos que se seguem são aceites para pagamento na secção de portagens na Africa do Sul.</h4>
                <div class="cont py-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 ">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/>
              </span>Numerário – Cash*</p>
                            </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Tag Electrónico
                                Automático</p></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Cartões Pertença de
                                Frota</p></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 ">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Cartões de Crédito**
                            </p></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Cartões de Débito de
                                Pré – Pagamento da TRAC ( emitidos só pela TRAC)*</p></h5>
                        </div>
                    </div>
                </div>
                <p style="text-decoration:italic;">* Excepto quando tarifas com desconto são aplicáveis</p>
                <p style="text-decoration:italic;">** APENAS para viaturas da classe 1 SÓ são aceites Master/Visa — NÃO
                    se
                    aceitam Amex/Diners.</p></div>
            <div class="col-sm-12 col-md-6"><h2 class="text-center">MOZAMBIQUE TOLL PLAZAS</h2>
                <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
                <h4>OS métodos de pagamento que se seguem são aceites tanto em Moamba Plaza com Portagem, como em Maputo
                    Plaza
                    com Portagem:</h4>
                <div class="cont py-3">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 ">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Numerário – Cash –
                                Meticais, ZAR e US Dollar*</p></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>E-tag de TRAC
                                Moçambique**</p></h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12">
                            <h5 class="my-1"><p class="m-0"><span><img
                                    src="./assets/images/plaza/Payment%20Method%20Ticks_Tick.png" class="img-fluid pr-3"
                                    style="height: 16px"/></span>Cartões de Débito de
                                Pré-Pagamento da TRAC (emitidos só pela TRAC)</p></h5>
                        </div>
                    </div>
                </div>
                <p style="text-decoration:italic;">* Excepto quando tarifas com desconto são aplicáveis. Quando o
                    pagamento em
                    numerário é efectuado na moeda ZAR ou US Dollar, o troco dai advindo será na moda Metica, ao câmbio
                    determinado pela TRAC, com ajuste decrescente ao Metical mais próximo. Nos pagamentos em divisas não
                    são
                    aceites moedas. Todos os pagamentos devem ser efectuados com notas</p>
                <p style="text-decoration:italic;">** Não é compatível com o sistema electrónico automático da Africa do
                    Sul</p>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-black">
        <div class="row py-4">
            <div class="col-sm-12 col-md-12"><h5 class="text-white text-center m-0 text-uppercase">PARA MAIS
                ASSISTÊNCIA, CLARIFICAÇÃO OU INFORMAÇÃO POR FAVOR LIGUE PARA HELPDESK DA TRAC:</h5></div>
        </div>
        <div class="row py-3">
            <div class="col-sm-12 col-md-3 offset-md-3 text-center text-white"><p><strong>RSA</strong></p>
                <p>Tel:&nbsp;<a href="tel:0800872264">0800 87 22 64</a>/<a href="tel:0828814444">082 881 4444</a></p>
                <p>Email:&nbsp;<a href="mailto:helpdesk@tracn4.co.za">helpdesk@tracn4.co.za</a></p></div>
            <div class="col-sm-12 col-md-3 text-center text-white"><p><strong>Mozambique</strong></p>
                <p>Tel:&nbsp;<a href="tel:0843434346">084 34 34 34 6</a></p>
                <p>Email:&nbsp;<a href="mailto:linhadocliente@tracn4.co.mz">linhadocliente@tracn4.co.mz</a></p></div>
        </div>
    </div>
    <div class="container-fluid bg-yellow">
        <div class="row py-4">
            <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase ">LINKS RELACIONADOS</h5>
                <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div id="relatedlinkstolls" class="row py-4">
            <div class="col-sm-12 col-md-6 text-center"><a class="actionlink" href=""
                                                           routerLink="/plazaconcessionrequirements">
                <div class="cta-img"><img
                        src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac.png"
                        style="height: 150px;"
                        alt="Toll Green">
                    <h5 class="mt-3 mb-0 black-text">REQUISITOS DE<br/>
                        CONCESSÃO</h5>
                </div>
                <div class="cta-content hide bg-black"><h3 class="text-center">REQUISITOS DE CONCESSÃO</h3>
                    <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
                    <h3 class="text-center">DOWNLOAD</h3></div>
            </a></div>
            <div class="col-sm-12 col-md-6 text-center"><a class="actionlink" href="" routerLink="/plazawhytoll">
                <div class="cta-img"><img src="./assets/images/plaza/Tolls_PlazaBannersIcons_WhyToll.png"
                                          style="height: 150px;"
                                          alt="Toll Yellow"><h5 class="mt-3 mb-0 black-text"></h5>
                    <h5 class="m-0 black-text">BENEFICIOS DE UMA ESTRADA<br/>COM PORTAGENS</h5></div>
                <div class="cta-content hide bg-black">
                    <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
                    <h3 class="text-center">Mais informação</h3></div>
            </a></div>
        </div>
    </div>

</main>

<ng-template #content1 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Trac Cares</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Trac%20Cares_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="">Why Queue?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/express-lane-flyers/ExpressLaneFlyer2-min-get-a-tag.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content3 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Why Stop?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/express-lane-flyers/ExpressLaneFlyer1-min-why-stop.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Toll Tariff Adjustment</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/sanral/Toll%20Tariffs-%20Final%20-%20Lowvelder_240x240.jpg" class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content5 let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Sanral</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/sanral/sanral-a3-poster.jpg" class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>

