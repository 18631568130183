import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

@Pipe({
  name: 'safe'
})

export class DomSanitizerService implements PipeTransform{

  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
