<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">CONTACT US</h1></div>
        <div class="col"><img src="./assets/images/contactus/ContactUSBannerIcon-01.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col px-0">
        <img src="./assets/images/contactus/trac-crime-banner-s.webp?v=20241108" width="100%" />
      </div>
    </div>
    <div class="row" style="background-color: #171717; height: 10px;"><div class="col"></div></div>
  </div>
  <div class="container">
    <div id="contactinfo" class="row py-4">
      <div class="col-sm-6"><h5 class="text-center">SOUTH AFRICA</h5>
        <div class="dot-divider mb-2"><span class="dot-divider-black"></span></div>
        <h3><strong>CUSTOMER CARE</strong></h3>



        <table class="table-responsive"  style="width:100%; line-height: 30px;">
          <tr>
            <td style="width:40px;line-height: 30px;">
              <strong>Email:</strong>
            </td>
            <td>
              <a href="mailto:helpdesk@tracn4.co.za">helpdesk@tracn4.co.za</a>
            </td>
          </tr>
          <tr>
            <td>
              <strong></strong>
            </td>
            <td>

            </td>
          </tr>
          <tr>
            <td>
              <strong>   <br></strong>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Call:</strong>
            </td>
            <td>
              <a href="tel:0800872264">0800 TRAC N4</a>&nbsp;(<a href="tel:0800872264">0800 872 264</a>) /
            </td>
          </tr>
          <tr>
            <td>
              <strong></strong>
            </td>
            <td>
              <a href="tel:0828814444">082 881 4444</a>
            </td>
          </tr>
        </table>

        <h3><strong>TOLL PLAZAS</strong></h3>
        <p><strong>Diamondhill Plaza:</strong>&nbsp;<a href="tel:0139357920">(+27) 13 935 7920</a></p>
        <p><strong>Middelburg Plaza:</strong>&nbsp;<a href="tel:0132436815">(+27) 13 243 6815</a></p>
        <p><strong>Machado Plaza:</strong>&nbsp;<a href="tel:0132569904">(+27) 13 256 9904</a></p>
        <p><strong>Nkomazi Plaza:</strong><a href="tel:0137260900">&nbsp;(+27) 13 726 0900</a></p>
        <h3><strong>HEAD OFFICE | IRENE</strong></h3>
        <p><strong>Call:</strong>&nbsp;<a href="tel:0118058788">+27 11 805 8788</a></p>
        <p><strong><br></strong></p>
        <p><strong>Physical Address:</strong></p>
        <p>Building A</p>
        <p>69 Regency Drive</p>
        <p>Route 21 Corporate Park</p>
        <p>Irene,&nbsp;Gauteng</p>
        <p>0157</p>
        <p><strong><br></strong></p>
        <p><strong>Postal Address:</strong></p>
        <p>P.O. Box 10817</p>
        <p>Vorna Valley</p>
        <p>Gauteng</p>
        <p>1686</p>
        <h3><strong>NELSPRUIT OFFICE</strong></h3>
        <p><strong>Call:&nbsp;</strong>+27 13 755 3316</p>
        <p><strong><br></strong></p>
        <p><strong>Physical Address:</strong></p>
        <p>2C Mataffin Road</p>
        <p>Mbombela</p>
        <p>1201</p>
        <p><strong><br></strong></p>
        <p><strong>Postal Address:</strong></p>
        <p>P.O. Box 4356</p>
        <p>Nelspruit</p>
        <p>1200</p></div>
      <div class="col-sm-6"><h5 class="text-center">MOZAMBIQUE</h5>
        <div class="dot-divider mb-2"><span class="dot-divider-black"></span></div>
        <h3><strong>CUSTOMER CARE</strong></h3>

        <table class="table-responsive"  style="width:100%; line-height: 30px;">
          <tr>
          <td style="width:40px;line-height: 30px;">
            <strong>Email:</strong>
          </td>
            <td>
              <a href="mailto:linhandocliente@tracn4.co.mz">linhandocliente@tracn4.co.mz</a>
            </td>
          </tr>
          <tr>
            <td>
              <strong></strong>
            </td>
            <td>
      &nbsp;
            </td>
          </tr>

          <tr>
            <td>
              <strong>Call:</strong>
            </td>
            <td>
              <a href="tel:+258843434346">+258 84 34 34 34 6</a>  /
            </td>
          </tr>
          <tr>
            <td>
              <strong></strong>
            </td>
            <td>
              <a href="tel:800 9022">800 9022</a>
            </td>
          </tr>
        </table>






        <h3><strong>TOLL PLAZAS</strong></h3>
        <p><strong>Maputo:</strong>&nbsp;(+258) 21 75 1631 / 38</p>
        <p><strong>Moamba:</strong>&nbsp;(+258) 21 52 0600 / 61 / 62 / 63</p>
        <h3><strong>MAPUTO</strong></h3>
        <p><strong>Call:</strong>&nbsp;+258 21 52 0600 / 61 / 62 / 63</p>
        <p><strong><br></strong></p>
        <p><strong>Physical Address:</strong></p>
        <p>N4 Matola</p>
        <p>Maputo</p>
        <p>Mozambique</p>
        <p><strong><br></strong></p>
        <p><strong>Postal Address:</strong></p>
        <p>P.O. Box 4356</p>
        <p>Nelspruit</p>
        <p>1200</p>
        <h3><strong>OR COMPLETE OUR ONLINE REQUEST FORM</strong></h3>
        <form [formGroup]="form" (ngSubmit)="submitForm(form.value)">
          <div class="alert-danger p-3 mb-2 rounded" *ngIf="setFormCompleteMessage">
            Please complete all fields.
          </div>
          <div class="alert-success p-3 mb-2 rounded" *ngIf="successSubmissionMessage">
            Message successfully sent.
          </div>

          <div class="form-group">
            <input formControlName="Name" type="text" class="form-control" name="name" placeholder="Full Name"
                   required/>
          </div>
          <div class="form-group">
            <input formControlName="Phone" type="text" name="Phone" class="form-control" placeholder="Phone"
                   required="required" required>
          </div>
          <div class="form-group">
            <input formControlName="Email" type="email" name="Email" class="form-control" placeholder="Email"
                   required="required" required>
          </div>
          <div class="form-group">
            <textarea formControlName="Message" name="Message" class="form-control" placeholder="Message" rows="6"
                      required="required" required></textarea>
          </div>
          <button class="g-recaptcha"
                  data-sitekey="6LfcZMkcAAAAAGFc9mmnf9AHZTjKnYvarQfxUUxl"
                  data-callback='onSubmit'
                  data-action='submit'  class="btn btn-primary mt-3">Submit</button>

        </form>
      </div>
    </div>
  </div>
</main>
