<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col offset-2"><h6 class="m-0 text-right ">TRAC Events</h6>
                    <h1 class="text-right bold ">TRAC N4 Elands Marathon</h1></div>
                <div class="col">
                    <img src="./assets/images/media/Trac%20Events%20Page%20Icon-01.png"
                         style="max-height: 200px; width: auto"
                         alt="Events"></div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row py-4">
            <div class="col">
                <div class="container"><h6 class="text-center">OFFICIAL LOGO AND FLYER</h6>
                    <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center shadow-sm rounded" style="text-align : center;">
            <div class="col-sm-6 col-md-6      justify-content-center h-100">
                <img src="./assets/images/elandsMarathon/2022-TRACN4-Elands-Marathon-Logo-smaller.jpg"
                     style="    "
                     class="img-fluid text-center  mx-auto d-block p-2 center-block image-center h-100 elands-marathon-logo"
                     alt=""/>
            </div>
            <div class="col-sm-6 col-md-6     ">
                <img src="./assets/images/elandsMarathon/digitalEntry.jpg" alt=""
                     class="img-fluid text-center shadow-lg mx-auto d-block m-4 " alt=""/>
            </div>
        </div>
    </div>


    <div class="container border shadow ">
        <!--
        <div class="row py-4">
            <div class="col">
                <div class="container"><h6 class="text-center">ENTER NOW</h6>
                    <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
                </div>
            </div>
        </div>
        -->
        <div class="row">
            <div class="col-sm-12 col-md-12  text-center h4 shadow bg-yellow black-text p-2 " style="margin-bottom: 0;">

                <a href="https://entries.raceinfo.co.za/events/77553-tracn4-elands" target="_blank"
                   class="text-uppercase">
                    Click here to Enter
                </a>
            </div>
        </div>
    </div>

    <br/><br/>

    <div class="container-fluid">
        <div class="row py-4">
            <div class="col">
                <div class="container"><h6 class="text-center">RACE PROFILE</h6>
                    <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 mb-3 mt-2 shadow mx-auto border ">
                <img src="./assets/images/elandsMarathon/Race-Profile.jpg"
                     class="img-fluid text-center mx-auto d-block"
                     alt=""/>
            </div>

        </div>
    </div>
    <!-- --- -->
    <div class="container-fluid">
        <div class="row py-4">
            <div class="col">
                <div class="container"><h6 class="text-center">SPONSORS</h6>
                    <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4 mb-3 mt-2">
                <img src="./assets/images/media/TracN4.jpg" alt="" class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-12 col-md-4 mb-3 mt-2  mx-auto">
                <img src="./assets/images/elandsMarathon/Buscor-logo.JPG" alt="MRF"
                     class="img-fluid text-center mx-auto d-block p-5">
            </div>

            <div class="col-sm-12 col-md-4 text-center">
                <img src="./assets/images/elandsMarathon/RISE-FM.png" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-12 col-md-6 mb-3 mt-2 p-5  mx-auto ">
                <img src="./assets/images/elandsMarathon/Comrades-Logo.jpg" alt=""
                     class="img-fluid text-center mx-auto d-block p-5">
            </div>
            <div class="col-sm-12 col-md-6 text-center">
                <img src="./assets/images/elandsMarathon/nedbank-logo-without-AMPU.jpg" alt=""
                     class="img-fluid text-center mx-auto d-block mt-3 p-1">
            </div>

        </div>
    </div>


    <div class="header  bg-yellow py-4">
        <h6 class="text-center text-black bold">PRESS RELEASES</h6></div>

    <div class="container">
        <div class="row py-4" *ngIf="this.apiContent !== ''">
            <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
                <div class="col-sm-12 col-md-12 py-4" *ngIf="i >= greaterthan && i <= smallerThan">
                    <div class="post-title"><span class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
                        <h2 class="posttitle mb-0">{{o.Name}}</h2></div>
                    <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
                        <a class="h6" routerLink="/readmore/{{o.ID}}/{{i}}">Read More</a></div>
                </div>
            </ng-container>
            <ng-container *ngIf="totalNumberOfItems >= 6">
                <mat-paginator [length]="totalNumberOfItems" class=""
                               [pageSize]="paginatorPagesize"
                               [pageSizeOptions]="[5, 10, 25]"
                               aria-label="Select page"
                               (page)="getNext($event)">
                </mat-paginator>
            </ng-container>
            <ng-container *ngIf="totalNumberOfItems === 0">
                <div class="col-sm-12 col-md-12 py-4">
                    <div class="text-center h4 ">No current press releases.
                    </div>
                </div>
            </ng-container>
            <!--
            <div class="post-links">
              <ul class="pagination pagination-sm">
                <li class="page-item disabled"><span class="page-link">«</span></li>
                <li class="page-item active"><span class="page-link">1</span></li>
                <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=2">2</a></li>
                <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=3">3</a></li>
                <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=4">4</a></li>
                <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=5">5</a></li>
                <li class="page-item"><a class="page-link" href="https://tracn4.co.za/press-releases?page=2" rel="next">»</a>
                </li>
              </ul>
            </div>
            -->

        </div>
    </div>


</main>

