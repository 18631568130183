<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col">
                    <h1 class="row justify-content-center align-items-center bold">TRAC N4 ELANDS MARATHON 2024
                        <span class="ml-4"><img src="./assets/images/media/Trac%20Events%20Page%20Icon-01.png" style="height: 100px; width: auto" alt="Events"></span>
                    </h1>
                </div>
            </div>
        </div>
    </div>
    <div class="jumbotron jumbotron-fluid m-0 image-cover" style="background-image: url('./assets/images/elandsMarathon/2024/marathon-2024-banner.webp'); background-position: center; background-size: cover;">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <img src="./assets/images/elandsMarathon/2024/trac-marathon-badge.webp" style="height: 200px; width: auto" alt="Trac Rally 2023">
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row py-3">
            <div class="col-sm-12">
                <div class="container  ">
                    <div class="col-sm-12 col-lg-12 white-text mt-3 my-3 p-0">
                        <div class="row" style="align-items: center;">
                            <div class="col-sm-6">
                                <img src="./assets/images/elandsMarathon/2024/trac-marathon-artwork.webp" alt="2023 TRACN4 National Rally Programme" class="img-fluid"/>
                            </div>
                            <div class="col-sm-6">
                                <div class="mt-3 mb-3   black-text  ">
                                    <div class="removeMarginOnSmallScreensSpectatorGuide text-left">
                                        <h3 class="text-uppercase">DARE TO BE GREAT</h3>
                                        <p>Welcome to the TRACN4 Elands Marathon, an exhilarating event set to take
                                            place on 2 March, 2024! Immerse yourself in the spirit of endurance and
                                            camaraderie as you tackle the scenic routes of our three distinctive races:
                                            42.2Km; 21.1Km, 10Km</p>
                                        <p>Not only will you be pushing your limits and accomplishing personal milestones,
                                            but participants also stand to gain Vitality points, enhancing the overall well-being
                                            experience. Join us for a day of fitness, achievement, and the pursuit of vitality at
                                            the TRACN4 Elands Marathon 2024!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center mb-5">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h2 class="text-uppercase">2 MARCH 2024</h2>
                    <h3 class="text-uppercase">TRACN4 ELANDS MARATHON 2024 DETAILS</h3>
                </div>
            </div>
        </div>
        <div class="row text-center mb-5">
            <div class="col-sm-12 col-md-4 mb-3">
                <div class="yellow-border marathon-boxshadow p-4" style="border-radius: 15px;">
                    <img src="./assets/images/elandsMarathon/2024/running-icon.webp" alt="2024 42KM" class="img-fluid"/>
                    <h2 class="text-uppercase my-3">42.2KM</h2>
                    <p class="mb-0 text-center"><strong>Entry Fee:</strong> R400</p>
                    <p class="mb-0 text-center"><strong>Time:</strong> 06:00</p>
                    <p class="mb-0 text-center"><strong>Location:</strong> Laerskool Oosterlijn (eMgwenya/Waterval Boven)</p>
                    <a href="https://www.plotaroute.com/route/2412419?units=km" target="_blank" class="mt-3 btn black-text bg-yellow text-center" style="border-radius: 10px; padding: 5px 20px;">VIEW ROUTE</a>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-3">
                <div class="yellow-border marathon-boxshadow p-4" style="border-radius: 15px;height: 100%;">
                    <img src="./assets/images/elandsMarathon/2024/running-icon.webp" alt="2024 42KM" class="img-fluid"/>
                    <h2 class="text-uppercase my-3">21.1KM</h2>
                    <p class="mb-0 text-center"><strong>Entry Fee:</strong> R200</p>
                    <p class="mb-0 text-center"><strong>Time:</strong> 06:00</p>
                    <p class="mb-0 text-center"><strong>Location:</strong> Malanga Hotel</p>
                    <a href="https://www.plotaroute.com/route/2412404?units=km" target="_blank" class="mt-3 btn black-text bg-yellow text-center" style="border-radius: 10px; padding: 5px 20px;">VIEW ROUTE</a>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-3">
                <div class="yellow-border marathon-boxshadow p-4" style="border-radius: 15px;height: 100%;">
                    <img src="./assets/images/elandsMarathon/2024/running-icon.webp" alt="2024 42KM" class="img-fluid"/>
                    <h2 class="text-uppercase my-3">10KM</h2>
                    <p class="mb-0 text-center"><strong>Entry Fee:</strong> R100</p>
                    <p class="mb-0 text-center"><strong>Time:</strong> 06:00</p>
                    <p class="mb-0 text-center"><strong>Location:</strong> Sappi Skills Traning Centre</p>
                    <a href="https://www.plotaroute.com/route/2412737?units=km" target="_blank" class="mt-3 btn black-text bg-yellow text-center" style="border-radius: 10px; padding: 5px 20px;">VIEW ROUTE</a>
                </div>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-sm-12">
                <p class="mb-0 text-center font-weight-bold" style="font-size: 18px;">Ticket to the Start is included in Race Fees<br></p>
                <p class="mb-0 text-center font-weight-bold" style="font-size: 18px;">All races finish at Ngodwana, Mpumalanga<br></p>
                <p class="mb-0 text-center font-weight-bold" style="font-size: 18px;">Temporary Licences R40 | Race Shirts R200 | Substitutions R100<br></p>
                <p class="mb-0 text-center font-weight-bold" style="font-size: 18px;">Earn Vitality Points<br></p>
            </div>
        </div>
        <div class="row text-center mb-5">
            <div class="col-sm-4">
                <div class="marathon-box marathon-boxshadow">
                    PRE-ENTRIES ONLY
                </div>
            </div>
            <div class="col-sm-4">
                <div class="marathon-box marathon-boxshadow">
                    ENTRIES CLOSE 18 FEB 2024
                </div>
            </div>
            <div class="col-sm-4">
                <div class="marathon-box marathon-boxshadow">
                    SUBSTITUTIONS 19-23 FEB 2024
                </div>
            </div>
        </div>
        <div class="row text-center mb-5">
            <div class="col-sm-12">
                <a href="https://www.elandsmarathon.co.za/" target="_blank" class="btn black-text bg-yellow text-center marathon-boxshadow" style="border-radius: 10px; padding: 12px 30px; width: 45%;">MORE INFORMATION</a>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-1">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">RACE PROFILE</h3>
                </div>
            </div>
        </div>
        <div class="row text-center yellow-border">
            <div class="col-sm-12 p-0">
                <img src="./assets/images/elandsMarathon/2024/race-profile.webp" alt="2024 race profile" class="img-fluid"/>
            </div>
        </div>
        <div class="row yellow-border mt-5 text-center">
            <div class="col-sm-12 pt-3">
                <h3 class="text-uppercase">QUALIFIER</h3>
                <div class="row">
                    <div class="col-sm-6">
                        <img src="./assets/images/elandsMarathon/2024/comerades.webp" alt="2024 race profile" class="img-fluid mx-5"/>
                    </div>
                    <div class="col-sm-6">
                        <img src="./assets/images/elandsMarathon/2024/two-oceans.webp" alt="2024 race profile" class="img-fluid mx-5"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-1">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">T-SHIRT ORDERS CLOSE 4 FEB 2024!</h3>
                    <p class="text-center">Price: R200.00</p>
                </div>
                <img src="./assets/images/elandsMarathon/2024/shirt.webp" alt="2023 TRACN4 National Rally Programme" class="img-fluid"/>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-1">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">TRACN4 ELANDS MARATHON 2023 GALLERY</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 mt-3 text-center" style="background: #f5f5f5; display: flex; flex-direction: column;">
                <span>{{imageCounter}} / 8</span>
                <img [src]="imageListVar" style="height:100%;" class="img-fluid d-block mx-auto rounded" alt="Trac Rally 2023"/>
                <button
                        class="slider-btn slider-btn-left"
                        (click)="previousImage()">&larr;
                </button>
                <button class="slider-btn slider-btn-right" (click)="nextImage()">&rarr;
                </button>
            </div>
        </div>
        <br/>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-3">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">TRACN4 ELANDS MARATHON 2023 VIDEO HIGHLIGHTS</h3>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 col-md-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/bA9zzkQq06k?si=eBCU2HVMcejtLn9Z"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
            <div class="col-sm-12 col-md-6">
                <iframe width="100%" height="320" src="https://www.youtube.com/embed/AuhRtI9VNE8?si=hrlRCf8Sc2-z_dsh"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center mt-5 mb-1">
                <div class="removeMarginOnSmallScreensSpectatorGuide">
                    <h3 class="text-uppercase">2023 WINNERS AND TOP PERFORMERS</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 mt-3 text-center" style="background: #f5f5f5; display: flex; flex-direction: column;">
                <span>{{imageCounter2}} / 6</span>
                <img [src]="imageListVar2" style="height:100%;" class="img-fluid d-block mx-auto rounded" alt="Trac Marathon 2023"/>
                <button
                        class="slider-btn slider-btn-left"
                        (click)="previousImage2()">&larr;
                </button>
                <button class="slider-btn slider-btn-right" (click)="nextImage2()">&rarr;
                </button>
            </div>
        </div>
        <br/>
    </div>
    <div class="container-fluid">
        <div class="row py-4">
            <div class="col">
                <h3 class="text-center">SPONSORS</h3>
            </div>
        </div>
        <div class="row" style="margin: 0 15%;">
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/rise-fm.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/trac.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/lvcc.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/tifosi.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/millys.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/astron.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/buscor.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-4 col-md-3 mb-3 mt-2">
                <img src="./assets/images/elandsMarathon/2024/nedbank.webp" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
        </div>
    </div>

    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- <div class="container-fluid">
        <div class="row  py-3  shadow-lg grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        SPONSORS
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4 mb-3 mt-2">
                <img src="./assets/images/media/TracN4.jpg" alt="" class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-12 col-md-4 mb-3 mt-2  mx-auto">
                <img src="./assets/images/elandsMarathon/Buscor-logo.JPG" alt="MRF"
                     class="img-fluid text-center mx-auto d-block p-5">
            </div>

            <div class="col-sm-12 col-md-4 text-center">
                <img src="./assets/images/elandsMarathon/RISE-FM.png" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-sm-12 col-md-3 ">
                <img src="./assets/images/elandsMarathon/Millys%20Logo.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-5 p-1">
            </div>
            <div class="col-sm-12 col-md-3  ">
                <img src="./assets/images/elandsMarathon/Comrades-Logo.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block p-1 ">
            </div>
            <div class="col-sm-12 col-md-3 text-center">
                <img src="./assets/images/elandsMarathon/nedbank-logo-without-AMPU.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-3 ">
            </div>
            <div class="col-sm-12 col-md-3 text-center">
                <img src="./assets/images/elandsMarathon/CALTEX_3D_SILVER_LOGO_CMYK.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-5 p-1">
            </div>

        </div>
    </div> -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <div id="marathon" class="container-fluid">
        <div class="row py-4 px-0" *ngIf="this.apiContent !== ''">
            <div class="col-sm-12 col-md-12 p-0">
                <mat-tab-group dynamicHeight>
                    <mat-tab label="2024 Press Releases">
                        <div class="mat-elevation-z4">
                            <!-- --------------- 2024 ------------------------------------------ -->
                            <div class="row mx-2">
                                <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
                                    <ng-container *ngIf="o.Date > '2024-01-01'" ?>
                                        <div class="col-sm-12 col-md-6 p-4" *ngIf="i >= greaterthan && i <= smallerThan">
                                            <div class="post-title"><span
                                                    class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
                                                <h2 class="posttitle mb-0">{{o.Name}}</h2>
                                            </div>
                                            <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
                                                <a class="h6" routerLink="/readmoreMarathon/{{o.ID}}/{{i}}">Read More</a>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="totalNumberOfItems === 0">
                                    <div class="col-sm-12 col-md-12 py-4">
                                        <div class="text-center h4 ">No current press releases.
                                        </div>
                                    </div>
                                </ng-container>
                                
                            </div>
                            <div class="column">
                                <ng-container *ngIf="totalNumberOfItems >= 6">
                                    <mat-paginator [length]="totalNumberOfItems" class=""
                                                [pageSize]="paginatorPagesize"
                                                [pageSizeOptions]="[5, 10, 25]"
                                                aria-label="Select page"
                                                (page)="getNext($event)">
                                    </mat-paginator>
                                </ng-container>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="2023 Press Releases">
                        <div class="mat-elevation-z4" style="min-height: auto; width: 100%;">
                            <div class="mat-elevation-z4">
                                <!-- --------------- 2023 ------------------------------------------ -->
                                <div class="row mx-2">
                                <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
                                    <ng-container *ngIf="o.Date < '2024-01-01'" ?>
                                            <div class="col-sm-12 col-md-6 p-4"
                                                *ngIf="i >= greaterthan && i <= smallerThan">
                                                <div class="post-title"><span
                                                        class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
                                                    <h2 class="posttitle mb-0">{{o.Name}}</h2></div>
                                                <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
                                                    <a class="h6" routerLink="/readmoreMarathon/{{o.ID}}/{{i}}">Read
                                                        More</a></div>
                                            </div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="totalNumberOfItems === 0">
                                    <div class="col-sm-12 col-md-12 py-4">
                                        <div class="text-center h4 ">No current press releases.
                                        </div>
                                    </div>
                                </ng-container>
                                </div>
                                <div class="column">
                                    <ng-container *ngIf="totalNumberOfItems >= 6">
                                        <mat-paginator [length]="totalNumberOfItems" class=""
                                                       [pageSize]="paginatorPagesize"
                                                       [pageSizeOptions]="[5, 10, 25]"
                                                       aria-label="Select page"
                                                       (page)="getNext($event)">
                                        </mat-paginator>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</main>


<ng-template #content4 let-modal>
    <div class="modal-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="modal-title" id="modal-basic-title4">Rally 2022 Gallery - Displaying
                        Photo {{imageCounter}} of 167</h4>

                </div>
                <div class="col-sm-6">
                    <div>
                        <button
                                class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="previousImageRes()" style="width:50%;border-right: 2px solid #fff;">Previous
                        </button>
                        <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="nextImageHighRes()" style="width:50%;">Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <img [src]="imageListVarHighResolution"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>
