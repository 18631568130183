<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">JOIN THE TEAM</h1></div>
        <div class="col"><img src="./assets/images/about/About%20Us%20Icon-01.png"
                              alt="About Us" style="max-height: 200px; width: auto"></div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row py-4"  *ngIf="apiContent !== '' " >
      <div class="col-sm-12 col-md-12" *ngIf="apiContent.body.Data.length !== 0">

        <h5 class="text-center font-bold text-uppercase">Vagas Disponíveis</h5>
        <div class="dot-divider mb-3">
          <span class="dot-divider-black"></span>
        </div>
        <h6 class="text-center font-bold">Faça o download.</h6>
      </div>
    </div>

    <div class="row py-4">
      <div class="col-sm-12 col-md-12" >
        <div class="row py-4" *ngIf="apiContent !== '' " >
          <ng-container *ngFor="let o of this.apiContent.body.Data; index as i">
            <div class="col-sm-12 col-md-6 text-center " style="border:0px solid #eee; min-height:vh20;  ">
              <div class="row ">
                <div class="col-12 h-100 ">
                  <!--
          Category: "Construction Projects"
          Date: "2021-07-08T00:00:00"
          Description: "This is the Construction Project Tender 1"
          FileName: "ConstructionProjectTender1.pdf"
          ID: 1
          Name: "Construction Project Tender 1"
                  -->
                  <div class="text-center p-3 m-2 bg-black white-text h3 rounded ">{{o.Category}}</div>
                  <div class="text-left p-1 m-2 h6 rounded border-bottom ">Date: {{o.Date | date:'dd MMMM Y'}}</div>
                  <h5 class="text-left p-1 m-2 border-bottom">{{o.Description}}</h5>
                  <h5 class="text-left p-1 m-2 border-bottom">PDF : {{o.FileName}}</h5>
                  <br/><br/>
                  <a class="apdfs text-center " style=" border: 1px solid #fff; width:100%; border-radius: 3px;"
                     href="{{appService.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/{{o.ID}}" title="Click and View PDF Document"
                     target="_blank">Download Document</a>

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>




  <div class="container" *ngIf="this.apiContent != ''">
    <div class="row py-6" *ngIf="this.apiContent.body.Data.length == 0">
      <div class="col-xs-12 col-md-12">
        <div class="row py-4 empty-content">
          <div class="col-xs-12 col-md-12 d-flex align-items-center justify-content-center">
            <h2 class="text-center">
              <div id="block--9" class="block block--wysiwyg_block m-5">
                <div class="block__content h6 m-5">COM MUITA PENA NOSSA NÃO TEMOS POSTOS DE TRABALHO DISPONÍVEIS.
                </div>
              </div>
            </h2>
          </div>
        </div>
      </div>
    </div> <!-- end of row -->

  </div>
</main>
