<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold">CSI</h6>
          <h1 class="text-right ">EDUCATION &amp; SKILLS DEVELOPMENT</h1></div>
        <div class="col"><img src="./assets/images/csiEducation/EDUCATION%20&%20SKILLS%20DEVELOPMEN%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">

      <div class="col-12 text-center bold"><p>Education not only changes lives, it has the potential to change the
        world. From presenting an
        innovative, leading-edge distance-learning programme, to offering bursaries, to supporting a national literacy
        programme – TRAC is able to create opportunities for many and assist the youth of today have a more promising
        tomorrow.</p>
      </div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/Little-Angel-2003.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h4 class="text-center text-black">THE FOLLOWING PROJECTS FALL UNDER TRAC’S CSI
        EDUCATION PORTFOLIO:</h4>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>

    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_KAAPMUIDEN%20PRIMARY%20SCHOOL.png" alt=""  class="img-fluid imgsize"/>
        <h6>PHUMELELA
          SECONDARY SCHOOL</h6>
        </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_PENREACH.png" alt="" class="img-fluid imgsize"/>
        <h6>PENREACH</h6>
       </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_TRAC%20BURSARIES.png" alt=""  class="img-fluid imgsize"/>
        <h6>TRAC BURSARIES</h6>
        </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_LITTLE%20ANGELS.png" alt="" class="img-fluid imgsize"/>
        <h6>LITTLE ANGELS</h6>
        </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_E-LEARNING.png" alt="" class="img-fluid imgsize"/>
        <h6>E-LEARNING</h6>
        </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiEducation/Education%20Icons_KAAPMUIDEN%20PRIMARY%20SCHOOL.png" alt=""  class="img-fluid imgsize"/>
        <h6>KAAPMUIDEN&nbsp;PRIMARY SCHOOL</h6>
       </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
      style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
              <div class="cta-img"><img
                src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                <h3 class="mt-3 mb-0 ">ENVIRONMENT</h3>
              </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/health">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">HEALTH & WELFARE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputo">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MAPUTO CORRIDOR</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafety">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ROAD SAFETY</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smme">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SMME DEVELOPMENT & SUPPORT</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandculture">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SPORTS & CULTURE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
