import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppsettingsService} from '../../../services/appsettings.service';
import {Observable} from 'rxjs/Observable';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-elands-marathon2024',
  templateUrl: './elands-marathon2024.component.html',
  styleUrls: ['./elands-marathon2024.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElandsMarathon2024Component {
  configUrl: any;
  authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

  public apiContent: any = '';
  public closeResult = '';
  totalNumberOfItems = 0;
  paginatorPagesize: any = 5;
  greaterthan = 0;
  smallerThan = 4;
  imageCounter = 1;
  imageCounter2 = 1;
  imageList = [{
      src: '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 01.webp'
  }];
  imageList2 = [{
      src: '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 01.webp'
  }];
  // public imageListVar = '/assets/Dullstroom-rally-2022/smaller/TRAC-DULLSTROOM-1.jpg';
  public imageListVar = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 01.webp';
  public imageListVar2 = '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 01.webp';
  public imageListVarHighResolution = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 01.webp';

  constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      public appService: AppsettingsService
  ) { }

  ngOnInit(): void {
    this.appService.loadAppConfig().subscribe((response: any) => {
      this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/3/1';
      this.getPressRelease().subscribe((data:any) => {
        console.log(data);
        this.apiContent = data;
        this.totalNumberOfItems = this.apiContent.body.Data.length;

        //  console.log(this.totalNumberOfItems);
      });
    });
  }

  getPressRelease(): Observable<HttpResponse<any[]>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });

    return this.http.get<any[]>(this.configUrl, {observe: 'response', headers: reqHeader});

  }

  public open(content: any): void {

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getNext($event: PageEvent) {
    // console.log($event);

    this.smallerThan = ($event.pageIndex + 1) * $event.pageSize - 1;
    this.greaterthan = ($event.pageIndex + 1) * $event.pageSize - $event.pageSize;

    this.getPressRelease().subscribe(data => {

      this.apiContent = data;
      this.totalNumberOfItems = this.apiContent.body.Data.length - 1;
      // console.log(this.totalNumberOfItems);
    });
  }

  public nextImage(): void {

    if (this.imageCounter < 8) {
        this.imageCounter++;
        if(this.imageCounter <= 8){
            this.imageListVar = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 0' + this.imageCounter + '.webp';
        }
    } else {
        this.imageCounter = 1;
        this.imageListVar = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 0' + this.imageCounter + '.webp';
    }
  }

  public previousImage(): void {

      if (this.imageCounter >= 2) {
          this.imageCounter--;
          if(this.imageCounter <= 8){
              this.imageListVar = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 0' + this.imageCounter + '.webp';
          }
          // if(this.imageCounter > 9 && this.imageCounter <= 99){
          //     this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - 0' + this.imageCounter + '.webp';
          // }
          // if(this.imageCounter > 99 && this.imageCounter <= 999){
          //     this.imageListVar = '/assets/rally-2023/rally/Trac Rally 2023 - ' + this.imageCounter + '.webp';
          // }
      } else {
          this.imageCounter = 8;
          this.imageListVar = '/assets/images/elandsMarathon/2024/gallery/race/Elands2023 - 0' + this.imageCounter + '.webp';
      }
  }

  public nextImage2(): void {
      if (this.imageCounter2 < 6) {
          this.imageCounter2++;
          if(this.imageCounter2 <= 9){
              this.imageListVar2 = '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 0' + this.imageCounter2 + '.webp';
          }
          // if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
          //     this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
          // }
          // if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
          //     this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
          // }
      } else {
          this.imageCounter2 = 1;
          this.imageListVar2 = '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 0' + this.imageCounter2 + '.webp';
      }
  }

  public previousImage2(): void {

      if (this.imageCounter2 >= 2) {
          this.imageCounter2--;
          if(this.imageCounter2 <= 9){
              this.imageListVar2 = '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 0' + this.imageCounter2 + '.webp';
          }
          // if(this.imageCounter2 > 9 && this.imageCounter2 <= 99){
          //     this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving0' + this.imageCounter2 + '.webp';
          // }
          // if(this.imageCounter2 > 99 && this.imageCounter2 <= 999){
          //     this.imageListVar2 = '/assets/rally-2023/winners/Trac Rally Prize Giving' + this.imageCounter2 + '.webp';
          // }
      } else {
          this.imageCounter2 = 6;
          this.imageListVar2 = '/assets/images/elandsMarathon/2024/gallery/prize/2023 Winners and Top Performers - 0' + this.imageCounter2 + '.webp';
      }
  }

  public highResolution(): void {
    this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';

  }

  public nextImageHighRes(): void {
    if (this.imageCounter <= 16) {
      this.imageCounter++;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 1;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    }
  }

  public previousImageRes(): void {
    if (this.imageCounter >= 2) {
      this.imageCounter--;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 16;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    }
  }
}
