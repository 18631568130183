<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow"  >
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col offset-2 ">
          <h6 class="m-0 text-right ">ABOUT US</h6>
          <h1 class="text-right ">ANTI-CORRUPTION<br/> POLICY</h1>
        </div>
        <div class="col ">
          <img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row py-4 ">
      <div class="col">
        <div class="container">
          <div id="block--7" class="block block--wysiwyg_block bold ">
            <div class="block__content"><p>TRAC’s internal controls and operating procedures are intended to detect,
              prevent and/or deter improper and corrupt activities. However, because TRAC acknowledges that not even the
              best systems of control can provide absolute safeguards against irregularities, we rely on the goodwill
              and moral upstanding of individuals to assist us keep the company corruption free.</p>
              <p>Employees, stakeholders, subcontractors and members of the public are therefore urged to report all
                allegations regarding improper and/or corrupt conduct that can have a direct effect on the company’s
                financial position and image through the correct channels. All allegations will be investigated either
                internally or with/by the relevant law enforcement authorities.</p>
              <p>&nbsp;Allegations of corrupt and/or inappropriate actions should be reported to the TRAC Fraud Hotline
                on +27 (0) 82 900 2447. Calls are not answered and complainants must therefore leave a detailed message
                which will be heard by a designated TRAC official. Callers must provide as much correct, factual and
                specific information as possible. Whistle blowers can remain anonymous although it is important to
                remember that by providing one’s personal contact information may speed up the investigation.</p>
              <p>&nbsp;TRAC management retains the prerogative to determine when circumstances warrant an investigation
                in conformity with the company’s anti-corruption and human resource policies and applicable laws and
                regulations.</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-black py-4 text-white">
      <div class="col">
        <div class="container"><h2 class="text-center text-white">REWARD</h2>
          <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
          <p>TRAC’s management may decide to reward the person who first reported an improper and/or corrupt activity up
            to R10 000. This sum will be determined at the discretion of TRAC management and no communication or dispute
            will be allowed in this regard. In order to qualify for the incentive, the caller must be identifiable and
            provide comprehensive information. TRAC strictly prohibits retaliation, harassment, or discrimination
            against any person who made a report in good faith and with a reasonably held belief.</p></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row py-4">
      <div class="col"><img src="./assets/images/anticorruption/Whistle-Blower-Creative-01.jpg" class="img-fluid"
                            alt="Whistle Blower 1 1 Resized">
        <h1 class="text-center"><span style="color: #000000;"><a class="clickphone" style="color: #000000;"
                                                                 href="tel:0829002447">+27 82 900 2447</a></span></h1>
      </div>
    </div>
  </div>

</main>
