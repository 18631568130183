import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import {AppsettingsService} from '../../services/appsettings.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  form: UntypedFormGroup;
  name: any;
  setFormCompleteMessage = false;
  successSubmissionMessage = false;
  submitted = false;
  public siteKey: '6LfcZMkcAAAAAGFc9mmnf9AHZTjKnYvarQfxUUxl' | undefined;
  constructor(
    private builder: UntypedFormBuilder,
    private contact: ContactService,
    public appService: AppsettingsService
  ) {
    this.form = this.builder.group({
      Name: ['', Validators.required],
      Phone: [''],
      Email: ['', Validators.required],
      Message: ['', Validators.required]
    });
/*
    this.form = this.builder.group({
      Name: ['', Validators.required],
      Phone: [''],
      Email: ['', [Validators.required, this.emailValidator]],
      Message: ['', Validators.required],
    });

 */
  }

  ngOnInit(): void {

  }

  public emailValidator(control: { value: string; }) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return {invalidEmailAddress: true};
    }
  }


  submitForm(form: UntypedFormGroup): void {
    // console.log(this.form.value);
    // console.log(form);
    // console.log(this.form.dirty);
    // console.log(this.form);

    if (this.form.invalid) {
      this.setFormCompleteMessage = true;
    } else {
      this.setFormCompleteMessage = false;

      this.appService.loadAppConfig().subscribe((response: any) => {


        this.contact.postdata(form, response).subscribe(data => {
          if (data.Success) {
            this.successSubmissionMessage = true;
          }
        });
      });


    }
  }

}
