<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">MEIO AMBIENTE</h1></div>
        <div class="col"><img src="./assets/images/csiEducation/EDUCATION%20&%20SKILLS%20DEVELOPMEN%20Banner_1.png"
                              alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col"><p>O nosso meio ambiente anda de mãos dadas com o modo da nossa existência. TRAC acredita que não
        se trata apenas de criar e estabelecer o meio ambiente no qual a fauna, a flora e os homens modernos têm a
        possibilidade de florescer, mas também nutrir o que já existe para se assegurar um futuro próspero.</p>
        <p>A TRAC aproveita todas as oportunidades para fazer bem à Mãe Natureza, apoia inúmeras iniciativas e programas
          que partilham o mesmo sentimento. Desde estabelecer o Verdejante, o projecto da N4 tem a protecção da flora e
          fauna, durante todos os trabalhos na estrada de grande envergadura como objectivo principal, no patrcínio de
          reabilitação&nbsp; e de cuidados de três rhinocerontes orfãos, abrigados no maior santuário do mundo para
          rhinocerontes orfãos, não deixamos uma pedra única que seja por virar na nossa pesquisa em ser-mos
          ambientalistas responsáveis.</p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEnvironment/Tree-Dark-2-1.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">OS PROJECTOS RELACIONADOS COM O MEIO AMBIENTE E/OU ORGANIZAÕES CRIADAS,
        <br/>APOIADAS E/OU ENDOSSADOS PELA TRAC SÃO:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_GREENING%20THE%20N4.png" alt=""
             class="img-fluid imgsize"/>
        <h6>GREENING THE N4</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_TREE%20OF%20REMEMBRANCE.png" alt=""
             class="img-fluid imgsize"/>
        <h6>TREE OF
          REMEMBRANCE</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_CARE%20FOR%20WILD%20REHABILITATION%20CENTRE.png"
             alt="" class="img-fluid imgsize"/>
        <h6>CARE FOR WILD
          REHABILITATION
          CENTRE</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_MASHESHA%20STOVES.png" alt=""
             class="img-fluid imgsize"/>
        <h6>MASHESHA STOVES</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_HEAL.png" alt="" class="img-fluid imgsize"/>
        <h6>HEAL</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_LEFPA.png" alt="" class="img-fluid imgsize"/>
        <h6>LEFPA</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_EWT.png" alt="" class="img-fluid imgsize"/>
        <h6>EWT</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiEnvironment/ENVIRONMENT%20Icons_OTHER.png" alt="" class="img-fluid imgsize"/>
        <h6>OTHER</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid" style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/educationport">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/healthport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SAÚDE E BENIFICÊNCIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputoport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">TRAC EM MOÇAMBIQUE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafetyport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SEGURANÇA RODOVIÁRIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smmeport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESENVOLVIMENTO SOCIAL
                    E ECONÔMICO</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandcultureport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESPORTO E CULTURA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
