import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageSwitchService {

  isEnglish = true;

  constructor(   ) {

  }

  public get language(): boolean {
    return this.isEnglish;
  }

  public set language(theLanguage) {

    this.isEnglish = theLanguage;
  }

}
