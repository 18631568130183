<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col-8 "><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">DESENVOLVIMENTO
            SOCIAL E ECONÔMICO</h1></div>
        <div class="col-4"><img src="./assets/images/csiSMME/SMME%20DEVELOPMENT%20&%20SUPPORT%20Banner_1.png" alt="About Us"
                                class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>




  <div class="container-fluid "
       style="background-image: url('/assets/images/csiSMME/Matsulu-Hawkers-5.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>Responsabilidade social é mais do que apenas suporte financeiro – é sobre pessoas – como deve benificiar e dar-lhes poder da forma
        de que elas mais necessitam. Responsabilidade social também é estabelecer sociedades valiosas a longo termo, e acabar com a
        divisão nas comunidades. <br/><br/>
        A TRAC é puramente apaixonada em levantar, dar poder e enriquecer as comunidades ao longo da N4 Rota com Portagem, por
        consequência um dos nossos objectivos primários do CSI é de assegurar que as comunidades ao longo de todo o trajecto da nossa
        estrada sejam beneficiados por inteiro da nossa concessão de 30 anos. Acreditamos de que, e mantemo-nos comprometidos, em
        impulsionar a sabedoria, encorajar o desenvolvimento profissional e apoiar em tudo quanto nos for possível. Para além disso, assegurar
        que as nossas iniciativas de responsabilidade social perdurem, trabalhamos atentamente com o governo local e regional, fóruns de
        negócio e outras corporações estabelecidas que partilham a nossa paixão, visão e metas.</p></div>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black bold">OS PROJECTOS SEGUINTES FAZEM PARTE DO PROGRAMA DE DESENVOLVIMENTO SOCIAL
        E ECONÔMICO DA TRAC:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-3 text-center">
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSMME/SMME%20DEVELOPMENT%20&%20SUPPORT%20Icons_ENTERPRISE%20DEVELOPMENT.png" alt=""  class="img-fluid imgsize"/>
        <h6>Desenvolvimento<br/>
          Empresarial
        </h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
        <img src="./assets/images/csiSMME/SMME%20DEVELOPMENT%20&%20SUPPORT%20Icons_PEAK%20TRAFFIC%20ROAD%20SAFETY%20CAMPAIGNS.png" alt="" class="img-fluid imgsize"/>
        <h6>Comerciantes - Matsulu</h6>
      </div>
      <div class="col-sm-12 col-md-3 text-center">
      </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environmentport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MEIO AMBIENTE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/educationport">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/healthport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SAÚDE E BENIFICÊNCIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputoport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">TRAC EM MOÇAMBIQUE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafetyport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SEGURANÇA RODOVIÁRIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandcultureport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESPORTO E CULTURA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>


</main>
