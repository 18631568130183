<!--   Carousel  **************** ************** **************** max-height: 520px; -->
<ngb-carousel *ngIf="images" class="grey-gradient-background "
              style="margin-top: 83px;   "
              [showNavigationArrows]="showNavigationArrows"
              [showNavigationIndicators]="showNavigationIndicators">
    <ng-template ngbSlide>
        <div class="container">
            <div class="row rounded">
                <!-- Start of the video section -->
                <div class="col-sm-12 col-lg-6 white-text ">
                    <div class="p-3 mt-3 mb-3     ">
                        <div class="embed-responsive embed-responsive-16by9 text-center ">
                            <iframe class="embed-responsive-item p-2 " style="max-height: 520px;  "
                                    src="https://www.youtube.com/embed/kZVI8tcaPh4?si=xNVeXxAm0ZpWz7Qz"
                                    title="TRAC N4"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                            </iframe>

                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-6 white-text ">
                    <div class="p-3 mt-3 mb-3     ">
                        <div class="removeMarginOnSmallScreens">
                            <h3 class="text-uppercase">TRAC - Making A Difference A Kilometre At A Time</h3>
                            <br/>
                            Inspired by Contemporary Design and community connections, it is our mission to
                            progressively build,
                            develop
                            and evolve. The rebranding of our company image positions us to take this collective vision,
                            well into the
                            future.

                            <br/><br/>
                            <h5 class="text-uppercase">
                                Play the video to learn about TRAC.
                            </h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </ng-template>
</ngb-carousel>
<!--
<ngb-carousel *ngIf="images" style="margin-top: 77px; background-color: #242424">
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[0]" alt=" first slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">YOUR GUARDIAN ON THE N4</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[1]" alt=" second slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">NOT JUST A ROAD</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[2]" alt="third slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">CONNECTING CULTURES, PEOPLE AND DESTINATIONS</div>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="wrapper">
      <img [src]="images[3]" alt="fourth slide">
    </div>
    <div class="carousel-caption">
      <div class="h1defintion text-uppercase">TRAC CARES</div>
    </div>
  </ng-template>
</ngb-carousel>
-->

<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-12 p-3  text-center bg-yellow ">
            <div class=" black-text bold">
                Live Route Map
            </div>
        </div>

        <div class="col-12 shadow bg-yellow">
            <div class="row text-center justify-content-center align-middle" style="height: 364px;">
                <iframe #theiframe id="theiframe"
                        [ngStyle]="{'width':setWidth}" [src]="url | safe"
                        class="text-center">
                </iframe>
            </div>
        </div>


        <div class="col-lg-12 p-3  text-center bg-yellow ">
            <div class=" black-text bold pointer">
                <a href="" routerLink="/liveroute" class="black-text fontsize13em pr-3"
                   title="Read More link...">Read More Information Here</a>
                <img title="Read More link..." src="./assets/images/home/UiIcons_Location.png"
                     class="img-fluid hoverstate"
                     routerLink="/liveroute"
                     style="height:28px;">
            </div>
        </div>


    </div>
</div>  -->
<!-- end of container-fluid -->
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12 p-3  text-center grey-gradient-background">
            <div class="text-uppercase white-text fontsize075">
                TRANS AFRICAN CONCESSIONS (TRAC) MANAGES THE N4 TOLL ROUTE STARTING FROM SOLOMON MAHLANGU OFF RAMP<br/>
                IN TSHWANE, GAUTENG TO THE MAPUTO PORT IN MOZAMBIQUE.
            </div>
        </div>
    </div>
</div> <!-- end of contrainer-fluid -->
<!-- Traffic advisory here --- -------------------------->

<!-- <div class="container-fluid" style="">
    <div class="row">
        <div class="col-lg-12 h1 text-center pt-4 ">
            Easter 2024
        </div>
        <div class="hide-on-large-screen text-center">
            <button class="btn black-text shadow bg-yellow fontsize13m text-center" (click)="open(content6)">Tap to View
                Predicted Traffic Flow
            </button>
            <img src="assets/images/Predicted-Traffic-Flow-2024-Easter.webp?v=20240327" class="img-fluid" (click)="open(content6)"/>
        </div>
        <div class="col-lg-12 pt-3 text-center hide-traffic-advisory-on-small-screen"
             style="overflow: auto">
            <div class="text-uppercase white-text ">
                <style type="text/css">
                    .tg {
                        border-collapse: collapse;
                        border-color: #ccc;
                        border-spacing: 0;
                        margin: 0 auto;
                        width: 100%;
                    }

                    .tg td {
                        background-color: #fff;
                        border-color: #ccc;
                        border-style: solid;
                        border-width: 1px;
                        color: #333;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        overflow: hidden;
                        padding: 11px 20px;
                        word-break: normal;
                    }

                    .tg th {
                        background-color: #f0f0f0;
                        border-color: #ccc;
                        border-style: solid;
                        border-width: 1px;
                        color: #333;
                        font-family: Arial, sans-serif;
                        font-size: 14px;
                        font-weight: normal;
                        overflow: hidden;
                        padding: 11px 20px;
                        word-break: normal;
                    }

                    .tg .tg-lto5 {
                        background-color: #ffcb2f;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-zyik {
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        top: -1px;
                        vertical-align: top;
                        will-change: transform
                    }

                    .tg .tg-d52n {
                        background-color: #32cb00;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-0pky {
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top;
                        font-weight: bold;

                    }

                    .tg .tg-tw5s {
                        background-color: #fe0000;
                        border-color: inherit;
                        text-align: left;
                        vertical-align: top
                    }

                    .tg .tg-7btt {
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-pxkr {
                        background-color: #32cb00;
                        border-color: inherit;
                        color: #000000;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .groen {
                        background-color: #32cb00;
                        border-color: inherit;
                        color: #000000;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-qz2x {
                        background-color: #ffcb2f;
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .oranje{
                        background-color: #ffcb2f;
                        border-color: inherit;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }

                    .tg .tg-siyb {
                        background-color: #fe0000;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .rooi {
                        background-color: #fe0000;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                    .tg .pers {
                        background-color: #73039f;
                        border-color: inherit;
                        color: #ffccc9;
                        font-weight: bold;
                        text-align: center;
                        vertical-align: top
                    }
                </style>
                <table class="tg">
                    <thead>
                    <tr>
                        <th class="tg-zyik h4" colspan="11">Predicted Traffic Flow for the TRAC N4 Route for Easter 2024
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="tg-0pky">Time</td>
                        <td class="tg-0pky">Wednesday</td>
                        <td class="tg-0pky">Thursday</td>
                        <td class="tg-0pky">Thursday</td>
                        <td class="tg-0pky">Friday</td>
                        <td class="tg-0pky">Saturday</td>
                        <td class="tg-0pky">Sunday</td>
                        <td class="tg-0pky">Monday</td>
                        <td class="tg-0pky">Tuesday</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky"></td>
                        <td class="tg-0pky">20 March 2024</td>
                        <td class="tg-0pky">21 March 2024</td>
                        <td class="tg-0pky">28 March 2024</td>
                        <td class="tg-0pky">29 March 2024</td>
                        <td class="tg-0pky">30 March 2024</td>
                        <td class="tg-0pky">31 March 2024</td>
                        <td class="tg-0pky">01 April 2024</td>
                        <td class="tg-0pky">02 April 2024</td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">00:00 - 03:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">03:00 - 06:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">06:00- 09:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">09:00- 12:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">12:00 - 15:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-bad"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">15:00 - 18:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-heavy"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-heavy"></td>
                    </tr>
                    <tr>
                        <td class="tg-0pky">18:00 - 21:00</td>
                        <td class="tf-busy"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-busy"></td>
                        <td class="tf-busy"></td>

                    </tr>
                    <tr>
                        <td class="tg-0pky">21:00 - 00:00</td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                        <td class="tf-light"></td>
                    </tr>
                    <tr>
                        <td class="tg-7btt" colspan="11">INDEX</td>
                    </tr>
                    <tr>
                        <td class="tf-light" colspan="11">Light Traffic Conditions</td>
                    </tr>
                    <tr>
                        <td class="tf-busy" colspan="11">Busy Traffic Conditions</td>
                    </tr>
                    <tr>
                        <td class="tf-heavy" colspan="11">Heavy Traffic Conditions - Expect Delays</td>
                    </tr>
                    <tr>
                        <td class="tf-bad" colspan="11">Exceptionally Heavy Traffic Conditions - Expect Delays</td>
                    </tr>
                    </tbody>
                </table>
                <br/><br/>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="actionlink" href="assets/traffic-advisory/pdfs/Traffic Management Flyer - Eng - March 2024.pdf" target="_blank">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/traffic-advisory-english.webp?v20240327"
                        class="img-fluid shadow" alt="TRAC Cares">
                    </div>
                    <div class="cta-content hide bg-black" style="width: 97%;">
                        <h3 class="text-center">TRACN4 TRAFFIC ADVISORY</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <p class="text-center">CLICK HERE TO VIEW</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div> -->

<!-- End of Traffic advisory here --- -------------------------- -->

<div class="container-fluid" style="">
    <div class="container">
        <div class="row">
            <div class="col-12 mt-5">
                <a class="actionlink" href="" routerLink="/tracevents">
                    <img src="./assets/images/media/rally2024/TRAC-Banner-Rally2024.webp?v=20241112" class="img-fluid shadow" alt="TRAC Rally">
                </a>
            </div>
        </div>
    </div>
</div>

<div class=" text-center">
    <div class="container">
        <div class="row py-4">
            <!-- Image 1 with black mouse over TRAC CARES-->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/tracn4-cares-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="TRAC Cares">
                    </div>
                    <div class="cta-content hide bg-black" (click)="open(content1)">
                        <h3 class="text-center">Learn More</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** ---  Tags Only -->
            <!-- Image 3 with black mouse over -->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/" (click)="open(content3)">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/get-a-tag-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="Tags Only">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Learn More</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** --- WHY STOP -->
            <!-- Image 2 with black mouse over -->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/" (click)="open(content2)">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/why-stop-thumbnail.webp?v20240410"
                             class="img-fluid shadow" alt="Why stop">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Learn More</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>
            <!-- ------------- *************************** --- Toll Tariffs -->
            <div class="col-sm-12 col-md-3 text-center mb-3">
                <a class="actionlink" href="" routerLink="/plazafees">
                    <div class="cta-img">
                        <img src="./assets/images/home-tiles/toll-tariffs-thumbnail.webp?v=20240410"
                             class="img-fluid shadow" alt="sanral 2024">
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center">Learn More</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                    </div>
                </a>
            </div>

            <!-- ------------- *************************** --- TAG ONLY -->

            <!-- Image 4 with black mouse over -->
            <!--
            <div class="col-sm-12 col-md-3 text-center mb-3">
              <a class="actionlink" href="" routerLink="/" (click)="open(content4)">
                <div class="cta-img">
                  <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Traffic%20Advisory_Why%20Stop%20Thubnail.jpg" class="img-fluid shadow" alt="TRAC Cares">
                </div>
                <div class="cta-content hide bg-black">
                  <h3 class="text-center">Learn More</h3>
                  <div class="dot-divider mb-3">
                    <span class="dot-divider-white"></span>
                  </div>
                </div>
              </a>
            </div>
            -->
            <!-- ------------- *************************** ---  -->

            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <div class="actionlink">
                    <div class="cta-img">
                        <img src="./assets/images/Department Icons_Trac Assist.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">TRACASSIST</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">Free roadside assistance.</div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase">Call our 24 hour help desk</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <h3 class="text-center text-uppercase"><a class="h2 white-text" href="tel:0800 87 22 64">0800 87
                            22 64</a></h3>
                        <h2 class="text-center text-uppercase"><a class="h2 white-text" href="tel:8009022">800 9022</a>
                        </h2>
                    </div>
                </div>
            </div>

            <!-- ------------ -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <a class="actionlink" href="" routerLink="/about-company-profile">
                    <div class="cta-img">
                        <img src="./assets/images/Department-Icons_About-Trac.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">About US</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">View our company profile</div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase">About US</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <h3 class="text-center text-uppercase"><a href="tel:0800872264"></a>Find out More</h3>
                    </div>
                </a>
            </div>

            <!-- ------------ -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <a class="actionlink" href="" routerLink="/plazafees">
                    <div class="cta-img">
                        <img src="./assets/images/Departmentcons_TollFees.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">Toll fees</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">View the latest toll fees</div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase ">Toll Tariff</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <h3 class="text-center text-uppercase ">Effective 1 March 2024</h3>
                    </div>
                </a>
            </div>

            <!-- ------------  CSI Guide -->
            <div class="col-md-3 p-4 text-center mb-3" style="position: relative;">
                <div class="actionlink" href="#"  >
                    <div class="cta-img">
                        <img src="./assets/images/csiEducation/CSI%20Icons_Education.png" class="p-2" alt="Trac Cares"
                             style="max-height: 200px;">
                        <h5 class="mt-2 mb-0 font-bold text-uppercase black-text">CSI Book</h5>
                        <div class="mt-2 mb-0 font-bold text-uppercase black-text">
                            <a class="" style="font-size: inherit; color:inherit"
                               href="assets/csi/csi.pdf">TRAC Uplifting Communities</a>
                        </div>
                    </div>
                    <div class="cta-content hide bg-black">
                        <h3 class="text-center text-uppercase ">Download PDF Now</h3>
                        <div class="dot-divider mb-3">
                            <span class="dot-divider-white"></span>
                        </div>
                        <a class="apdfs text-center "
                           style=" border: 1px solid #fff; font-size: 1.2em; width:100%; border-radius: 3px;"
                           href="assets/csi/CSI Book - TRAC Uplifting Communities.pdf"
                           title="Click and View PDF Document"
                           target="_blank">Download Document</a>
                    </div>
                </div>
            </div>


        </div>
        <div class="row py-4">
            <div class="col">
                <div id="block--2" class="block block--wysiwyg_block">
                    <div class="block__content ">
                        <p>The N4 Toll Route is a Build, Operate and Transfer (BOT) toll road, and is
                            approximately 570 kilometres long. TRAC has a 30-year concession with the South African and
                            Mozambican
                            national roads agencies – SANRAL and ANE respectively – signed in 1997 to develop, manage
                            and maintain the
                            road in a bid to stimulate and facilitate trade and investment in three key economical
                            regions – Gauteng,
                            Mpumalanga and Mozambique.</p>
                        <p>TRAC’s development and maintenance of the N4 toll road is possible through the funds
                            generated from toll
                            fees from the six toll plazas TRAC runs along the route.</p>
                        <p>Offering some of the most scenic vistas in South Africa, the TRAC N4 Toll Route also offers
                            travelers a
                            feast of historical, scenic and geological features that make for a trip along this majestic
                            road an
                            unforgettable experience. Furthermore, there are a multitude of leisure options to explore,
                            including
                            sports and nature activities, as well as eateries serving delicious, often unique, food and
                            beverages.&nbsp;<a
                                    href="#" routerLink="/about-company-profile">Read More.</a></p></div>
                </div>
            </div>
        </div>
    </div>
</div>

<img src="assets/images/road-footer.jpg" alt="Road footer" style="width: 100%;">


<ng-template #content1 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Trac Cares</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/trac-cares-flyer.webp?v20240426"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title2">Why Stop ?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/why-stop-flyer.webp?v20240412"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content3 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title3">Tag-Only</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/get-a-tag-flyer.webp?v20240412"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content4 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title4">Traffic Advisory</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <img src="./assets/images/home-tiles/Trac%20Website%20Realeases%20Traffic%20Advisory_Why%20Stop%20Graphic.jpg"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>

<ng-template #content5 let-modal>
    <div class="modal-header bg-yellow ">
        <h4 class="modal-title" id="modal-basic-title5">Announcement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body   grey-gradient-background2 p-1">
        <!-- <h4 class="bold">Dear TRAC N4 Route road user</h4>
        <br/>
        -->
        <img src="assets/TrafficManagement-Dec-2022/TrafficManagementFlyer-Eng-Dec2022.jpg"

             class="img-fluid imgHeight mx-auto d-block shadow" />
      <!--  <div>
            <h4 class="bold">
                Exciting News! Express Lanes to be rolled out along the entire TRAC N4 Route
            </h4>
            <br/>
            <p>
                Paying toll fees on the TRAC N4 Route has just gotten easier and more convenient with the
                introduction of Express Lanes along the Maputo Corridor.
            </p>
            <p>
                Express Lanes were introduced to the South African section of the route in 2019, at the Diamond Hill
                Plaza. Road users took to them so well that Trans African Concessions decided to establish this
                convenient toll payment method at all its mainline plazas in South Africa, with the roll-out campaign
                starting at the Nkomazi Plaza.
            </p>
            <p>
                Express Lanes are electronic, tag-only lanes, which road users go through while travelling at a
                maximum speed of 40 km/h. They are different from Tag-Only lanes, in which road users must come
                to an almost complete stop to activate their tag.
            </p>
            <p>
                Express Lanes also enable electronic tag users to use a dedicated lane, separate from the normal toll
                plaza lanes, which eliminates the occurrence of tag holders having to reverse in tag-only lanes when
                another road user erroneously attempts to drive through one.
            </p>

            <p class="bold">
                For more information, please click here:
            </p>
            <a href="#" routerLink="/plazafees" (click)="modal.dismiss('Cross click')">Express Lane Benefits</a>

            <br/>
            <a href="#" routerLink="/plazafees" (click)="modal.dismiss('Cross click')">How to get a Tag</a>

        </div>

        <br/><br/>
        <p>
      <span class="bold">
        For more information contact the TRAC 24-hour Helpdesk
      </span>
            <br/>
            Toll-free: 0800 87 22 64
            <br/>
            Whatsapp: 082 881 4444
            <br/>
            Email:<a href="email:helpdesk@tracn4.co.za">helpdesk@tracn4.co.za</a>
        </p>
-->
    </div>
    <div class="modal-footer bg-yellow">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<ng-template #content6 let-modal>
    <div class="modal-header bg-yellow ">
        <h4 class="modal-title" id="modal-basic-title6">Traffic Advisory December 2024</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body   grey-gradient-background2 p-4" style="overflow: scroll; ">
        <!-- Traffic advisory here December--- -------------------------- -->
        <!-- <div class="container-fluid" style="">
            <div class="row">
                <div class="col-lg-12 h1 text-center pt-4 ">
                    2023 December
                </div>
                <div class="col-lg-12 pt-3 text-center "
                     style="overflow: auto">
                    <div class="text-uppercase white-text ">
                        <style type="text/css">
                            .tg {
                                border-collapse: collapse;
                                border-color: #ccc;
                                border-spacing: 0;
                                margin: 0 auto;
                                width: 100%;
                            }
        
                            .tg td {
                                background-color: #fff;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }
        
                            .tg th {
                                background-color: #f0f0f0;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                font-weight: normal;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }
        
                            .tg .tg-lto5 {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }
        
                            .tg .tg-zyik {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                top: -1px;
                                vertical-align: top;
                                will-change: transform
                            }
        
                            .tg .tg-d52n {
                                background-color: #32cb00;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }
        
                            .tg .tg-0pky {
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top;
                                font-weight: bold;
        
                            }
        
                            .tg .tg-tw5s {
                                background-color: #fe0000;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }
        
                            .tg .tg-7btt {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
        
                            .tg .tg-pxkr {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .groen {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }
        
                            .tg .tg-qz2x {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .oranje{
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
        
                            .tg .tg-siyb {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .rooi {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .pers {
                                background-color: #73039f;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                        </style>
                        <table class="tg">
                            <thead>
                            <tr>
                                <th class="tg-zyik h4" colspan="11">Predicted Traffic Flow for the TRAC N4 Route for 2023 Festive
                                    Season
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="tg-0pky">Time</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Saturday</td>
                                <td class="tg-0pky">Tuesday</td>
                                <td class="tg-0pky">Sunday</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky"></td>
                                <td class="tg-0pky">01 Dec 2023</td>
                                <td class="tg-0pky">08 Dec 2023</td>
                                <td class="tg-0pky">14 Dec 2023</td>
                                <td class="tg-0pky">15 Dec 2023</td>
                                <td class="tg-0pky">21 Dec 2023</td>
                                <td class="tg-0pky">22 Dec 2023</td>
                                <td class="tg-0pky">23 Dec 2023</td>
                                <td class="tg-0pky">01 Jan 2024</td>
                                <td class="tg-0pky">07 Jan 2024</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">00:00 - 02:00</td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">02:00 - 05:00</td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">05:00- 08:00</td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="oranje"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">08:00- 11:00</td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-0pky">11:00- 14:00</td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-0pky">14:00 - 17:00</td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="rooi"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-0pky">17:00 - 20:00</td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="rooi"></td>
                                <td class="rooi"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="oranje"></td>
                                <td class="tg-d52n"></td>
                                <td class="oranje"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">20:00 - 23:00</td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
                                <td class="tg-d52n"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-7btt" colspan="11">INDEX</td>
                            </tr>
                            <tr>
                                <td class="tg-pxkr" colspan="11">Normal Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tg-qz2x" colspan="11">Busy Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tg-siyb" colspan="11">Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            <tr>
                                <td class="pers" colspan="11">Extreme Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End of Traffic advisory here --- -------------------------- -->
        <!-- Traffic advisory here Easter--- -------------------------- -->
        <div class="container-fluid" style="">
            <div class="row">
                <div class="col-lg-12 h1 text-center pt-4 ">
                    Easter 2024
                </div>
                <div class="col-lg-12 pt-3 text-center "
                    style="overflow: auto">
                    <div class="text-uppercase white-text ">
                        <style type="text/css">
                            .tg {
                                border-collapse: collapse;
                                border-color: #ccc;
                                border-spacing: 0;
                                margin: 0 auto;
                                width: 100%;
                            }

                            .tg td {
                                background-color: #fff;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }

                            .tg th {
                                background-color: #f0f0f0;
                                border-color: #ccc;
                                border-style: solid;
                                border-width: 1px;
                                color: #333;
                                font-family: Arial, sans-serif;
                                font-size: 14px;
                                font-weight: normal;
                                overflow: hidden;
                                padding: 11px 20px;
                                word-break: normal;
                            }

                            .tg .tg-lto5 {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-zyik {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                top: -1px;
                                vertical-align: top;
                                will-change: transform
                            }

                            .tg .tg-d52n {
                                background-color: #32cb00;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-0pky {
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top;
                                font-weight: bold;

                            }

                            .tg .tg-tw5s {
                                background-color: #fe0000;
                                border-color: inherit;
                                text-align: left;
                                vertical-align: top
                            }

                            .tg .tg-7btt {
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-pxkr {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .groen {
                                background-color: #32cb00;
                                border-color: inherit;
                                color: #000000;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-qz2x {
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .oranje{
                                background-color: #ffcb2f;
                                border-color: inherit;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }

                            .tg .tg-siyb {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .rooi {
                                background-color: #fe0000;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                            .tg .pers {
                                background-color: #73039f;
                                border-color: inherit;
                                color: #ffccc9;
                                font-weight: bold;
                                text-align: center;
                                vertical-align: top
                            }
                        </style>
                        <table class="tg">
                            <thead>
                            <tr>
                                <th class="tg-zyik h4" colspan="11">Predicted Traffic Flow for the TRAC N4 Route for Easter 2024
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class="tg-0pky">Time</td>
                                <td class="tg-0pky">Wednesday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Thursday</td>
                                <td class="tg-0pky">Friday</td>
                                <td class="tg-0pky">Saturday</td>
                                <td class="tg-0pky">Sunday</td>
                                <td class="tg-0pky">Monday</td>
                                <td class="tg-0pky">Tuesday</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky"></td>
                                <td class="tg-0pky">20 March 2024</td>
                                <td class="tg-0pky">21 March 2024</td>
                                <td class="tg-0pky">28 March 2024</td>
                                <td class="tg-0pky">29 March 2024</td>
                                <td class="tg-0pky">30 March 2024</td>
                                <td class="tg-0pky">31 March 2024</td>
                                <td class="tg-0pky">01 April 2024</td>
                                <td class="tg-0pky">02 April 2024</td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">00:00 - 03:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">03:00 - 06:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">06:00- 09:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">09:00- 12:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">12:00 - 15:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-bad"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">15:00 - 18:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-heavy"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-heavy"></td>
                            </tr>
                            <tr>
                                <td class="tg-0pky">18:00 - 21:00</td>
                                <td class="tf-busy"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-busy"></td>
                                <td class="tf-busy"></td>
        
                            </tr>
                            <tr>
                                <td class="tg-0pky">21:00 - 00:00</td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                                <td class="tf-light"></td>
                            </tr>
                            <tr>
                                <td class="tg-7btt" colspan="11">INDEX</td>
                            </tr>
                            <tr>
                                <td class="tf-light" colspan="11">Light Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tf-busy" colspan="11">Busy Traffic Conditions</td>
                            </tr>
                            <tr>
                                <td class="tf-heavy" colspan="11">Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            <tr>
                                <td class="tf-bad" colspan="11">Exceptionally Heavy Traffic Conditions - Expect Delays</td>
                            </tr>
                            </tbody>
                        </table>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End of Traffic advisory here --- -------------------------- -->
    </div>
    <div class="modal-footer bg-yellow">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>


<style>

    .modal-dialog {
        position: relative;
        width: auto;
        margin: 12.5rem 1rem 0 1rem !important;
        pointer-events: none;
    }

</style>
