<div  *ngIf="langService.isEnglish"  class="container-fluid ">
  <div class="row bg-yellow p-3">

    <div class="col-sm-12 col-md-12">
      <div class="container ">
        <div class="row py-4">
          <div class="col-xs-12 col-md-8 ">
            <div id="block--5678" class="block block--wysiwyg_block mb-1">
              <div class="block__content ">
                <div class="black-text bold">WOULD YOU LIKE TO GET IN TOUCH WITH TRAC?</div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 mobilemarginAdjust">
            <div class="text-right float-right" style="position: absolute; right: 20px; top:0px;">
              <a href="" class="btn-black text-uppercase" routerLink="contact">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div  *ngIf="langService.isEnglish" class="container-fluid N4grey-gradient-background ">
  <div class="row ">
    <div class="col-sm-12 ">

      <div class="container">
        <div class="row" id="footer">
          <div class="col-xs-12 col-md-3">
            <div class="text-center">
              <img class="m-3" src="assets/images/logolight.png"  class="img-fluid" alt="Logo">
            </div>
          </div>
          <div class="col-xs-12 col-md-3">
            <div class="text-center footer-about mt-4"><h4>ABOUT US</h4>
              <br/>
              <div class="m-2">Trans African Concessions (TRAC) manages the N4 Toll Route starting from
                Solomon Mahlangu off ramp in Tshwane,
                Gauteng to the Maputo Harbour in Mozambique.
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-3">
            <div class="text-center mt-4 ">
              <h4>QUICK LINKS</h4>
              <br/>
              <ul class="list-style-none p-1 m-1" style="">
                <li><a routerLink="/home" href="" target="_self" >Home</a></li>
                <li><a routerLink="/plazawhytoll" href="" target="_self">Why Toll?</a></li>
                <li><a routerLink="/plazafees" href="" target="_self">Toll Plazas &amp; Fees</a></li>
                <li><a routerLink="/plazaconcessionrequirements" href="" target="_self">Concession Requirements</a>
                </li>
                <li><a routerLink="/contact" href="" target="_self">Contact Us</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xs-12 col-md-3 ">
            <div class="text-center mt-4 ">
              <h4>GET IN TOUCH</h4>
              <br/>
              <div class="mt-2">24 Hour Helpdesk</div>
              <div><a href="tel:0800 87 22 64">0800 87 22 64 (RSA) / <br/>800 9022 (Moz)</a></div>
              <div>Additional Contact Details</div>
              <ul class="f-inline-ul  p-1 m-1" style="display: inline-flex">
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.facebook.com/TracN4/" target="_blank">
                    <img src="assets/images/TracN4%20Social%20Icons_Facebook.png" />
                  </a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.instagram.com/tracn4/" target="_blank">
                    <img src="assets/images/TracN4%20Social%20Icons_Instagram.png" /></a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://twitter.com/TRACN4route?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                     target="_blank"><img src="assets/images/TracN4%20Social%20Icons_X.png?v=20240903" /></a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.youtube.com/channel/UCYL3Yls6QnXePvAVL2WywPQ" target="_blank" class="small">
                    <img src="assets/images/TracN4%20Social%20Icons_Youtube.png" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12  black-background-color ">
      <div id="">
        <div class="white-text text-center fontsize080 pt-3 pb-3">
          © 2021 Trans African Concessions (Pty) Ltd. All Rights
          Reserved.
        </div>
      </div>
    </div>
  </div>
</div>



<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------Portuguese Footer below ------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->
<!-- ----------------------------------------------------------------------------------------------------------- -->


<div  *ngIf="!langService.isEnglish"  class="container-fluid ">
  <div class="row bg-yellow p-3">

    <div class="col-sm-12 col-md-12">
      <div class="container ">
        <div class="row py-4">
          <div class="col-xs-12 col-md-8 ">
            <div id="block--58" class="block block--wysiwyg_block mb-1">
              <div class="block__content ">
                <div class="black-text bold">GOSTARIA DE ENTRAR EM CONTACTO COM A TRAC?</div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 mobilemarginAdjust ">
            <div class="text-right float-right" style="position: absolute; right: 20px; top:0px;">
              <a href="" class="btn-black text-uppercase" routerLink="contact">CONTACTE-NOS</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div  *ngIf="!langService.isEnglish" class="container-fluid N4grey-gradient-background ">
  <div class="row ">
    <div class="col-sm-12 ">

      <div class="container">
        <div class="row" id="footerport">
          <div class="col-xs-12 col-md-3">
            <div class="text-center">
              <img class="m-3 ml-2" src="assets/images/logolight.png" style="height: 80px;" alt="Logo">
            </div>
          </div>
          <div class="col-xs-12 col-md-3">
            <div class="text-center footer-about mt-4 "><h4>SOBRE NÓS</h4>
              <br/>
              <div class="m-2 mobileFooterAdjustment">Trans African Concessions (TRAC) tem
                como responsabilidade gerir a N4 Rota
                com Portagem a partir da rampa de
                saída Solomon Malhangu na cidade de
                Tshwane (Pretoria), na Provincia de
                Gauteng até ao Porto de Maputo em
                Moçambique.
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-3">
            <div class="text-center mt-4 ">
              <h4>QUICK LINKS</h4>
              <br/>
              <ul class="list-style-none p-1 m-1" style="">
                <li><a routerLink="/homeport" href="" target="_self" >Casa</a></li>
                <li><a routerLink="/plazawhytollport" href="" target="_self">Porquê Portagem?</a></li>
                <li><a routerLink="/plazafeesport" href="" target="_self">Plazas com Portagem & Tarifas de Portagem</a></li>
                <li><a routerLink="/plazaconcessionrequirementsport" href="" target="_self">Requerimentos de Concessão</a>
                </li>
                <li><a routerLink="/contactport" href="" target="_self">Contacte-nos</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xs-12 col-md-3 ">
            <div class="text-center mt-4 ">
              <h4>CONTACTOS</h4>
              <br/>
              <div class="mt-2">24/24 Linha do Cliente<br/><br/></div>
              <div><a href="tel:0800 87 22 64">0800 87 22 64 (RSA) /<br/>800 9022 (Moz)</a><br/><br/></div>
              <div>Mídia Social<br/><br/></div>
              <ul class="f-inline-ul  p-1 m-1" style="display: inline-flex">
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.facebook.com/TracN4/" target="_blank">
                    <img src="assets/images/TracN4%20Social%20Icons_Facebook.png" />
                  </a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.instagram.com/tracn4/" target="_blank">
                    <img src="assets/images/TracN4%20Social%20Icons_Instagram.png" /></a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://twitter.com/TRACN4route?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                     target="_blank"><img src="assets/images/TracN4%20Social%20Icons_X.png" /></a>
                </li>
                <li class="mr-3 fontsize1-5">
                  <a href="https://www.youtube.com/channel/UCYL3Yls6QnXePvAVL2WywPQ" target="_blank" class="small">
                    <img src="assets/images/TracN4%20Social%20Icons_Youtube.png" /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12  black-background-color ">
      <div id=" ">
        <div class="white-text text-center fontsize080 pt-3 pb-3">
          © 2021 Trans African Concessions (Pty) Ltd. All Rights
          Reserved.
        </div>
      </div>
    </div>
  </div>
</div>
