<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold">CSI</h6>
          <h1 class="text-right ">SPORTS &amp; CULTURE</h1></div>
        <div class="col"><img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20Banner_1.png" alt="About Us"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>It is generally accepted that a diverse range of social benefits are
        derived from engagement and participation in sport and cultural
        activities. Numerous studies on the benefits of these have been done with the majority of them indicating that
        participation promotes
        pro-social behaviour, improves academic performance and contributes positively to participants’ emotional,
        physical and mental
        well-being.</p></div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-12"><h4 class="text-center text-black">TRAC HAS WITNESSED THESE BENEFITS
            FIRST-HAND
            THROUGH THE VARIOUS SPORTS AND CULTURAL
            EVENTS AND PROJECTS WE HAVE SUPPORTED SINCE THE START OF OUR CONCESSION IN 1997</h4>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_SELATI%20CUP.png" alt=""
             class="img-fluid imgsize"/>
        <h6>SELATI CUP</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CROC%20CANOE%20MARATHON.png"
             alt="" class="img-fluid imgsize"/>
        <h6>SELATI MARATHON</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_GREATEST%20TRAIN%20RACE--.png" alt=""
             class="img-fluid imgsize"/>
        <h6>GREATEST TRAIN
          RACE</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CROC%20CANOE%20MARATHON.png"
             alt="" class="img-fluid imgsize"/>
        <h6>TRAC CROC CANOE
          MARATHON</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_SCHOOL%20SPORTS--.png"
             alt="" class="img-fluid imgsize"/>
        <h6>SCHOOL SPORTS</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_TRAC%20CURRO%20CHESS--.png" alt=""
             class="img-fluid imgsize"/>
        <h6>TRAC CURRO CHESS</h6>
      </div>
      <div class="col-sm-12  col-md-3 text-center">
        <img src="./assets/images/csiSports/SPORTS%20&%20CULTURE%20%20Icons_INNIBOS.png" alt=""
             class="img-fluid imgsize"/>
        <h6>INNIBOS</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiSports/Croc-Canoe-70.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
      </div>
    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">RELATED LINKS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">

            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environment">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ENVIRONMENT</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/health">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Health%20&%20Welfare.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">HEALTH & WELFARE</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputo">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MAPUTO CORRIDOR</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafety">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">ROAD SAFETY</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smme">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SMME DEVELOPMENT & SUPPORT</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/education">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green"
                  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCATION & SKILLS DEVELOPMENT</h3>
                </div>
              </a>
            </div>
            <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
