<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">LOAD CONTROL</h1></div>
        <div class="col"><img src="./assets/images/loadcontrol/LOAD%20CONTROL%20Icon-01.png"
                              alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4 d-flex align-items-center">
      <div class="col-sm-12 col-md-12">
        <div class="row">
          <div class="col-sm-12"><h5 class="text-center text-black text-uppercase">Protecting the Infrastructure</h5>
            <div class="dot-divider mb-3">
              <span class="dot-divider-black"></span>
            </div>
            <p>Overloaded vehicles cause extensive damage to South Africa’s roads every year. This compromises the
              safety of all road users and escalates road maintenance costs.</p>
            <p>Surveys conducted along the N4 Maputo Corridor Toll Route in the early stages of the concession contract
              indicated that more than a third of the vehicles travelling the route were overloaded. This prompted TRAC
              to enter into an operations agreement with SANRAL in an attempt to address this dilemma.</p>
            <p>Although TRAC has no law enforcement rights, we nevertheless play an essential part in reducing
              overloading as we manage seven (7) LCC’s in SA, three (3) in Mozambique and 11 lay-bye centres on the
              route. Traffic officers stationed at these facilities are able to perform their law enforcement role.</p>
            <p>Since the introduction of LCCs on the N4 more than 10-million vehicles have been weighed. Overloading has
              been significantly reduced on the N4 Toll Route. Of vehicles weighed at the LCC’s on the South African
              section of the route, 21% of the average daily heavy vehicle traffic are overloaded and less than 2% of
              these are illegally (above the grace limit) overloaded.</p>
            <p><br></p></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class="py-6 image-sep"
             style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6">
        <div id="block--14" class="block block--wysiwyg_block">
          <div class="block__content"><h5 class="text-center text-black">SETTING THE STANDARD</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <p>Since signing the LCC contract with SANRAL and ANE, TRAC has been setting the standard in terms of LCC
              innovation and technology. In 2007 SANRAL and TRAC implemented state-of-the-art load control technology
              and satellite tracking at the Donkerhoek Traffic Control Centre (TCC) on the N4 Toll Route whereby heavy
              vehicles on the N4 Toll Route and alternative routes between Tshwane and Bronkhorstspruit are screened for
              possible overloading while driving over weigh-in-motion (WIM) devices on the road.</p>
            <p>WIM devices are now standard across the N4 Toll Route and play a significant role in curbing overloading
              on the national road.</p>
            <p>In 2015 TRAC established a vanguard weighbridge – the Pessene LCC in Mozambique. This is undoubtedly
              TRAC’s most unique LCC and uses only solar power to operate allowing it to run independently from the
              Mozambican Power Grid. Furthermore, it has a dedicated lane for heavy vehicles to ensure they are
              automatically directed into the weighing facility.&nbsp; Another distinctive feature of Pessene is the
              Biometric Control (finger print scanning) system, that ensures only people registered on the system can
              weigh and release a vehicle.</p></div>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="block--15" class="block block--wysiwyg_block">
          <div class="block__content"><h5 class="text-center text-black">OVERLOADING FACTS</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <ul>
              <li><span class="uavc-list-desc ult-responsive"><p>Overloaded vehicles cause damage to the road surface by a factor that grows exponentially as the load increases on a national road.</p></span>
              </li>
              <li><span class="Defaults-check"></span><span class="uavc-list-desc ult-responsive">Damage to roads as a result of overloading leads to higher maintenance and repair costs and shortens the life of a road. This places an additional burden on the state as well as law abiding road users who ultimately carry the costs of inconsiderate overloaders.</span>
              </li>
              <li><span class="Defaults-check"></span><span class="uavc-list-desc ult-responsive">Overloaded vehicles pose a threat to road safety. Overloaded vehicles require longer distances to brake, generate high centrifugal forces in corners and shifting loads or loss of tyre grip becomes an instant safety risk. Overloaded vehicles have a reduced tyre life, an increased risk of blow-outs and reduce the driver’s control over the vehicle, escalating the chances for an accident to occur.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-yellow">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase ">Related Links</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 text-center">
        <a class="actionlink" href="" routerLink="/loadcontrolcenter">
          <div class="cta-img">
            <img src="./assets/images/loadcontrol/phone.png" alt="Location Green">
            <h5 class="mt-3 mb-0 black-text">CONTACT LCC</h5><h6
            class="m-0 black-text">Contact The Load Control Centres</h6></div>
          <div class="cta-content hide bg-black"><h3 class="text-center">CONTACT THE LOAD CONTROL CENTERS</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center"></h3></div>
        </a>
      </div>
    </div>
  </div>

</main>
