import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sportsandculture',
  templateUrl: './sportsandculture.component.html',
  styleUrls: ['./sportsandculture.component.scss']
})
export class SportsandcultureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
