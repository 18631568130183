<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right h1defintion">NUMEROS DE CONTACTO<br/>
            DOS CENTROS DE<br/>
            CONTROLE DE CARGA</h1></div>
        <div class="col"><img src="./assets/images/loadcontrol/phone.png"  alt="Contact Center"      style="max-height: 200px; width: auto" ></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6 contactlist" *ngIf="apiContent !== ''">
        <h5 class="text-center text-black bold">AFRICA DO SUL</h5>
        <div class="dot-divider mb-4">
          <span class="dot-divider-black"></span>
        </div>
        <ng-container *ngFor="let o of this.apiContent.body?.Data">
          <div class="mb-3"  *ngIf="o.CountryId === 1">
            <strong>{{o.Name}}</strong>
            <a href="tel:+27{{o.ContactNumber}}">{{o.ContactNumber}}</a>
          </div>
        </ng-container>
      </div>


      <div class="col-sm-6 contactlist"  *ngIf="apiContent !== ''">
        <h5 class="text-center text-black bold">MOÇAMBIQUE</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
        <ng-container *ngFor="let o of this.apiContent.body?.Data">
          <div class="mb-3"  *ngIf="o.CountryId === 2">
            <strong>{{o.Name}} 123</strong>
            <a href="tel:+27{{o.ContactNumber}}">{{o.ContactNumber}}</a>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
  <div class="container-fluid bg-yellow">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase ">LINKS RELACIONADOS</h5>
        <div class="dot-divider mb-1"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 text-center"><a class="actionlink" href="" routerLink="/loadcontrolport">
        <div class="cta-img">
          <img src="./assets/images/loadcontrol/truck.png" class="p-0 img-fluid" style="height: 140px;" alt="Location Green">
          <h5 class="mt-3 mb-0 black-text" >CONTROLO DE CARDA</h5><h6
          class="m-0 black-text" >MAIS INFORMAÇÃO</h6></div>
        <div class="cta-content hide bg-black"><h3 class="text-center">MAIS INFORMAÇÃO</h3>
          <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
          <h3 class="text-center"></h3></div>
      </a></div>
    </div>
  </div>

</main>
