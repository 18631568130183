<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow shadow">
    <div class="container-fluid">
      <div class="row py-2 justify-content-center align-items-center ">
        <div class="col offset-2">
          <h1 class="text-right text-uppercase h1defintion" >Routine Maintenance</h1></div>
        <div class="col"><img src="./assets/images/tenders/Routine%20Maintance%20Specs%20Icon-01.png"
                              style="max-height: 200px; width: auto" alt="Road"></div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">

        <h5 class="text-center font-bold">ROAD ROUTINE MAINTENANCE SPECIFICATIONS FOR CONTRACTORS</h5>
        <div class="dot-divider mb-3">
          <span class="dot-divider-black"></span>
        </div>
        <h6 class="text-center font-bold">Available Downloads</h6>
      </div>
    </div>


    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="row py-4" *ngIf="apiContent != ''" >
          <ng-container *ngFor="let o of this.apiContent.body.Data">
            <div class="col-sm-12 col-md-3 text-center " style="border:0px solid #eee; min-height:vh20;  ">
              <div class="row ">
                <div class="col-12 h-100 ">
                  <!--
                  ListDocumentsData: Array(3)
                  Category: "Tenders Routine Maintenance - Routine Maintenance Specs"
                  Date: "2021-07-12T00:00:00"
                  Description: "Drawings A & B\r\n"
                  FileName: "TP1915-A1_LIST-OF-DRAWINGS.pdf"
                  ID: 6
                  Name: "TP1915-A1_LIST-OF-DRAWINGS"
                  -->
                  <h5 class="text-center pb-2 mt-4 ">{{o.Description}}</h5>
                  <div class="row ">
                    <ng-container *ngFor="let list of o.ListDocumentsData">
                      <div class="col-12">
                        <!-- <p class="m-0 h-25 black-text" style="color:#fff; font-weight:600; min-height: 20px; ">Download Available</p> -->
                      <a class="apdfs text-center " style=" border: 1px solid #fff; width:100%; border-radius: 3px;"
                         href="{{this.appService.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/{{list.ID}}" title="Click and View PDF Document"
                         target="_blank">
                        <div class="h-25 " style="color:#fff; font-weight:600; min-height: 20px; ">{{list.Name}}</div>
                      </a>
                      </div>
                    </ng-container>

                  </div>

                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class=" image-sep "
             style="background-image: url('./assets/images/road.jpg');
             height: 80px; background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div> <!-- end of container-fluid -->

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>


</main>


