import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-healthport',
  templateUrl: './healthport.component.html',
  styleUrls: ['./healthport.component.scss']
})
export class HealthportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
