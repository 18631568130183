import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppsettingsService} from '../../../services/appsettings.service';
import {Observable} from 'rxjs/Observable';
import {LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-elands-marathan2023',
  templateUrl: './elands-marathan2023.component.html',
  styleUrls: ['./elands-marathan2023.component.scss']
})
export class ElandsMarathan2023Component implements OnInit {
  configUrl: any;
  authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

  public apiContent: any = '';
  public closeResult = '';
  totalNumberOfItems = 0;
  paginatorPagesize: any = 5;
  greaterthan = 0;
  smallerThan = 4;
  imageCounter = 1;
  public imageListVar = '/assets/elands2023/smaller/1.jpg';
  public imageListVarHighResolution = '/assets/elands2023/1.jpg';

  constructor(
      private http: HttpClient,
      private modalService: NgbModal,
      public appService: AppsettingsService
  ) { }

  ngOnInit(): void {
    this.appService.loadAppConfig().subscribe((response: any) => {
      this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getPressReleaseData/3/1';
      this.getPressRelease().subscribe((data:any) => {
        console.log(data);
        this.apiContent = data;
        this.totalNumberOfItems = this.apiContent.body.Data.length;

        //  console.log(this.totalNumberOfItems);
      });
    });
  }

  getPressRelease(): Observable<HttpResponse<any[]>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });

    return this.http.get<any[]>(this.configUrl, {observe: 'response', headers: reqHeader});

  }

  getNext($event: PageEvent) {
    // console.log($event);

    this.smallerThan = ($event.pageIndex + 1) * $event.pageSize - 1;
    this.greaterthan = ($event.pageIndex + 1) * $event.pageSize - $event.pageSize;

    this.getPressRelease().subscribe(data => {

      this.apiContent = data;
      this.totalNumberOfItems = this.apiContent.body.Data.length - 1;
      // console.log(this.totalNumberOfItems);
    });
  }

  public open(content: any): void {

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public nextImage(): void {

    if (this.imageCounter <= 16) {
      this.imageCounter++;
      this.imageListVar = '/assets/elands2023/smaller/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 1;
      this.imageListVar = '/assets/elands2023/smaller/' + this.imageCounter + '.jpg';
    }
  }

  public previousImage(): void {

    if (this.imageCounter >= 2) {
      this.imageCounter--;
      this.imageListVar = '/assets/elands2023/smaller/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 17;
      this.imageListVar = '/assets/elands2023/smaller/' + this.imageCounter + '.jpg';
    }
  }

  public highResolution(): void {
    this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';

  }

  public nextImageHighRes(): void {
    if (this.imageCounter <= 16) {
      this.imageCounter++;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 1;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    }
  }

  public previousImageRes(): void {
    if (this.imageCounter >= 2) {
      this.imageCounter--;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    } else {
      this.imageCounter = 16;
      this.imageListVarHighResolution = '/assets/elands2023/' + this.imageCounter + '.jpg';
    }
  }

}
