import {Component, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';
import {AppsettingsService} from '../../../../services/appsettings.service';

@Component({
  selector: 'app-tollplazafeesport',
  templateUrl: './tollplazafeesport.component.html',
  styleUrls: ['./tollplazafeesport.component.scss']
})
export class TollplazafeesportComponent implements OnInit {

  configUrl: any;
  authToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1laWQiOiIwNkZCREI5Ny1DRjA0LTQ5OEQtQjVFQS0yMUY0NUMwQkRCM0UiLCJ1bmlxdWVfbmFtZSI6Impmb3VyaWVAb3V0bG9vay5jb20iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL2FjY2Vzc2NvbnRyb2xzZXJ2aWNlLzIwMTAvMDcvY2xhaW1zL2lkZW50aXR5cHJvdmlkZXIiOiJBU1AuTkVUIElkZW50aXR5IiwiQXNwTmV0LklkZW50aXR5LlNlY3VyaXR5U3RhbXAiOiI2NzUzMThjOC0wNDRmLTRlN2EtYTg1OC0zYWExMzJhNGZiMmUiLCJDaGFuZ2VQYXNzd29yZCI6IkZhbHNlIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1NTI3IiwiYXVkIjoiODVFMTU4MEExNjhFNEY4OEJDMUZFQzA4RDIwMTJGQzUiLCJleHAiOjE2NTk0NTQxODAsIm5iZiI6MTYyNzkxODE4MH0.bFBthApAF_HofRR4Z6WVVzBDfdDBwryqM3VnbBR2LaU';

  public apiContent: any = '';
  public closeResult = '';


  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    public appService: AppsettingsService
  ) {

  }

  ngOnInit(): void {

    this.appService.loadAppConfig().subscribe((response: any) => {

      this.configUrl = response.apiBaseUrl + '/TracService/api/v1/trac/getTollsData';
      this.getTollsData().subscribe(data => {
        console.log(data.body);
        this.apiContent = data;
      });
    });
  }

  getTollsData(): Observable<HttpResponse<any[]>> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authToken
    });

    return this.http.get<any[]>(this.configUrl, {observe: 'response', headers: reqHeader});
  }

  public open(content: any): void {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
