import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {any} from 'codelyzer/util/function';
import {Observable, Observer} from 'rxjs';



@Injectable()
export class AppsettingsService {


  public appConfig: any;

  constructor(private http: HttpClient) {
    this.appConfig = this.loadAppConfig();

    // this.appConfig = this.loadAppConfig().asObservable();
  }

  public loadAppConfig(): Observable<any> {
    return this.http.get('/assets/config.json');
  }



  // This is an example property ... you can make it however you want.
  get apiBaseUrl(): string {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiBaseUrl;
  }
}

