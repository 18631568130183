import {Component, enableProdMode} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {LanguageSwitchService} from './services/language-switch.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {interval, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AppsettingsService} from './services/appsettings.service';
declare var gtag: any;
enableProdMode();


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbCarouselConfig, AppsettingsService]  // add NgbCarouselConfig to the component providers


})
export class AppComponent {

  title = 'N4trac';
  public isMenuCollapsed = true;
  public isEnglish: boolean | undefined;
  public logo: './assets/images/logolight.png' | string | undefined;
  // mySubscription: Subscription;
  private logoSwop = true;

  constructor(
    public langService: LanguageSwitchService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    // string path from root to current route. i.e /Root/CurrentRoute
    // route.data includes both `data` and `resolve`

    router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        // console.log(event.url.search('port'));
        if (event.url.search('port') !== -1) {
          this.langService.isEnglish = false;
        }
      });
    this.logo = './assets/images/logolight.png';
    /*
    this.mySubscription = interval(5000).subscribe((x =>{
      this.doStuff();
    }));
*/

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-FNBCQTZJDD', {
          page_path: event.urlAfterRedirects
        });
        // gtag('config', 'G-FNBCQTZJDD');
      }
    });
  }

  public doStuff(): void{
    if (this.logoSwop === true)
    {
      this.logo = './assets/images/logolight2.png';
      this.logoSwop = false;
    }else {
      this.logo = './assets/images/logolight.png';
      this.logoSwop = true;
    }

    // unsubscribe at end to only run once
    // this.failedRequestSub.unsubscribe();
  }
  setLanguage(value: string): void {

    // console.log(this.router.url);

    if (value === 'english') {
      this.langService.isEnglish = true;
      const removePortSuffix = this.router.url;

      console.log(this.router.url);
      const englishRoute = removePortSuffix.replace('port', '');
      this.router.navigate([englishRoute]);
    } else {
      console.log(this.router.url);
      const findreadmore = this.router.url;
      const match = findreadmore.indexOf('/readmore/');
      console.log(' check if the readmore part matches');
      console.log(match);
      this.langService.isEnglish = false;
      if (match === -1) {
        this.router.navigate([this.router.url + 'port']);
      } else {
        // exception for the read more pages
        console.log('HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH');
        const str = this.router.url;
        const readmorePortuguese = str.replace('readmore', 'readmoreport');
        console.log(readmorePortuguese);
        this.router.navigate([readmorePortuguese]);
      }
    }
  }
}
