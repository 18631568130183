<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col offset-2">

                    <h6 class="m-0 text-right ">TRAC Events</h6>
                    <h1 class="text-right  ">TRAC N4 Elands Marathon<br/>2023</h1>


                </div>
                <div class="col">
                    <img src="./assets/images/media/Trac%20Events%20Page%20Icon-01.png"
                         style="height: 200px; width: auto"
                         alt="Events"></div>
            </div>
        </div>
    </div>


    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; position: relative;">
                <div
                        style="position: relative; width:100%; overflow: hidden; margin:0; box-sizing: border-box;">
                    <img src="./assets/elands2023/1.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/2.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/3.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/4.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/15.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/6.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/7.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/8.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                    <img src="./assets/elands2023/9.jpg" class="img-fluid"
                         style="height: auto; width:11.1%; "/>
                </div>
                <div>
                </div>
            </div>
        </div>
    </div>
    <!--
        <div class="container-fluid">
            <div class="row  py-3  grey-gradient-background shadow">
                <div class="col">
                    <div class="container">
                        <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                            OFFICIAL EVENT BANNER 2023
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        -->
    <div class="container">
        <div class="row py-3 justify-content-center shadow-lg  rounded" style="text-align : center;">

            <div class="col-sm-6 col-md-6  ">

                <div class=" p-2 ">
                    <img src="./assets/elands2023/TRACN4-Elands-Marathon-Logo%20%20With-DateNedbank.png"
                         style="width: 400px;    "
                         class="img-fluid text-center  mx-auto d-block p-1  image-center  "
                         alt=""/>
                </div>

                <br/><br/>
                <a class="expressbutton shadow-lg" target="_blank" href="https://elandsmarathon.co.za/">
                    Enter Now</a>
            </div>


            <div class="col-sm-6 col-md-6      justify-content-center ">
                <a href="https://elandsmarathon.co.za/" class="shadow-lg" title="https://elandsmarathon.co.za/">
                    <img src="./assets/elands2023/2023%20TRAC%20Elands%20Marathon%20-%20Flyer_Print.jpg"
                         style="    "
                         class="img-fluid text-center shadow-lg  mx-auto d-block p-1  image-center  "
                         alt=""/>
                </a>
            </div>
        </div>
    </div>

    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <div class="container-fluid">
        <div class="row  py-3  shadow-lg grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        RACE PROFILE
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <div class="container">
        <div class="row justify-content-center  shadow-lg rounded" style="text-align : center;">
            <div class="col-sm-12 col-md-12      justify-content-center ">
                <div class=" p-2 ">
                    <img src="./assets/elands2023/Race%20Profile.jpg"
                         style="width: 100%;    "
                         class="img-fluid text-center  mx-auto d-block p-1  image-center  "
                         alt=""/>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row  py-3  shadow-lg grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        ALLOCATION OF PRIZE MONEY
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center  shadow-lg rounded" style="text-align : center;">
            <!--
        <div class="col-md-2     ">
            <div class=" p-2 ">

                <img src="./assets/elands2023/TRACN4-Elands-Marathon-Logo%20%20With-DateNedbank.png"
                     style="width: 400px;    "
                     class="img-fluid text-center  mx-auto d-block p-1  image-center  "
                     alt=""/>


                </div>
            </div>
     -->
            <div class="col-sm-12 col-md-12  justify-content-center  ">
                <img src="./assets/elands2023/2023%20TRAC%20Elands%20Marathon%20-%20Flyer_Print%20copy.jpg" alt=""
                     class="img-fluid text-center shadow-lg mx-auto d-block m-4  " alt=""/>
            </div>
        </div>
    </div>


    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <div class="container-fluid">
        <div class="row  py-3  shadow-lg grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        SPONSORS
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-4 mb-3 mt-2">
                <img src="./assets/images/media/TracN4.jpg" alt="" class="img-fluid text-center mx-auto d-block p-3">
            </div>
            <div class="col-sm-12 col-md-4 mb-3 mt-2  mx-auto">
                <img src="./assets/images/elandsMarathon/Buscor-logo.JPG" alt="MRF"
                     class="img-fluid text-center mx-auto d-block p-5">
            </div>

            <div class="col-sm-12 col-md-4 text-center">
                <img src="./assets/images/elandsMarathon/RISE-FM.png" alt=""
                     class="img-fluid text-center mx-auto d-block p-3">
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-sm-12 col-md-3 ">
                <img src="./assets/images/elandsMarathon/Millys%20Logo.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-5 p-1">
            </div>
            <div class="col-sm-12 col-md-3  ">
                <img src="./assets/images/elandsMarathon/Comrades-Logo.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block p-1 ">
            </div>
            <div class="col-sm-12 col-md-3 text-center">
                <img src="./assets/images/elandsMarathon/nedbank-logo-without-AMPU.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-3 ">
            </div>
            <div class="col-sm-12 col-md-3 text-center">
                <img src="./assets/images/elandsMarathon/CALTEX_3D_SILVER_LOGO_CMYK.jpg" alt="" style="max-width:100% ;"
                     class="img-fluid text-center mx-auto d-block mt-5 p-1">
            </div>

        </div>
    </div>
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->


    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <div class="container-fluid">
        <div class="row  py-3  shadow-lg grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        PRESS RELEASES
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row py-4" *ngIf="this.apiContent !== ''">
            <div class="col-sm-12 col-md-12">
                <mat-tab-group dynamicHeight>
                    <mat-tab label="2023 Press Releases">
                        <div class="mat-elevation-z4">
                            <!-- --------------- 2022 ------------------------------------------ -->
                            <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
                                <ng-container *ngIf="o.Date > '2023-01-01'" ?>

                                    <div class="col-sm-12 col-md-12 py-4" *ngIf="i >= greaterthan && i <= smallerThan">
                                        <div class="post-title"><span
                                                class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
                                            <h2 class="posttitle mb-0">{{o.Name}}</h2></div>
                                        <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
                                            <a class="h6" routerLink="/readmoreMarathonport/{{o.ID}}/{{i}}">Read More</a></div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="totalNumberOfItems >= 6">
                                <mat-paginator [length]="totalNumberOfItems" class=""
                                               [pageSize]="paginatorPagesize"
                                               [pageSizeOptions]="[5, 10, 25]"
                                               aria-label="Select page"
                                               (page)="getNext($event)">
                                </mat-paginator>
                            </ng-container>
                            <ng-container *ngIf="totalNumberOfItems === 0">
                                <div class="col-sm-12 col-md-12 py-4">
                                    <div class="text-center h4 ">No current press releases.
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </mat-tab>
                    <mat-tab label="2022 Press Releases">
                        <div class="mat-elevation-z4" style="min-height: 420px; width: 100%;">
                            <div class="mat-elevation-z4">
                                <!-- --------------- 2022 ------------------------------------------ -->
                                <ng-container *ngFor="let o of this.apiContent.body.Data;  index as i ">
                                    <div class="col-sm-12 col-md-12 py-4" *ngIf="i >= greaterthan && i <= smallerThan">
                                        <div class="post-title"><span
                                                class="posttitledate mr-2">{{o.Date | date:'dd MMM Y'}}</span>
                                            <h2 class="posttitle mb-0">{{o.Name}}</h2></div>
                                        <div class="post-summary"><p class="float-left w-100">{{o.Introduction}}</p>
                                            <a class="h6" routerLink="/readmoreMarathonport/{{o.ID}}/{{i}}">Read More</a></div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="totalNumberOfItems >= 6">
                                    <mat-paginator [length]="totalNumberOfItems" class=""
                                                   [pageSize]="paginatorPagesize"
                                                   [pageSizeOptions]="[5, 10, 25]"
                                                   aria-label="Select page"
                                                   (page)="getNext($event)">
                                    </mat-paginator>
                                </ng-container>
                                <ng-container *ngIf="totalNumberOfItems === 0">
                                    <div class="col-sm-12 col-md-12 py-4">
                                        <div class="text-center h4 ">No current press releases.
                                        </div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>


    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->
    <!-- ---------------------------------------------------------------------------- -->


    <!-- ---------------------------------------------------------------------------- -->
    <!-- LAST YEAR youtube video---------------------------------------------------------------------------- -->
    <div class="container-fluid">
        <div class="row  py-3  grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        TRAC N4 Elands Marathon 2022
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row d-flex py-4 shadow-lg">
            <div class="col-sm-12 col-md-12">
                <div class="mt-5">
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe src="https://www.youtube.com/embed/AuhRtI9VNE8"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container-fluid">
        <div class="row  py-3  grey-gradient-background shadow">
            <div class="col">
                <div class="container">
                    <h3 class="text-white my-2  text-center text-uppercase lineHeight166">
                        2022 Marathon Gallery
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="container dirt shadow-sm">
        <div class="row  dirt shadow">
            <div class="col-sm-12 col-md-12 ">
                <h5 class="text-center mt-1">
                    <span class="hide-on-small-screen"></span> {{imageCounter}} of 17
                </h5>
                <div class="dot-divider mb-3"><span class="dot-divider-black-dynamic"> </span>
                </div>
            </div>


            <div class="col-sm-12 ">
                <img [src]="imageListVar" style=" height: auto; max-height: 450px;"
                     class="img-fluid d-block mx-auto rounded" alt="Dullstroom Rally image"/>
                <br/><br/>
            </div>

        </div>
        <div class="row  dirt ">
            <div class="col-sm-2 ">
            </div>
            <div class="col-sm-8">
                <div>
                    <button
                            class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                            (click)="previousImage()" style="width:50%;border-right: 2px solid #fff;">Previous Photo
                    </button>
                    <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2" (click)="nextImage()"
                            style="width:50%;">Next Photo
                    </button>
                </div>
            </div>
            <div class="col-sm-2">
            </div>

            <div class="col-sm-12 col-md-4  ">
                &nbsp;
            </div>
            <div class="col-sm-12 col-md-4  text-center"
                 style="border-top:2px solid rgba(255,255,255,0.25); border-bottom:2px solid rgba(255,255,255,0.25);">
                <br/>
                <button class="btn black-text  bg-yellow fontsize13m text-center "
                        (click)="open(content4); highResolution()">
                    <span class="photo-highresolution-Button"></span>
                </button>
                &nbsp; <br/>
            </div>
            <br/><br/>

        </div>
        <br/>
    </div>

    <!-- ---------------------------------------------------------------------------- -->


    <!--
    <div class="container">
        <div class="row">
            <button class="btn-outline-"><a routerLink="/traceventsElandsMarathon">
                Trac N4 Elands Marathon 2022</a>
            </button>
        </div>
    </div>
    -->
</main>


<ng-template #content4 let-modal>
    <div class="modal-header">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-6">
                    <h4 class="modal-title" id="modal-basic-title4">Rally 2022 Gallery - Displaying
                        Photo {{imageCounter}} of 167</h4>

                </div>
                <div class="col-sm-6">
                    <div>
                        <button
                                class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="previousImageRes()" style="width:50%;border-right: 2px solid #fff;">Previous
                        </button>
                        <button class="btn black-text shadow bg-yellow fontsize13m text-center p-2"
                                (click)="nextImageHighRes()" style="width:50%;">Next
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <img [src]="imageListVarHighResolution"
             class="img-fluid"
             style=" width: 100%; max-width:100%; height:auto; "/>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
    </div>
</ng-template>
