<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right text-white"></h6>
          <h1 class="text-right h1defintion">REQUISITOS DE<br/>
            CONCESSÃO</h1></div>
        <div class="col"><img src="./assets/images/plaza/Tolls_PlazaBanners_ConcessionRequiremnets.png"
                              style="max-height: 200px; width: auto"    alt="CONCESSION REQUIREMENTS"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <h6 class="text-center bold pb-2">ABAIXO POR FAVOR ENCONTRE OS REQUISITOS MAIS RECENTES<br/>
          PARA A CONCESSÃO</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>

    <!--    Category: "Concession Requirements"-->
    <!--    Date: "2021-07-12T00:00:00"-->
    <!--    Description: "DIAMOND HILL PLAZA"-->
    <!--    FileName: "Diamond Hill TP Concession Requirements July 2020.pdf"-->
    <!--    ID: 30-->
    <!--    Name: "DIAMOND HILL PLAZA"-->

    <div class="row py-4"  *ngIf="apiContent != ''" >
      <ng-container *ngFor="let o of this.apiContent.body.Data">
        <div class="col-sm-12 col-md-3 text-center mb-5 ">
          <a class=" text-center " style=" border: 1px solid #fff;"
             href="{{this.appService.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/{{o.ID}}" title="Click and View PDF Document"
             target="_blank">
            <img src="./assets/images/plaza/Tolls_PlazaBannersIcons_AboutTrac.png" class="p-3" alt="Location">
            <h5 class="mt-3" style="color:#000000; font-weight:600;">{{o.Name}}</h5>
          </a>
        </div>
      </ng-container>

    </div>
  </div>

  <!-- Dolf request
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <h6 class="text-center bold pb-2">Follow link below for Online Account Application</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
        <a href="http://172.20.1.38/WebForms/Operations/Road%20User/AccountApplication.aspx">Online Account Application</a>
      </div>
    </div>
  </div>


   ------------------------- -->
  <!-- ------------------------- -->
  <!-- ------------------------- -->

  <div class="container-fluid">
    <div class="row py-4 bg-yellow">
      <div class="col-sm-12 col-md-12"><h6 class="text-center text-uppercase bold">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="" class="row py-4">
      <div class="col-sm-12 col-md-6 text-center">
        <a class="actionlink" href="#" routerLink="/plazafeesport">
          <div class="cta-img">
            <img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac_7.png" class="p-5 img-fluid" style="max-height:300px; "
                 alt="Toll Red">
            <h3 class="mt-3 mb-0 black-text">PORTAGENS
              E TARIFAS</h3>
         </div>
          <div class="cta-content hide bg-black"><h3 class="text-center">PORTAGENS E TARIFAS</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center">DOWNLOAD</h3></div>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 text-center">
        <a class="actionlink" href="#" routerLink="/plazawhytollport">
          <div class="cta-img">
            <img src="./assets/images/plaza/Tolls_PlazaBannersIcons_WhyToll.png" class="p-5 img-fluid" style="max-height:300px; "
                 alt="Toll Yellow">
            <h3 class="mt-3 mb-0 black-text" >BENEFICIOS DE UMA
              ESTRADA COM
              PORTAGENS</h3>
           </div>
          <div class="cta-content hide bg-black"><h3 class="text-center">BENEFICIOS DE UMA ESTRADA<br/>
            COM PORTAGENS</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center">BENEFICIOS DE</h3></div>
        </a></div>

    </div>
  </div>
</main>
