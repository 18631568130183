import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainlayoutComponent } from './components/mainlayout/mainlayout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './components/footer/footer.component';
import { SwiperModule } from 'swiper/angular';

import { MapComponent } from './components/map/map.component';
import { AboutCompanyProfileComponent } from './components/about-company-profile/about-company-profile.component';
import { AboutHistoryComponent } from './components/about-history/about-history.component';
import { AboutAntiCorruptionPolicyComponent } from './components/about-anti-corruption-policy/about-anti-corruption-policy.component';
import { HomeComponent } from './components/home/home.component';
import { ConstructionComponent } from './components/tenders/construction/construction.component';
import { RoutineComponent } from './components/tenders/routine/routine.component';
import { TenderresultsComponent } from './components/tenders/tenderresults/tenderresults.component';
import { JointheteamComponent } from './components/jointheteam/jointheteam.component';
import { WhytollComponent } from './components/plaza/whytoll/whytoll.component';
import { TollplazafeesComponent } from './components/plaza/tollplazafees/plazafees';
// import { TollplazaAndfeesComponent } from './components/plaza/toll-plaza-and-fees/toll-plaza-and-fees';
import { ConcessionrequirementsComponent } from './components/plaza/concessionrequirements/concessionrequirements.component';
import { LoadcontrolComponent } from './components/loadcontrol/loadcontrol/loadcontrol.component';
import { LoadcontrolcenterComponent } from './components/loadcontrol/loadcontrolcenter/loadcontrolcenter.component';
import { RoadsafetyComponent } from './components/roadsafety/roadsafety.component';
import { EducationComponent } from './components/csi/education/education.component';
import { EnvironmentComponent } from './components/csi/environment/environment.component';
import { HealthComponent } from './components/csi/health/health.component';
import { MaputoComponent } from './components/csi/maputo/maputo.component';
import { SmmeComponent } from './components/csi/smme/smme.component';
import { SportsandcultureComponent } from './components/csi/sportsandculture/sportsandculture.component';
import { ContactComponent } from './components/contact/contact.component';
import { CsiroadsafetyComponent } from './components/csi/csiroadsafety/csiroadsafety.component';
import { TrackeventsComponent } from './components/media/trackevents/trackevents.component';
import { PressreleasesComponent } from './components/media/pressreleases/pressreleases.component';
import { LiverouteComponent} from './components/liveroute/liveroute.component';
import { ReadmoreComponent } from './components/media/readmore/readmore.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {ReactiveFormsModule} from '@angular/forms';
import { OpendocumentComponent } from './components/opendocument/opendocument.component';
import { HomeportComponent } from './components/home/homeport/homeport.component';
import { AboutAntiCorruptionPolicyportComponent } from './components/about-anti-corruption-policy/about-anti-corruption-policyport/about-anti-corruption-policyport.component';
import { AboutCompanyProfileportComponent } from './components/about-company-profile/about-company-profileport/about-company-profileport.component';
import { AboutHistoryportComponent } from './components/about-history/about-historyport/about-historyport.component';
import { ContactportComponent } from './components/contact/contactport/contactport.component';
import { CsiroadsafetyportComponent } from './components/csi/csiroadsafety/csiroadsafetyport/csiroadsafetyport.component';
import { EducationportComponent } from './components/csi/education/educationport/educationport.component';
import { EnvironmentportComponent } from './components/csi/environment/environmentport/environmentport.component';
import { HealthportComponent } from './components/csi/health/healthport/healthport.component';
import { MaputoportComponent } from './components/csi/maputo/maputoport/maputoport.component';
import { SmmeportComponent } from './components/csi/smme/smmeport/smmeport.component';
import { SportsandcultureportComponent } from './components/csi/sportsandculture/sportsandcultureport/sportsandcultureport.component';
import { JointheteamportComponent } from './components/jointheteam/jointheteamport/jointheteamport.component';
import { LiverouteportComponent } from './components/liveroute/liverouteport/liverouteport.component';
import { LoadcontrolportComponent } from './components/loadcontrol/loadcontrol/loadcontrolport/loadcontrolport.component';
import { LoadcontrolcenterportComponent } from './components/loadcontrol/loadcontrolcenter/loadcontrolcenterport/loadcontrolcenterport.component';
import { PressreleasesportComponent } from './components/media/pressreleases/pressreleasesport/pressreleasesport.component';
import { ReadmoreportComponent } from './components/media/readmore/readmoreport/readmoreport.component';
import { TrackeventsportComponent } from './components/media/trackevents/trackeventsport/trackeventsport.component';
import { ConcessionrequirementsportComponent } from './components/plaza/concessionrequirements/concessionrequirementsport/concessionrequirementsport.component';
import { TollplazafeesportComponent } from './components/plaza/tollplazafees/tollplazafeesport/tollplazafeesport.component';
import { WhytollportComponent } from './components/plaza/whytoll/whytollport/whytollport.component';
import { RoadsafetyportComponent } from './components/roadsafety/roadsafetyport/roadsafetyport.component';
import { ConstructionportComponent } from './components/tenders/construction/constructionport/constructionport.component';
import { RoutineportComponent } from './components/tenders/routine/routineport/routineport.component';
import { TenderresultsportComponent } from './components/tenders/tenderresults/tenderresultsport/tenderresultsport.component';
import { ReadmoretrackeventComponent } from './components/media/readmoretrackevent/readmoretrackevent.component';
import { ReadmoretrackeventportComponent } from './components/media/readmoretrackeventport/readmoretrackeventport.component';
import {AppsettingsService} from './services/appsettings.service';
import {DomSanitizerService} from './services/dom-sanitizer.service';
import {SafePipe} from './services/safe.pipe';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { TrackeventsElandsMarathonComponent } from './components/media/trackevents-elands-marathon/trackevents-elands-marathon.component';
import { TrackeventsElandsMarathonportComponent } from './components/media/trackevents-elands-marathonport/trackevents-elands-marathonport.component';
import { SafeHtmlPipe } from './services/safe-html.pipe';
import { ElandsMarathan2023Component } from './components/media/elands-marathan2023/elands-marathan2023.component';
import { ElandsMarathon2023portComponent } from './components/media/elands-marathon2023port/elands-marathon2023port.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ReadmoreMarathonComponent } from './components/media/readmore-marathon/readmore-marathon.component';
import { ReadmoreMarathonportComponent } from './components/media/readmore-marathonport/readmore-marathonport.component';
import { ElandsMarathon2024Component } from './components/media/elands-marathon2024/elands-marathon2024.component';
import { GetATagComponent } from './components/get-a-tag/get-a-tag.component';
import { GalleryRally2024 } from './components/gallery/rally-2024/rally2024.component';

@NgModule({
  declarations: [
    AppComponent,
    MainlayoutComponent,
    FooterComponent,
    MapComponent,
    AboutCompanyProfileComponent,
    AboutHistoryComponent,
    AboutAntiCorruptionPolicyComponent,
    HomeComponent,
    ConstructionComponent,
    RoutineComponent,
    TenderresultsComponent,
    JointheteamComponent,
    WhytollComponent,
    TollplazafeesComponent,
    // TollplazaAndfeesComponent,
    ConcessionrequirementsComponent,
    LoadcontrolComponent,
    LoadcontrolcenterComponent,
    RoadsafetyComponent,
    EducationComponent,
    EnvironmentComponent,
    HealthComponent,
    MaputoComponent,
    SmmeComponent,
    SportsandcultureComponent,
    ContactComponent,
    CsiroadsafetyComponent,
    TrackeventsComponent,
    PressreleasesComponent,
    LiverouteComponent,
    ReadmoreComponent,
    OpendocumentComponent,
    HomeportComponent,
    AboutAntiCorruptionPolicyportComponent,
    AboutCompanyProfileportComponent,
    AboutHistoryportComponent,
    ContactportComponent,
    CsiroadsafetyportComponent,
    EducationportComponent,
    EnvironmentportComponent,
    HealthportComponent,
    MaputoportComponent,
    SmmeportComponent,
    SportsandcultureportComponent,
    JointheteamportComponent,
    LiverouteportComponent,
    LoadcontrolportComponent,
    LoadcontrolcenterportComponent,
    PressreleasesportComponent,
    ReadmoreportComponent,
    TrackeventsportComponent,
    ConcessionrequirementsportComponent,
    TollplazafeesportComponent,
    WhytollportComponent,
    RoadsafetyportComponent,
    ConstructionportComponent,
    RoutineportComponent,
    TenderresultsportComponent,
    ReadmoretrackeventComponent,
    ReadmoretrackeventportComponent,
    DomSanitizerService,
    SafePipe,
    TrackeventsElandsMarathonComponent,
    TrackeventsElandsMarathonportComponent,
    SafeHtmlPipe,
    ElandsMarathan2023Component,
    ElandsMarathon2023portComponent,
    ReadmoreMarathonComponent,
    ReadmoreMarathonportComponent,
    ElandsMarathon2024Component,
    GetATagComponent,
    GalleryRally2024
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        SwiperModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        ReCaptchaModule.forRoot({
            invisible: {
                sitekey: '6LfcZMkcAAAAAGFc9mmnf9AHZTjKnYvarQfxUUxl',
            },
            normal: {
                sitekey: '6LfcZMkcAAAAAGFc9mmnf9AHZTjKnYvarQfxUUxl',
            },
            language: 'en'
        }),
        MatTabsModule
    ],
  providers: [AppsettingsService],
  bootstrap: [AppComponent],
})

export class AppModule {

}
