<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right text-white"></h6>
          <h1 class="text-right h1defintion">CONCESSION REQUIREMENTS</h1></div>
        <div class="col"><img src="./assets/images/plaza/Tolls_PlazaBanners_ConcessionRequiremnets.png"
                              style="max-height: 200px; width: auto"     alt="CONCESSION REQUIREMENTS"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <h6 class="text-center bold pb-2">DOWNLOAD THE LATEST CONCESSION REQUIREMENTS BELOW</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>

<!--    Category: "Concession Requirements"-->
<!--    Date: "2021-07-12T00:00:00"-->
<!--    Description: "DIAMOND HILL PLAZA"-->
<!--    FileName: "Diamond Hill TP Concession Requirements July 2020.pdf"-->
<!--    ID: 30-->
<!--    Name: "DIAMOND HILL PLAZA"-->

    <div class="row py-4"  *ngIf="apiContent != ''" >
      <ng-container *ngFor="let o of this.apiContent.body.Data">
      <div class="col-sm-12 col-md-3 text-center mb-5 ">
        <a class=" text-center " style=" border: 1px solid #fff;"
           href="{{appService.apiBaseUrl}}/TracService/api/v1/document/OpenDocument/{{o.ID}}" title="Click and View PDF Document"
           target="_blank">
        <img src="./assets/images/plaza/Tolls_PlazaBannersIcons_AboutTrac.png" class="p-3" alt="Location">
        <h5 class="mt-3" style="color:#000000; font-weight:600;">{{o.Name}}</h5>
        </a>
      </div>
      </ng-container>
    </div>
  </div>

  <!-- Dolf request
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12">
        <h6 class="text-center bold pb-2">Follow link below for Online Account Application</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
        <a href="http://172.20.1.38/WebForms/Operations/Road%20User/AccountApplication.aspx">Online Account Application</a>
      </div>
    </div>
  </div>


   ------------------------- -->
  <!-- ------------------------- -->
  <!-- ------------------------- -->


  <div class="container-fluid">
    <div class="row py-4 bg-yellow">
      <div class="col-sm-12 col-md-12"><h6 class="text-center text-uppercase bold">Related Links</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div id="" class="row py-4">
      <div class="col-sm-12 col-md-6 text-center">
        <a class="actionlink" href="#" routerLink="/plazafees">
        <div class="cta-img">
          <img src="./assets/images/plaza/Tolls%20_%20Plaza%20Banners%20Icons_About%20Trac_7.png" class="p-5 img-fluid" style="max-height:300px; "
               alt="Toll Red">
          <h5 class="mt-3 mb-0 black-text">TOLL PLAZAS<br/>&amp; TOLL FEES</h5>
          <div class="m-0 black-text text-uppercase" >Find out more</div></div>
        <div class="cta-content hide bg-black"><h3 class="text-center">TOLL PLAZAS &amp;TOLL FEES</h3>
          <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
          <h3 class="text-center">DOWNLOADABLE</h3></div>
      </a>
      </div>
      <div class="col-sm-12 col-md-6 text-center">
        <a class="actionlink" href="#" routerLink="/plazawhytoll">
        <div class="cta-img">
          <img src="./assets/images/plaza/Tolls_PlazaBannersIcons_WhyToll.png" class="p-5 img-fluid" style="max-height:300px; "
               alt="Toll Yellow">
          <h5 class="mt-3 mb-0 black-text" >WHY TOLL?</h5>
          <div class="m-0 black-text text-uppercase">Why and how toll roads work for you</div></div>
        <div class="cta-content hide bg-black"><h3 class="text-center">WHY TOLL?</h3>
          <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
          <h3 class="text-center">FIND OUT MORE</h3></div>
      </a></div>

    </div>
  </div>

</main>
