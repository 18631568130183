<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right bold"></h6>
          <h1 class="text-right ">SAÚDE E
            BENIFICÊNCIA</h1></div>
        <div class="col"><img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Banner_1.png" alt="Health"
                              class="img-fluid" style="max-height: 180px;"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12 p-0" style="height: auto; background-color: #0b2e13; margin-bottom:12.5%;">
        <div
          style="position: absolute; width:100%; overflow: hidden; height: 25vh; margin:0; box-sizing: border-box;">
          <img src="./assets/images/about/Company%20Profile%20Carousel_1.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_2.jpg" class="img-fluid img-runner"/>
          <!-- <img src="./assets/images/about/Company%20Profile%20Carousel_3.jpg" class="img-fluid" style="height: auto; width:11.1%; "/>
          -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_4.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_5.jpg" class="img-fluid img-runner"/>
          <!--
          <img src="./assets/images/about/Company%20Profile%20Carousel_6.jpg" class="img-fluid"
               style="height: auto; width:11.1%; "/>
               -->
          <img src="./assets/images/about/Company%20Profile%20Carousel_7.jpg" class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_8.jpg"class="img-fluid img-runner"/>
          <img src="./assets/images/about/Company%20Profile%20Carousel_9.jpg" class="img-fluid img-runner"/>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-12 text-center bold"><p>A saúde e benificência das comunidades ao longo dos 570 Km Rota com Portagem é da maior importância para a TRAC. Desde
        projectos de cuidados na saúde, aos projectos que estão especificamente focados na assistência aos idosos, às iniciativas que têm
        como alvo a angariação de fundos e dar a conhecer acerca de alguma epidemia ou doença prominente, a TRAC está em todas,
        te muito mais.</p></div>
    </div>
  </div>

  <div class="container-fluid "
       style="background-image: url('/assets/images/csiHealth/Bikers-29.jpg'); background-size: cover; background-position: center;  ">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>        </div>
      </div>
    </div>
  </div>


  <div class="container-fluid bg-yellow shadow">
    <div class="row py-4">

      <div class="col-sm-12 col-md-12"><h6 class="text-center text-black">OS PROJECTOS E EVENTOS SEGUINTES FAZEM PARTE DO INVESTIMENTO SOCIAL DA TRAC:</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_KUPHILA%20CLINIC.png" alt=""  class="img-fluid imgsize"/>
        <h6>KUPHILA CLINIC</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_CANSA%20RELAY%20FOR%20LIFE.png" alt="" class="img-fluid imgsize"/>
        <h6>CANSA RELAY FOR
          LIFE</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_ASSISTANCE%20TO%20THE%20ELDERLY.png" alt=""  class="img-fluid imgsize"/>
        <h6>ASSISTANCE TO THE
          ELDERLY</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_SPCA.png" alt="" class="img-fluid imgsize"/>
        <h6>SPCA</h6>
      </div>
      <div class="col-sm-12 col-md-2 text-center">
        <img src="./assets/images/csiHealth/HEALTH%20&%20WELFARE%20Icons_MANDELA%20DAY.png" alt="" class="img-fluid imgsize"/>
        <h6>MANDELA DAY</h6>
      </div>

    </div>
  </div>
  <div class="container-fluid grey-gradient-background">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 white-text">
        <h6 class="text-center">LINKS RELACIONADOS</h6>
        <div class="dot-divider mb-3"><span class="dot-divider-white"></span></div>
      </div>
    </div>
  </div>
  <div class="container-fluid "
       style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-size: cover;  ">
    <div id="csirelated" class="row py-4">
      <div class="col-sm-12 col-md-12">
        <div class="container-fluid">
          <div class="row py-4">
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/environmentport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Envirtoment.png" alt="Heart Green"  class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">MEIO AMBIENTE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/educationport">
                <div class="cta-img"><img
                  src="./assets/images/csiHealth/CSI%20Icons_Education.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">EDUCAÇÃO E DESENVOLVIMENTO DE CONHECIMENTOS</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->

             <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/maputoport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Maputo%20Corridor.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">TRAC EM MOÇAMBIQUE</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/csiroadsafetyport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Roadsaftey.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">SEGURANÇA RODOVIÁRIA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/smmeport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_SMME%20Development.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESENVOLVIMENTO SOCIAL
                    E ECONÔMICO</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
            <div class="col-sm-12 col-md-2 text-center">
              <a href="" routerLink="/sprtsandcultureport">
                <div class="cta-img"><img
                  src="./assets/images/csiEducation/CSI%20Icons_Sports%20And%20Culture.png" alt="Heart Green" class="img-fluid imgsizeCircles"/>
                  <h3 class="mt-3 mb-0 ">DESPORTO E CULTURA</h3>
                </div>
              </a>
            </div> <!-- **************** **************** ************************************************************* -->
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
