import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadcontrol',
  templateUrl: './loadcontrol.component.html',
  styleUrls: ['./loadcontrol.component.scss']
})
export class LoadcontrolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
