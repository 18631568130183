<mat-progress-bar mode="indeterminate"></mat-progress-bar>
<main id="main" class="about-template">
  <div id="posts">
    <div class="container">
      <article id="post">
        <header>
          <div class="postmaintitle-holder" *ngIf="this.PageDataFromArticle !== ''">
            <h1 class="mt-5 black-text">{{this.PageDataFromArticle.Name}}</h1>
            <span class="greenseparator small left"></span></div>
          <div class="post-title mt-5">
            <span class="posttitledate mr-2">{{this.PageDataFromArticle.Date | date:'dd MMMM'}}</span>
            <h2 class="posttitle mb-0"> - {{this.PageDataFromArticle.Name}}</h2>
            <div class="meta w-100 float-left"><span class="author">Administrator - </span>&nbsp;
              <time class="date mr-2" datetime="">
                {{this.PageDataFromArticle.Date | date:'dd MMMM YYYY'}}
              </time>
              <!--   <span class="category">—<a href="#" routerLink="/construction">Construction Projects</a></span> -->
              <div class="tags"></div>
              <!--
              <a class="facebook"
                 href="http://www.facebook.com/sharer.php?u=https://tracn4.co.za/posts/kaapmuiden-kaalrug-project-almost-complete"
                 title="Share on Facebook." target="_blank"><i class="fab fa-facebook-f mr-1"></i></a><a
                href="https://twitter.com/intent/tweet?text=Kaapmuiden - Kaalrug project almost completehttps://tracn4.co.za/posts/kaapmuiden-kaalrug-project-almost-complete"
                title="Share on Twitter." target="_blank"><i class="fab fa-twitter mr-1"></i></a>
                -->
            </div>
          </div>
        </header>
        <br>
        <div class="" *ngIf="this.apiContent !== ''">

          <div class="content" [innerHTML]="this.PageData">

          </div>

        </div>

      </article>
    </div>
  </div>
</main>
