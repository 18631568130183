<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow">
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2"><h6 class="m-0 text-right "></h6>
          <h1 class="text-right ">CONTROLO DE CARGA</h1></div>
        <div class="col"><img src="./assets/images/loadcontrol/LOAD%20CONTROL%20Icon-01.png"
                              alt="About Us"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4 d-flex align-items-center">
      <div class="col-sm-12"><h5 class="text-center text-black">PROTEGENDO A INFRAESTRUTURA</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
        <p>Veiculos sobrecarregados cusam danos extensivos ás estradas todos os anos na Africa do Sul. Estes danos
          compromete a segurança de todos os utentes da estrada, e fazem escalar os custos de manutenção.</p>
        <p>Aos primeiros dias do inicio da concessão foram conduzidos estudos ao longo da N4 Corredor do Maputo Rota com
          Portagem indicando que mais de um terço das viaturas que percorriam a rota estavam sobrecarregados. Esta
          realidade despertou a TRAC para que sem demora fosse introduzido um acordo de operações com a SANRAL numa
          tentativa de solucionar este dilema. Apesar da TRAC não ter direitos na aplicação da lei tem no entanto poder
          de executar um papel essencial na redução de sobrecargas pelo facto de gerir sete (7) BÁSCULAS na RSA, e três
          (3) em Moçambique e onze (11)centros de acostamento ao longo da rota. Os oficiais de trânsito estacionados
          nestas instalações estão aptos a realizar o seu papel, que é o de fazer cumprir a lei. Desde a introdução das
          CCP (Centros de Contolo de Peso) terão sido pesados para cima de 10 milhões de viaturas. As sobrecargas têm
          reduzido significativamente na N4 Rota com Portagem, as viaturas que foram alvo de pesagem nos CCP no lado da
          secção Sul Africana da rota, confirmou que 21% das viaturas, tendo em conta número médio de tráfego de pesados
          diário, transitam em sobrecarga, menos de dois (2%) por cento destes encontram-se ilegalmente sobrecarregados,
          (acima do limite da boa-vontade).</p></div>
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class="py-6 image-sep"
             style="background-image: url('/assets/images/csiEducation/road-1.jpg'); background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6">
        <div id="block--14" class="block block--wysiwyg_block">
          <div class="block__content"><h5 class="text-center text-black">CONSOLIDAR A NORMA</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <p>Desde a assinatura do contracto dos CCP, com a SANRAL e ANE, a TRAC tem vindo a consolidar a norma
              referente à inovação e tecnologia dos CCP. Em 2007 a SANRAL e a TRAC implementaram a jóia-da-coroa no que
              se trata de tecnologia no controlo de carga e tracking por satélite, no Departamento de Controlo de
              Tráfego de Donkerhoek (TCC) na N4 Rota com Portagem, pelo qual as viaturas pesadas na N4 Rota com
              Portagem, e rotas alternativas entre Tshwane and Bronkhorstspruit, são descortinados para possíveis
              sobrecargas enquato em andamento, ao passarem por dispositivos–de–pesagem enquanto em movimento, colocados
              na estrada, conhecidos por (WIM).</p>
            <p>0s dispositivos WIM são agora norma em toda a N4 Rota com Portagem, desenpenhando um papel muito
              significativo ao restringir as sobrecargas na estrada nacional.</p>
            <p>Em 2015 a TRAC estabeleceu uma báscula vanguardista – a Pessene CCP em Moçambique. Este é sem sombra de
              dúvidas o CCP mais excepcional da TRAC. Necessita apenas de energia solar para funcionar de forma
              independente, não dependendo da Grelha de Energia Moçambicana. Para além disso tem uma via dedicada a
              viaturas pesadas o que assegura que estas viaturas são automáticamente dirigidas para a báscula. Outra
              característica da Pessence é o sistema de Controlo Biométrico (o scanar da impressão digital), este
              sistema assegura que apenas as p[-essoas registadas no sistema podem pesar e libertar a viatura.</p></div>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="block--15" class="block block--wysiwyg_block">
          <div class="block__content"><h5 class="text-center text-black">FACTOS DE SOBRECARGA</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
            <ul>
              <li><span class="uavc-list-desc ult-responsive"><p>Viaturas sobrecarregadas causam enormes danos ao piso da estrada por um factor que nasce expontâneamente como o aumento da carga na estrada nacional.</p></span>
              </li>
              <li><span class="Defaults-check"></span><span class="uavc-list-desc ult-responsive">Danos nas estradas por via de sobrecarga obrigam a manutenção e reparações mais aturadas, obrigando por isso a custos muito mais elevados, e também a curta duração dessas mesmo estradas. Isto coloca uma carga adicional ao Estado como também ao utentes cumpridores dos seus deveres e leis que têm de suportar os custos involvidos, criados por aqueles que sobrecarregam as suas viaturas.</span>
              </li>
              <li><span class="Defaults-check"></span><span class="uavc-list-desc ult-responsive">Viaturas sobrecarregadas provocam uma ameaça de segurança. As viaturas sobrecarregadas necessitam de maior distância para travagens, geram forças centrífugas muito altas nas curvas, deslocando cargas ou perda de aderência que provoca um risco de segurança instantâneo. Os pneus de viaturas em sobrecarga tem uma duração de vida curta, o aumento de rebentamento, a redução de controlo por parte do condutor em estabilizar a viatura escala a possibilidade da ocorrência de um acidente.</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-yellow">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12"><h5 class="text-center text-uppercase ">LINKS RELACIONADOS</h5>
        <div class="dot-divider mb-3"><span class="dot-divider-black"></span></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-12 col-md-12 text-center">
        <a class="actionlink" href="" routerLink="/loadcontrolcenterport">
          <div class="cta-img">
            <img src="./assets/images/loadcontrol/phone.png" alt="Location Green">
            <h5 class="mt-3 mb-0 black-text">CONTACTE UM CCP</h5><h6
            class="m-0 black-text"></h6></div>
          <div class="cta-content hide bg-black"><h3 class="text-center">Contacte um CCP</h3>
            <div class="dot-divider mb-3"><span class="dot-divider-white"> </span></div>
            <h3 class="text-center"></h3></div>
        </a>
      </div>
    </div>
  </div>

</main>
