<main id="main" class="about-template">
  <div class="jumbotron jumbotron-fluid m-0 bg-yellow" >
    <div class="container">
      <div class="row py-2 justify-content-center align-items-center">
        <div class="col offset-2">
          <h6 class="m-0 text-right ">ABOUT US</h6>
          <h1 class="text-right ">HISTORY</h1>
        </div>
        <div class="col">
          <img src="./assets/images/about/About%20Us%20Icon-01.png"
                              alt="About Us">
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-black py-4 grey-gradient-background shadow">
      <div class="col">
        <div class="container "><div class="text-white h6 text-center text-uppercase lineHeight166 "> Following South Africa’s first democratic elections in
          1994 the country’s government together with its Mozambican counterparts conceptualised the development of a
          corridor between Tshwane (then Pretoria) in Gauteng and Maputo in Mozambique. The main objective of this
          corridor was to re-establish trade links between the two countries. The heads of States at that time were
          presidents Nelson Mandela and Joachim Chissano. </div></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row py-4 d-flex align-items-center">
      <div class="col-sm-12 col-md-6">
        <div class="row">
          <div class="col-sm-12 ">
            <img src="./assets/images/history/Maputo%20Development-01.jpg" alt="History Counties"></div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 ">
        <div class="row">
          <div class="col-sm-12"><p>Called the Maputo Development Corridor Initiative, the idea was to develop the N4
            highway between those regions to such an extent that it would facilitate the stimulation of trade and
            investment in the region and provide access to global markets through the Port of Maputo. The port, which is
            closer and more conveniently situated to landlocked industries in Gauteng, Limpopo and Mpumalanga than those
            on the South African coastline, was, at the time, under-resourced and under-utilised due to the sanctions
            imposed against South Africa’s apartheid government and the Mozambican civil war.</p>
            <p>In order to turn the harbour into a thriving export and import facility, it had to major upgrades which
              included the dredging of the port’s deep-water channel (to increase its depth from 9.5m to 13.5m) in a bid
              to increase the tonne capacity of vessels able to pass into the port.<br></p></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid ">
    <div class="row py-4 bg-yellow shadow mb-1">
      <div class="col-sm-12  ">
        <div class="container bold  "><p>By April 1995 the respective governments had reached a series of agreements
          and had invited bidders to tender for the transformation of the N4 into the Maputo Corridor toll road. It
          would have to be a private sector-driven project, which required the establishment of a public-private
          partnership that could remove the route’s operational, maintenance and rehabilitation costs from the two
          governments’ operational budgets.</p>
          <p>Equally important, the bid would have to include socio-economic outcomes through a social contract. This
            would enable the empowerment and upliftment of communities in the immediate vicinity of the N4 through
            social and entrepreneurial development, job creation and training and skills transfer in a variety of
            fields.</p>
          <p>In the last quarter of 1996, Trans African Concessions (TRAC) was named the preferred bidder after an
            intense bid development process that cost approximately R50-million. TRAC, comprising a partnership between
            Basil Read, Stocks &amp; Stocks (both South African construction companies) and Bouygues (a French
            construction multinational) would be responsible for carrying out the terms of the 30-year concession to
            build, operate and maintain the toll road. The joint venture between the three construction companies, aptly
            named SBB, would undertake the initial construction work, which lasted three-and-a-half years.</p>
          <p>In May 1997, the concession documents were signed by TRAC and the South African and Mozambican governments,
            represented by their respective national roads agencies, SANRAL and ANE. By December that year, TRAC had
            raised the start-up finance (approximately R1.3-billion) required for the project. Construction of the
            Middelburg Toll Plaza began soon thereafter. This would be the first of three plazas along the N4 in South
            Africa, with another two in Mozambique. Over the first three-and-half years, R1.5-billion of the contract’s
            R3-billion price tag was spent on upgrading the road and services.</p></div>
      </div>
    </div>
  </div>
  <div class="container-fluid ">
    <div class="row d-flex">
      <div class="col-sm-12 col-md-6 p-0 d-flex">
        <div class="py-6"
             style="width:100%; background-image: url(./assets/images/history/toll-plazas-image-large-01.jpg); background-repeat: no-repeat; background-size: cover; background-position:center;">
          &nbsp;
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="row py-6">
          <div class="col-sm-12 p-5"><p>In 2004 another contract was signed to add a section of the N4 from Balmoral to the
            Hans Strijdom (now Solomon Mahlangu) off-ramps in Pretoria (now Tshwane). This led to a fourth toll plaza in
            South Africa – Diamond Hill – bringing the number of TRAC N4 toll plazas to six across the route.<br></p>
            <p>The N4 is what is known as a BOT (build, operate, transfer) toll road. This means that the states of
              South Africa and Mozambique remain the legal owners of the land on which the N4 Toll Route has been built
              on and will reassume responsibility for the road once TRAC’s concession expires in 2027. The economic life
              span of a road is around 20 to 25 years. But, because of TRAC’s continuous maintenance and rehabilitation
              of the N4 during the concession period, the two governments will be handed back essentially a new road.
              One of the major positives of this private-public partnership is that it will save the South African
              government as estimated R32-billion on road maintenance costs.</p>
            <p>Following the construction of the actual route as it is known today, and its toll plaza, TRAC’s
              objectives have shifted to operations, maintenance, upgrading and expansion.</p></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 p-0">
        <div class="py-6 image-sep"
             style="background-image: url(./assets/images/road.jpg); background-repeat: no-repeat; background-size: cover;"></div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row py-4">
      <div class="col-sm-6">
        <div id="block--5" class="block block--wysiwyg_block p-4">
          <div class="block__content"><h5 class="text-center">BENCHMARK PUBLIC-PRIVATE PARTNERSHIP</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
            <p>The concession represents a benchmark public-private partnership with the governments of South African
              and Mozambique, the private sector and the communities along the route, with each party sharing in the
              associated risks and rewards of the project. Along with railways, hospitals and prisons, roads are
              regarded as projects highly suitable for public-private partnerships. That’s because the public sector
              does not buy an object but a service, and many associated risks are transferred from the state to the
              private sector.</p></div>
        </div>
      </div>
      <div class="col-sm-6">
        <div id="block--6" class="block block--wysiwyg_block p-4">
          <div class="block__content"><h5 class="text-center">SANRAL &amp; ANE</h5>
            <div class="dot-divider mb-3"><span class="dot-divider-black"> </span></div>
            <p>Having signed a concession with the South African National Roads Agency Limited (SANRAL), which is the
              route’s implementation agent, TRAC can charge toll fees to manage, fund, upgrade and maintain the N4 Toll
              Route in South Africa.&nbsp;<a href="http://www.sanral.co.za/">http://www.sanral.co.za</a>&nbsp;It has a
              similar agreement with the National Roads Administration of Mozambique (ANE) for the EN4.&nbsp;<a
                href="http://www.ane.gov.mz/">http://www.ane.gov.mz</a></p>
            <p>Funds generated are spent on the section of the N4 toll road managed by TRAC, while tax-based revenue, as
              allocated by the national treasury, pays for the construction and upkeep of all South Africa’s national,
              provincial and local non-toll roads.</p></div>
        </div>
      </div>
    </div>
  </div>

</main>
