<main id="main" class="about-template">
    <div class="jumbotron jumbotron-fluid m-0 bg-yellow shadow">
        <div class="container">
            <div class="row py-2 justify-content-center align-items-center">
                <div class="col offset-2"><h6 class="m-0 text-right ">MAP</h6>
                    <h1 class="text-right ">LIVE ROUTE</h1></div>
                <div class="col"><img src="./assets/images/about/About%20Us%20Icon-01.png" alt="About Us"></div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 p-3  text-center   ">
                <div class=" black-text bold h4">
                    Live Route Map
                </div>
            </div>
            <div class="col-12 p-3  text-center ">
                <img title="More information on map" src="./assets/images/home/UiIcons_Location.png"
                     class="img-fluid pr-2"
                     style="height:28px;">Click on the <span class="p-2 rounded"
                                                             style="background-color: #4CAF50; color:#fff">green</span>
                and <span class="p-2 rounded" style="background-color: #f44336; color:#fff">red</span> markers to see
                information at that point on the map.
                <hr/>
            </div>

            <div class="col-12 mb-2  ">
                <div class="row text-center justify-content-center align-middle" style="height: 364px;">
                    <iframe #theiframe id="theiframe" [ngStyle]="{'width':setWidth}" [src]="url | safe"
                            class="text-center">
                    </iframe>

                </div>
            </div>
        </div>
    </div>


    <div class="home-after-map-section" >
        <div class="container">
            <div class="row py-4">
                <div class="col-sm-12 col-md-12">
                    <hr/>

                    <header>
                        <div class="postmaintitle-holder" >
                            <h1 class="mt-5 black-text">{{this.PageName}}</h1>
                            <span class="greenseparator small left"></span></div>
                        <div class="post-title mt-5">
                            <span class="posttitledate mr-2">{{this.PageDate | date:'dd MMMM'}}</span>
                            <h2 class="posttitle mb-0"> - {{this.PageName}}</h2>
                            <div class="meta w-100 float-right"><span class="author">Administrator - </span>&nbsp;
                                <time class="date mr-2" datetime="">
                                    {{this.PageDate | date:'dd MMMM YYYY'}}
                                </time>

                            </div>
                        </div>
                    </header>
                    <hr/>
                    <div class="" *ngIf="this.apiContent !== ''">
                        <div class="content" [innerHTML]="this.PageData | safeHtml">
                        </div>

                    </div>
                    <hr/>
                </div>
                <!--
                <div class="col-sm-12 col-md-12">
                       <hr/>

                    <div class="h1">N4 Toll Route Update</div>
                    <hr/>
                    <div class="h4">
                        July 2022
                    </div>
                    <hr/>



                    <div class="row">
                        <div class="col-sm-12 col-md-6 ">
                            <p><strong>WEATHER WARNING</strong></p>
                            <p>Poor visibility, mainly due to heavy mist during early morning hours or at night, causes
                                dangerous driving conditions. Road users are reminded to switch headlights on, increase
                                following distance and reduce travelling speed during adverse weather conditions. </p>
                            <p>&nbsp; </p>
                            <p><strong>DO:</strong></p>
                            <ul>
                                <li>Reduce speed and obey speed limits</li>
                                <li>Switch headlights on</li>
                                <li>Keep following distance</li>
                                <li>Remain patient and considerate</li>
                            </ul>
                            <p>&nbsp;</p>
                            <p><strong>DON’T:</strong></p>
                            <ul>
                                <li>Attempt to pass the vehicle in front of you when visibility is poor</li>
                                <li>Stop on the side of the road</li>
                            </ul>
                            <p></p>
                            <p><strong>UPGRADING, REHABILITATION, AND MAINTENANCE WORK</strong></p>
                            <p>Quality road infrastructure is an asset which benefits the economy, stimulates growth and
                                development, and provides a safe link between people and places. A road needs ongoing
                                maintenance and improvement to ensure that it continues to add value.
                            </p>
                            <p>
                                As the concessionaire of the N4 Toll Route between Tshwane and Maputo (Mozambique), TRAC
                                is committed to providing a road of high standards. This requires regular road and
                                maintenance works which may, at times, inconvenience road users.

                            </p>


                            <p>&nbsp; </p>
                            <p>In the interest of road safety and hassle-free usage of the N4 Toll Route, please take
                                note of
                                the following:</p>
                            <p>&nbsp; </p>
                            <p><strong>PEAK TIMES </strong></p>
                            <p>General</p>
                            <p>High traffic volumes at peak times may influence the duration of delays: </p>
                            <p>Late afternoons on weekdays: 15:00 – 20:00 </p>
                            <p>Friday afternoons:&nbsp; 15:00 - 20:00 </p>
                            <p>Sunday afternoons: 14:00 – 17:00 </p>
                            <p><strong>Long weekends</strong></p>
                            <p>Days preceding public/school holidays </p>
                            <p><strong>Maputo Plaza </strong><span></span></p>
                            <ul>
                                <li>Monday to Friday: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <ul>
                                        <li>05:45 - 09:00<span></span></li>
                                        <li>15:00 - 20:00<span></span></li>
                                    </ul>
                                </li>
                            </ul>
                            <p>&nbsp; </p>
                            <p><strong>Routine Road Maintenance</strong></p>
                            <p>Routine Road Maintenance continues with day-to-day tasks which include:</p>
                            <ul>
                                <li>Road marking in SA - between Montrose to Crossroads via Ngodwana;
                                </li>
                                <li>Firebreaks - to commence on Schoemanskloof (weather permitting);
                                </li>

                            </ul>
                        </div>
                        <div class="col-sm-12 col-md-6 ">

                        </div>
                    </div>


                    <p class="border-bottom">&nbsp; </p>
                    <div class="h4">Mpumalanga</div>
                    <hr/>
                    <p><strong>Witbank to Middelburg</strong></p>
                    <ul>
                        <li>The upgrade and rehabilitation project between eMalahleni and Middelburg is completed, only
                            ancillary works remain;


                        </li>
                        <li>Additional construction work will commence at Middelburg Plaza, the additional work will
                            include the construction of an eastbound screening lane for Mid-East Load control center. An
                            eastbound and westbound express lane for vehicles with tags will be also incorporated into
                            the existing plaza arrangement;

                        </li>
                        <li>
                            Traffic will be accommodated with lane and shoulder closures;

                        </li>
                        <li>
                            The additional works is scheduled to be completed in July 2022;
                        </li>
                        <li>
                            The additional work will see lane closures at the plaza in the eastbound direction;

                        </li>
                    </ul>


                    <p><strong>Wonderfontein to Belfast</strong></p>
                    <ul>
                        <li>Rehabilitation works on the existing road section 5 form N4/5x km 10.8-29.4 has commenced;
                            The works will include the rehabilitation of the existing road;
                        </li>
                        <li>
                            Lane and shoulder lane closures can be expected to accommodate the traffic during the
                            construction period;

                        </li>
                        <li>
                            The roadworks is scheduled to be completed by April 2024;

                        </li>

                    </ul>

                    <p><strong>Belfast to Machadodorp&nbsp;</strong></p>
                    <ul>
                        <li>
                            The major upgrade and rehabilitation project between Belfast an Machadodorp is completed;
                        </li>
                        <li>
                            Additional work has started for the construction of a new underpass at Milly’s. The
                            construction will result in lanes closures and is scheduled to be completed in November
                            2023;

                        </li>

                    </ul>


                    <p><strong>Montrose Intersection Upgrade ( N4-6x km 63 and N4- 7x km 22.00)</strong></p>
                    <ul>

                        <li>
                            The upgrade of the Montrose intersection has commenced. The works will see the upgrading of
                            the existing at-grade intersection at the convergence of N4-6Y (MDC Section 6N) and N4-7X
                            (MDC Section 6E) to a grade-separated interchange;

                        </li>
                        <li>
                            The Estimate completion date is June 2023;
                            Traffic accommodation will be in place till June 2023 that will see single lane closures and
                            Stop/Go’s;

                        </li>
                        <li>
                            Blasting will also be taking place for the duration of the projects and the road will be
                            closed for 30 min during blasting;

                        </li>
                        <li>
                            The works include the following:
                            <ul>
                                <li>
                                    The realignment of approximately 600 m of the existing N4-6Y;
                                </li>
                                <li>
                                    The construction of four new interchange ramps;

                                </li>
                                <li>
                                    The widening of the existing two-lane N4 bridge over the Crocodile River to
                                    accommodate four lanes of traffic and a taper for an off-ramp;

                                </li>
                                <li>
                                    The construction of a new deck-stiffened arch bridge across the existing N4-7X,
                                    anchored into rock faces, to accommodate two lanes of traffic;

                                </li>
                                <li>
                                    Erection of high-mast lighting to illuminate the interchange;

                                </li>
                            </ul>
                        </li>
                    </ul>


                    <p><strong>Karino (N4-7 Km 62.950 to km 66.250)</strong>&nbsp;</p>
                    <ul>
                        <li>
                            The Karino IC is now open to traffic with only ancillary works remaining to be done;
                        </li>
                        <li>
                            As part of the Karino IC upgrade additional lanes will be constructed from the new Karino IC
                            to the Nelspruit bypass at km 58.0;


                        </li>
                        <li>
                            Lane closure will be in place to accommodate traffic;

                        </li>
                        <li>
                            The roadworks is scheduled to be completed in September 2022;

                        </li>


                    </ul>

                    <p class="border-bottom">&nbsp; </p>
                    <div class="h4">Mozambique</div>
                    <hr/>

                    <p><strong>Section 16 A/B</strong></p>
                    <ul>

                        <li>
                            Road marking in progress;

                        </li>
                        <li>
                            Grass cutting in progress;
                        </li>
                        <li>
                            Installation of road studs in progress;

                        </li>
                        <li>
                            Potholes repairs in progress;

                        </li>
                    </ul>


                    <p><strong>Section 17</strong></p>
                    <ul>

                        <li>
                            Road marking in progress;

                        </li>
                        <li>
                            Installation of road studs is completed;


                        </li>
                        <li>MDC 17 roadworks to start soon;

                        </li>
                        <li>
                            Grass cutting in progress;
                        </li>
                    </ul>




                    <p><strong>Section 18</strong></p>
                    <ul>

                        <li>
                            Grass cutting and cleaning of drains is in progress;
                        </li>

                    </ul>
                    <p><strong>Section 19 and 20</strong></p>
                    <ul>

                        <li>
                            Grass cutting is completed;

                        </li>
                        <li>
                            Repairs at Xai Xai interchange in progress;
                        </li>
                    </ul>
                    <p><br></p>
                    <p><strong><em>Remember – rather lose a minute in your life, than your life in a
                        minute!</em></strong></p>
                    <p>Report accidents, incidents and/or dangerous conditions along the N4 Toll Route to TRAC’s 24-hour
                        Helpdesk. </p>
                    <p class=""><strong>RSA: 0800 87 22 64 / +27 82 881 4444</strong></p>
                    <p><strong>Mozambique: +258 84 34 34 34 6 or 800 90 22 ( toll free for TMcell and Vodacom
                        networks)</strong>
                    </p>

                    <div>
                        <a href="https://twitter.com/TRACN4route?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                           style="vertical-align:middle"
                           target="_blank" class="h6">
                            Follow us on Twitter: @TRACN4route
                        </a>

                    </div>
                    <p></p>
                    <p class=" " title="Follow link to our Facebook page">
                        <a href="https://www.facebook.com/TracN4/" target="_blank" class="h6">
                            Facebook: TRACN4route
                        </a>
                    </p>


                </div>
            -->

            </div>
        </div>
    </div>
</main>

<ng-template #content1 let-modal>
    <div class="modal-header bg-yellow ">
        <h4 class="modal-title" id="modal-basic-title4">Traffic Advisory</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body   grey-gradient-background2 p-4">
        <div class="container-fluid">
          <div class="row">
          <div class="col-sm-12 col-lg-6 shadow-lg">
            <img src="./assets/traffic-advisory/english-April2022.jpg" class="img-fluid"/>
          </div>
          <div class="col-sm-12 col-lg-6 shadow-lg">
            <img src="./assets/traffic-advisory/english-April2022.jpg" class="img-fluid"/>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-footer bg-yellow">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('close click')">Close</button>
      </div>
</ng-template>
